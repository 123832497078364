// ----------------------------------------------------------------------
import { fShortenNumber } from "../utils/formatNumber";

const btcchartData = [
  [
    1372032000,
    107.979,
    1,
    0
  ],
  [
    1372118400,
    102.982,
    1,
    0
  ],
  [
    1372204800,
    103.34,
    1,
    0
  ],
  [
    1372291200,
    103.2,
    1,
    0
  ],
  [
    1372377600,
    101.328,
    1,
    0
  ],
  [
    1372464000,
    93.697,
    1,
    0
  ],
  [
    1372550400,
    95,
    1,
    0
  ],
  [
    1372636800,
    97,
    1,
    0
  ],
  [
    1372723200,
    88.511,
    1,
    0
  ],
  [
    1372809600,
    90.9812,
    1,
    0
  ],
  [
    1372896000,
    79.8,
    1,
    0
  ],
  [
    1372982400,
    79.714,
    1,
    0
  ],
  [
    1373068800,
    67.73,
    1,
    0
  ],
  [
    1373155200,
    69,
    1,
    0
  ],
  [
    1373241600,
    75.4146,
    1,
    0
  ],
  [
    1373328000,
    76.71,
    1,
    0
  ],
  [
    1373414400,
    76.369,
    1,
    0
  ],
  [
    1373500800,
    87.5476,
    1,
    0
  ],
  [
    1373587200,
    91,
    1,
    0
  ],
  [
    1373673600,
    91.7778,
    1,
    0
  ],
  [
    1373760000,
    96.3177,
    1,
    0
  ],
  [
    1373846400,
    93.7002,
    1,
    0
  ],
  [
    1373932800,
    98.61,
    1,
    0
  ],
  [
    1374019200,
    97.07,
    1,
    0
  ],
  [
    1374105361,
    98.5,
    1,
    0
  ],
  [
    1374191762,
    90.58,
    1,
    0
  ],
  [
    1374278162,
    92.17,
    1,
    0
  ],
  [
    1374364561,
    89.3901,
    1,
    0
  ],
  [
    1374450962,
    90.7573,
    1,
    0
  ],
  [
    1374537361,
    91.61,
    1,
    0
  ],
  [
    1374623762,
    95.5585,
    1,
    0
  ],
  [
    1374710162,
    94.51,
    1,
    0
  ],
  [
    1374796800,
    96.57,
    1,
    0
  ],
  [
    1374883200,
    96.11,
    1,
    0
  ],
  [
    1374969600,
    94.9492,
    1,
    0
  ],
  [
    1375056000,
    100,
    1,
    0
  ],
  [
    1375142400,
    100.47,
    1,
    0
  ],
  [
    1375228800,
    107.95,
    1,
    0
  ],
  [
    1375315200,
    106.808,
    1,
    0
  ],
  [
    1375401600,
    104.46,
    1,
    0
  ],
  [
    1375488000,
    104.02,
    1,
    0
  ],
  [
    1375574400,
    104.055,
    1,
    0
  ],
  [
    1375660800,
    105.062,
    1,
    0
  ],
  [
    1375747200,
    107.2,
    1,
    0
  ],
  [
    1375833600,
    106.75,
    1,
    0
  ],
  [
    1375920000,
    106.75,
    1,
    0
  ],
  [
    1376006400,
    105,
    1,
    0
  ],
  [
    1376092800,
    103.526,
    1,
    0
  ],
  [
    1376179200,
    104.366,
    1,
    0
  ],
  [
    1376265600,
    104.6,
    1,
    0
  ],
  [
    1376352000,
    106.411,
    1,
    0
  ],
  [
    1376438400,
    108.267,
    1,
    0
  ],
  [
    1376524800,
    111.65,
    1,
    0
  ],
  [
    1376611200,
    109.5,
    1,
    0
  ],
  [
    1376697600,
    109,
    1,
    0
  ],
  [
    1376784000,
    113.2,
    1,
    0
  ],
  [
    1376870400,
    112.675,
    1,
    0
  ],
  [
    1376956800,
    118.89,
    1,
    0
  ],
  [
    1377043200,
    123.136,
    1,
    0
  ],
  [
    1377129600,
    121.89,
    1,
    0
  ],
  [
    1377216000,
    120.82,
    1,
    0
  ],
  [
    1377302400,
    118.8,
    1,
    0
  ],
  [
    1377388800,
    121,
    1,
    0
  ],
  [
    1377475200,
    122,
    1,
    0
  ],
  [
    1377561600,
    120.353,
    1,
    0
  ],
  [
    1377648000,
    126.45,
    1,
    0
  ],
  [
    1377734400,
    123.05,
    1,
    0
  ],
  [
    1377820800,
    122.11,
    1,
    0
  ],
  [
    1377907200,
    133.17,
    1,
    0
  ],
  [
    1377993600,
    136.79,
    1,
    0
  ],
  [
    1378080000,
    138.58,
    1,
    0
  ],
  [
    1378166400,
    136.3,
    1,
    0
  ],
  [
    1378252800,
    137.08,
    1,
    0
  ],
  [
    1378339200,
    126.76,
    1,
    0
  ],
  [
    1378425600,
    126.49,
    1,
    0
  ],
  [
    1378512000,
    118.89,
    1,
    0
  ],
  [
    1378598400,
    124.13,
    1,
    0
  ],
  [
    1378684800,
    121.86,
    1,
    0
  ],
  [
    1378771200,
    127.27,
    1,
    0
  ],
  [
    1378857600,
    125.89,
    1,
    0
  ],
  [
    1378944000,
    135.55,
    1,
    0
  ],
  [
    1379030400,
    132.83,
    1,
    0
  ],
  [
    1379116800,
    135.01,
    1,
    0
  ],
  [
    1379203200,
    129.4,
    1,
    0
  ],
  [
    1379289600,
    130.86,
    1,
    0
  ],
  [
    1379376000,
    131.59,
    1,
    0
  ],
  [
    1379462400,
    131.71,
    1,
    0
  ],
  [
    1379548800,
    131.37,
    1,
    0
  ],
  [
    1379635200,
    129.7,
    1,
    0
  ],
  [
    1379721600,
    126.95,
    1,
    0
  ],
  [
    1379808000,
    127.87,
    1,
    0
  ],
  [
    1379894400,
    128.98,
    1,
    0
  ],
  [
    1379980800,
    126.05,
    1,
    0
  ],
  [
    1380067200,
    127.38,
    1,
    0
  ],
  [
    1380153600,
    128.21,
    1,
    0
  ],
  [
    1380240000,
    128.94,
    1,
    0
  ],
  [
    1380326400,
    133.77,
    1,
    0
  ],
  [
    1380412800,
    134.9,
    1,
    0
  ],
  [
    1380499200,
    137.15,
    1,
    0
  ],
  [
    1380585600,
    133.01,
    1,
    0
  ],
  [
    1380672000,
    132.49,
    1,
    0
  ],
  [
    1380758400,
    114.18,
    1,
    0
  ],
  [
    1380844800,
    125.99,
    1,
    0
  ],
  [
    1380931200,
    129.01,
    1,
    0
  ],
  [
    1381017600,
    128.08,
    1,
    0
  ],
  [
    1381104000,
    129.12,
    1,
    0
  ],
  [
    1381190400,
    126.45,
    1,
    0
  ],
  [
    1381276800,
    125.86,
    1,
    0
  ],
  [
    1381363200,
    130.53,
    1,
    0
  ],
  [
    1381449600,
    130.77,
    1,
    0
  ],
  [
    1381536000,
    130.56,
    1,
    0
  ],
  [
    1381622400,
    134.59,
    1,
    0
  ],
  [
    1381708800,
    139.25,
    1,
    0
  ],
  [
    1381795200,
    140.44,
    1,
    0
  ],
  [
    1381881600,
    146.81,
    1,
    0
  ],
  [
    1381968000,
    142.46,
    1,
    0
  ],
  [
    1382054400,
    146.29,
    1,
    0
  ],
  [
    1382140800,
    155.7,
    1,
    0
  ],
  [
    1382227200,
    173.86,
    1,
    0
  ],
  [
    1382313600,
    175.92,
    1,
    0
  ],
  [
    1382400000,
    184.24,
    1,
    0
  ],
  [
    1382486400,
    194.35,
    1,
    0
  ],
  [
    1382572800,
    214.95,
    1,
    0
  ],
  [
    1382659200,
    192.21,
    1,
    0
  ],
  [
    1382745600,
    184.11,
    1,
    0
  ],
  [
    1382832000,
    178.59,
    1,
    0
  ],
  [
    1382918400,
    195.19,
    1,
    0
  ],
  [
    1383004800,
    198.55,
    1,
    0
  ],
  [
    1383091200,
    204.39,
    1,
    0
  ],
  [
    1383177600,
    200.09,
    1,
    0
  ],
  [
    1383264000,
    204.02,
    1,
    0
  ],
  [
    1383350400,
    205.73,
    1,
    0
  ],
  [
    1383436800,
    206.77,
    1,
    0
  ],
  [
    1383523200,
    215.1,
    1,
    0
  ],
  [
    1383609600,
    229.84,
    1,
    0
  ],
  [
    1383696000,
    246.42,
    1,
    0
  ],
  [
    1383782400,
    263.42,
    1,
    0
  ],
  [
    1383868800,
    299.83,
    1,
    0
  ],
  [
    1383955200,
    344.63,
    1,
    0
  ],
  [
    1384041600,
    334.93,
    1,
    0
  ],
  [
    1384128000,
    322.69,
    1,
    0
  ],
  [
    1384214400,
    346.62,
    1,
    0
  ],
  [
    1384300800,
    365.06,
    1,
    0
  ],
  [
    1384387200,
    417.59,
    1,
    0
  ],
  [
    1384473600,
    420.31,
    1,
    0
  ],
  [
    1384560000,
    422.16,
    1,
    0
  ],
  [
    1384646400,
    441.64,
    1,
    0
  ],
  [
    1384732800,
    494.94,
    1,
    0
  ],
  [
    1384819200,
    806.11,
    1,
    0
  ],
  [
    1384905600,
    551.31,
    1,
    0
  ],
  [
    1384992000,
    597.1,
    1,
    0
  ],
  [
    1385078400,
    713.51,
    1,
    0
  ],
  [
    1385164800,
    778.15,
    1,
    0
  ],
  [
    1385251200,
    796.57,
    1,
    0
  ],
  [
    1385337600,
    774.33,
    1,
    0
  ],
  [
    1385424000,
    807.23,
    1,
    0
  ],
  [
    1385510400,
    898.46,
    1,
    0
  ],
  [
    1385596800,
    1004.86,
    1,
    0
  ],
  [
    1385683200,
    1030.28,
    1,
    0
  ],
  [
    1385769600,
    1127.8,
    1,
    0
  ],
  [
    1385856000,
    1131.29,
    1,
    0
  ],
  [
    1385942400,
    948.87,
    1,
    0
  ],
  [
    1386028800,
    1043.24,
    1,
    0
  ],
  [
    1386115200,
    1074.34,
    1,
    0
  ],
  [
    1386201600,
    1143.82,
    1,
    0
  ],
  [
    1386288000,
    992.68,
    1,
    0
  ],
  [
    1386374400,
    764.47,
    1,
    0
  ],
  [
    1386460800,
    697.55,
    1,
    0
  ],
  [
    1386547200,
    794.87,
    1,
    0
  ],
  [
    1386633600,
    916.57,
    1,
    0
  ],
  [
    1386720000,
    995.83,
    1,
    0
  ],
  [
    1386806400,
    894.52,
    1,
    0
  ],
  [
    1386892800,
    876.99,
    1,
    0
  ],
  [
    1386979200,
    889.72,
    1,
    0
  ],
  [
    1387065600,
    867.6,
    1,
    0
  ],
  [
    1387152000,
    872.54,
    1,
    0
  ],
  [
    1387238400,
    665.45,
    1,
    0
  ],
  [
    1387324800,
    659.54,
    1,
    0
  ],
  [
    1387411200,
    503.61,
    1,
    0
  ],
  [
    1387497600,
    692.68,
    1,
    0
  ],
  [
    1387584000,
    619.14,
    1,
    0
  ],
  [
    1387670400,
    603.38,
    1,
    0
  ],
  [
    1387756800,
    615.33,
    1,
    0
  ],
  [
    1387843200,
    672.36,
    1,
    0
  ],
  [
    1387929600,
    666.31,
    1,
    0
  ],
  [
    1388016000,
    683.94,
    1,
    0
  ],
  [
    1388102400,
    770.27,
    1,
    0
  ],
  [
    1388188800,
    736.27,
    1,
    0
  ],
  [
    1388275200,
    730.78,
    1,
    0
  ],
  [
    1388361600,
    740.24,
    1,
    0
  ],
  [
    1388448000,
    755.57,
    1,
    0
  ],
  [
    1388534400,
    761.59,
    1,
    0
  ],
  [
    1388620800,
    774.76,
    1,
    0
  ],
  [
    1388707200,
    804.03,
    1,
    0
  ],
  [
    1388793600,
    812.42,
    1,
    0
  ],
  [
    1388880000,
    866.27,
    1,
    0
  ],
  [
    1388966400,
    931.19,
    1,
    0
  ],
  [
    1389052800,
    961.68,
    1,
    0
  ],
  [
    1389139200,
    781.35,
    1,
    0
  ],
  [
    1389225600,
    844.77,
    1,
    0
  ],
  [
    1389312000,
    840.51,
    1,
    0
  ],
  [
    1389398400,
    864.25,
    1,
    0
  ],
  [
    1389484800,
    921.98,
    1,
    0
  ],
  [
    1389571200,
    850.89,
    1,
    0
  ],
  [
    1389657600,
    838.5,
    1,
    0
  ],
  [
    1389744000,
    833.68,
    1,
    0
  ],
  [
    1389830400,
    864.91,
    1,
    0
  ],
  [
    1389916800,
    837.04,
    1,
    0
  ],
  [
    1390003200,
    829.51,
    1,
    0
  ],
  [
    1390089600,
    843.82,
    1,
    0
  ],
  [
    1390176000,
    866.49,
    1,
    0
  ],
  [
    1390262400,
    873.11,
    1,
    0
  ],
  [
    1390348800,
    865.78,
    1,
    0
  ],
  [
    1390435200,
    846.94,
    1,
    0
  ],
  [
    1390521600,
    817.35,
    1,
    0
  ],
  [
    1390608000,
    800.85,
    1,
    0
  ],
  [
    1390694400,
    854.47,
    1,
    0
  ],
  [
    1390780800,
    886.06,
    1,
    0
  ],
  [
    1390867200,
    776.05,
    1,
    0
  ],
  [
    1390953600,
    813.71,
    1,
    0
  ],
  [
    1391040000,
    826.06,
    1,
    0
  ],
  [
    1391126400,
    821.62,
    1,
    0
  ],
  [
    1391212800,
    831.58,
    1,
    0
  ],
  [
    1391299200,
    832.44,
    1,
    0
  ],
  [
    1391385600,
    823.12,
    1,
    0
  ],
  [
    1391472000,
    822.82,
    1,
    0
  ],
  [
    1391558400,
    830.63,
    1,
    0
  ],
  [
    1391644800,
    819.81,
    1,
    0
  ],
  [
    1391731200,
    764.04,
    1,
    0
  ],
  [
    1391817600,
    704.83,
    1,
    0
  ],
  [
    1391904000,
    666.26,
    1,
    0
  ],
  [
    1391990400,
    681.32,
    1,
    0
  ],
  [
    1392076800,
    683.5,
    1,
    0
  ],
  [
    1392163200,
    670.55,
    1,
    0
  ],
  [
    1392249600,
    655.7,
    1,
    0
  ],
  [
    1392336000,
    595.99,
    1,
    0
  ],
  [
    1392422400,
    657.79,
    1,
    0
  ],
  [
    1392508800,
    654.4,
    1,
    0
  ],
  [
    1392595200,
    629.56,
    1,
    0
  ],
  [
    1392681600,
    637.5,
    1,
    0
  ],
  [
    1392768000,
    624.2,
    1,
    0
  ],
  [
    1392854400,
    626.58,
    1,
    0
  ],
  [
    1392940800,
    540.71,
    1,
    0
  ],
  [
    1393027200,
    574.24,
    1,
    0
  ],
  [
    1393113600,
    606.47,
    1,
    0
  ],
  [
    1393200000,
    606.04,
    1,
    0
  ],
  [
    1393286400,
    540.24,
    1,
    0
  ],
  [
    1393372800,
    541.17,
    1,
    0
  ],
  [
    1393459200,
    592.77,
    1,
    0
  ],
  [
    1393545600,
    580.58,
    1,
    0
  ],
  [
    1393632000,
    550.89,
    1,
    0
  ],
  [
    1393718400,
    566.8,
    1,
    0
  ],
  [
    1393804800,
    567.18,
    1,
    0
  ],
  [
    1393891200,
    662.64,
    1,
    0
  ],
  [
    1393977600,
    669.78,
    1,
    0
  ],
  [
    1394064000,
    665.99,
    1,
    0
  ],
  [
    1394150400,
    662.32,
    1,
    0
  ],
  [
    1394236800,
    632.01,
    1,
    0
  ],
  [
    1394323200,
    613.41,
    1,
    0
  ],
  [
    1394409600,
    637.59,
    1,
    0
  ],
  [
    1394496000,
    628.58,
    1,
    0
  ],
  [
    1394582400,
    648.03,
    1,
    0
  ],
  [
    1394668800,
    634.94,
    1,
    0
  ],
  [
    1394755200,
    637.01,
    1,
    0
  ],
  [
    1394841600,
    629.18,
    1,
    0
  ],
  [
    1394928000,
    635.58,
    1,
    0
  ],
  [
    1395014400,
    632.68,
    1,
    0
  ],
  [
    1395100800,
    621.131,
    1,
    0
  ],
  [
    1395187200,
    621.989,
    1,
    0
  ],
  [
    1395273600,
    606.917,
    1,
    0
  ],
  [
    1395360000,
    585.086,
    1,
    0
  ],
  [
    1395446400,
    570.088,
    1,
    0
  ],
  [
    1395532800,
    565.603,
    1,
    0
  ],
  [
    1395619200,
    562.507,
    1,
    0
  ],
  [
    1395705600,
    583.653,
    1,
    0
  ],
  [
    1395792000,
    582.841,
    1,
    0
  ],
  [
    1395878400,
    565.318,
    1,
    0
  ],
  [
    1395964800,
    481.586,
    1,
    0
  ],
  [
    1396051200,
    501.994,
    1,
    0
  ],
  [
    1396137600,
    487.882,
    1,
    0
  ],
  [
    1396224000,
    473.5,
    1,
    0
  ],
  [
    1396310400,
    461.231,
    1,
    0
  ],
  [
    1396396800,
    482.239,
    1,
    0
  ],
  [
    1396483200,
    427.285,
    1,
    0
  ],
  [
    1396569600,
    441.01,
    1,
    0
  ],
  [
    1396656000,
    446.417,
    1,
    0
  ],
  [
    1396742400,
    464.338,
    1,
    0
  ],
  [
    1396828800,
    460.728,
    1,
    0
  ],
  [
    1396915200,
    447.76,
    1,
    0
  ],
  [
    1397001600,
    453.834,
    1,
    0
  ],
  [
    1397088000,
    442.098,
    1,
    0
  ],
  [
    1397174400,
    357.887,
    1,
    0
  ],
  [
    1397260800,
    431.381,
    1,
    0
  ],
  [
    1397347200,
    422.532,
    1,
    0
  ],
  [
    1397433600,
    414.669,
    1,
    0
  ],
  [
    1397520000,
    460.65,
    1,
    0
  ],
  [
    1397606400,
    514.424,
    1,
    0
  ],
  [
    1397692800,
    525.123,
    1,
    0
  ],
  [
    1397779200,
    492.394,
    1,
    0
  ],
  [
    1397865600,
    475.868,
    1,
    0
  ],
  [
    1397952000,
    500.486,
    1,
    0
  ],
  [
    1398038400,
    503.919,
    1,
    0
  ],
  [
    1398124800,
    497.009,
    1,
    0
  ],
  [
    1398211200,
    492.955,
    1,
    0
  ],
  [
    1398297600,
    491.997,
    1,
    0
  ],
  [
    1398384000,
    497.143,
    1,
    0
  ],
  [
    1398470400,
    454.992,
    1,
    0
  ],
  [
    1398556800,
    458.861,
    1,
    0
  ],
  [
    1398643200,
    431.469,
    1,
    0
  ],
  [
    1398729600,
    439.983,
    1,
    0
  ],
  [
    1398816000,
    445.04,
    1,
    0
  ],
  [
    1398902400,
    453.026,
    1,
    0
  ],
  [
    1398988800,
    457.524,
    1,
    0
  ],
  [
    1399075200,
    445.454,
    1,
    0
  ],
  [
    1399161600,
    438.067,
    1,
    0
  ],
  [
    1399248000,
    436.025,
    1,
    0
  ],
  [
    1399334400,
    426.772,
    1,
    0
  ],
  [
    1399420800,
    429.734,
    1,
    0
  ],
  [
    1399507200,
    439.179,
    1,
    0
  ],
  [
    1399593600,
    440.18,
    1,
    0
  ],
  [
    1399680000,
    451.94,
    1,
    0
  ],
  [
    1399766400,
    453.5,
    1,
    0
  ],
  [
    1399852800,
    439.789,
    1,
    0
  ],
  [
    1399939200,
    439.021,
    1,
    0
  ],
  [
    1400025600,
    440.546,
    1,
    0
  ],
  [
    1400112000,
    445.345,
    1,
    0
  ],
  [
    1400198400,
    446.445,
    1,
    0
  ],
  [
    1400284800,
    449.686,
    1,
    0
  ],
  [
    1400371200,
    448.341,
    1,
    0
  ],
  [
    1400457600,
    444.727,
    1,
    0
  ],
  [
    1400544000,
    447.092,
    1,
    0
  ],
  [
    1400630400,
    493.851,
    1,
    0
  ],
  [
    1400716800,
    490.421,
    1,
    0
  ],
  [
    1400803200,
    531.587,
    1,
    0
  ],
  [
    1400889600,
    517.05,
    1,
    0
  ],
  [
    1400976000,
    526.092,
    1,
    0
  ],
  [
    1401062400,
    568.678,
    1,
    0
  ],
  [
    1401148800,
    585.025,
    1,
    0
  ],
  [
    1401235200,
    570.299,
    1,
    0
  ],
  [
    1401321600,
    575.647,
    1,
    0
  ],
  [
    1401408000,
    568.185,
    1,
    0
  ],
  [
    1401494400,
    611.99,
    1,
    0
  ],
  [
    1401580800,
    623.747,
    1,
    0
  ],
  [
    1401667200,
    641.264,
    1,
    0
  ],
  [
    1401753600,
    655.964,
    1,
    0
  ],
  [
    1401840000,
    647.941,
    1,
    0
  ],
  [
    1401926400,
    640.09,
    1,
    0
  ],
  [
    1402012800,
    658.492,
    1,
    0
  ],
  [
    1402099200,
    651.427,
    1,
    0
  ],
  [
    1402185600,
    654.686,
    1,
    0
  ],
  [
    1402272000,
    655.781,
    1,
    0
  ],
  [
    1402358400,
    650.005,
    1,
    0
  ],
  [
    1402444800,
    653.243,
    1,
    0
  ],
  [
    1402531200,
    636.554,
    1,
    0
  ],
  [
    1402617600,
    600.064,
    1,
    0
  ],
  [
    1402704000,
    598.806,
    1,
    0
  ],
  [
    1402790400,
    574.897,
    1,
    0
  ],
  [
    1402876800,
    588.059,
    1,
    0
  ],
  [
    1402963200,
    591.467,
    1,
    0
  ],
  [
    1403049600,
    615.456,
    1,
    0
  ],
  [
    1403136000,
    608.054,
    1,
    0
  ],
  [
    1403222400,
    596.75,
    1,
    0
  ],
  [
    1403308800,
    593.684,
    1,
    0
  ],
  [
    1403395200,
    595.267,
    1,
    0
  ],
  [
    1403481600,
    602.806,
    1,
    0
  ],
  [
    1403568000,
    593.97,
    1,
    0
  ],
  [
    1403654400,
    583.338,
    1,
    0
  ],
  [
    1403740800,
    565.343,
    1,
    0
  ],
  [
    1403827200,
    581.134,
    1,
    0
  ],
  [
    1403913600,
    597.884,
    1,
    0
  ],
  [
    1404000000,
    596.557,
    1,
    0
  ],
  [
    1404086400,
    600.833,
    1,
    0
  ],
  [
    1404172800,
    641.053,
    1,
    0
  ],
  [
    1404259200,
    639.593,
    1,
    0
  ],
  [
    1404345600,
    644.296,
    1,
    0
  ],
  [
    1404432000,
    645.34,
    1,
    0
  ],
  [
    1404518400,
    632.275,
    1,
    0
  ],
  [
    1404604800,
    633.161,
    1,
    0
  ],
  [
    1404691200,
    634.082,
    1,
    0
  ],
  [
    1404777600,
    620.907,
    1,
    0
  ],
  [
    1404864000,
    625.483,
    1,
    0
  ],
  [
    1404950400,
    625.329,
    1,
    0
  ],
  [
    1405036800,
    616.522,
    1,
    0
  ],
  [
    1405123200,
    631.772,
    1,
    0
  ],
  [
    1405209600,
    633.737,
    1,
    0
  ],
  [
    1405296000,
    626.479,
    1,
    0
  ],
  [
    1405382400,
    620.863,
    1,
    0
  ],
  [
    1405468800,
    621.716,
    1,
    0
  ],
  [
    1405555200,
    616.042,
    1,
    0
  ],
  [
    1405641600,
    621.988,
    1,
    0
  ],
  [
    1405728000,
    628.065,
    1,
    0
  ],
  [
    1405814400,
    628.031,
    1,
    0
  ],
  [
    1405900800,
    623.606,
    1,
    0
  ],
  [
    1405987200,
    622.452,
    1,
    0
  ],
  [
    1406073600,
    621.285,
    1,
    0
  ],
  [
    1406160000,
    619.836,
    1,
    0
  ],
  [
    1406246400,
    603.057,
    1,
    0
  ],
  [
    1406332800,
    601.336,
    1,
    0
  ],
  [
    1406419200,
    595.445,
    1,
    0
  ],
  [
    1406505600,
    593.96,
    1,
    0
  ],
  [
    1406592000,
    582.949,
    1,
    0
  ],
  [
    1406678400,
    583.807,
    1,
    0
  ],
  [
    1406764800,
    566.962,
    1,
    0
  ],
  [
    1406851200,
    586.202,
    1,
    0
  ],
  [
    1406937600,
    594.843,
    1,
    0
  ],
  [
    1407024000,
    589.072,
    1,
    0
  ],
  [
    1407110400,
    586.244,
    1,
    0
  ],
  [
    1407196800,
    588.924,
    1,
    0
  ],
  [
    1407283200,
    585.857,
    1,
    0
  ],
  [
    1407369600,
    585.364,
    1,
    0
  ],
  [
    1407456000,
    588.876,
    1,
    0
  ],
  [
    1407542400,
    590.575,
    1,
    0
  ],
  [
    1407628800,
    589.46,
    1,
    0
  ],
  [
    1407715200,
    590.73,
    1,
    0
  ],
  [
    1407801600,
    576.586,
    1,
    0
  ],
  [
    1407888000,
    570.869,
    1,
    0
  ],
  [
    1407974400,
    545.684,
    1,
    0
  ],
  [
    1408060800,
    514.132,
    1,
    0
  ],
  [
    1408147200,
    493.911,
    1,
    0
  ],
  [
    1408233600,
    518.2,
    1,
    0
  ],
  [
    1408320000,
    495.187,
    1,
    0
  ],
  [
    1408406400,
    457.169,
    1,
    0
  ],
  [
    1408492800,
    486.988,
    1,
    0
  ],
  [
    1408579200,
    512.663,
    1,
    0
  ],
  [
    1408665600,
    517.32,
    1,
    0
  ],
  [
    1408752000,
    513.641,
    1,
    0
  ],
  [
    1408838400,
    498.68,
    1,
    0
  ],
  [
    1408924800,
    507.096,
    1,
    0
  ],
  [
    1409011200,
    503.002,
    1,
    0
  ],
  [
    1409097600,
    512.903,
    1,
    0
  ],
  [
    1409184000,
    513.127,
    1,
    0
  ],
  [
    1409270400,
    507.801,
    1,
    0
  ],
  [
    1409356800,
    508.954,
    1,
    0
  ],
  [
    1409443200,
    502.727,
    1,
    0
  ],
  [
    1409529600,
    479.863,
    1,
    0
  ],
  [
    1409616000,
    473.641,
    1,
    0
  ],
  [
    1409702400,
    477.006,
    1,
    0
  ],
  [
    1409788800,
    477.589,
    1,
    0
  ],
  [
    1409875200,
    489.608,
    1,
    0
  ],
  [
    1409961600,
    483.558,
    1,
    0
  ],
  [
    1410048000,
    485.134,
    1,
    0
  ],
  [
    1410134400,
    481.527,
    1,
    0
  ],
  [
    1410220800,
    475.023,
    1,
    0
  ],
  [
    1410307200,
    475.318,
    1,
    0
  ],
  [
    1410393600,
    480.128,
    1,
    0
  ],
  [
    1410480000,
    479.056,
    1,
    0
  ],
  [
    1410566400,
    477.74,
    1,
    0
  ],
  [
    1410652800,
    478.565,
    1,
    0
  ],
  [
    1410739200,
    477.861,
    1,
    0
  ],
  [
    1410825600,
    472.441,
    1,
    0
  ],
  [
    1410912000,
    465.381,
    1,
    0
  ],
  [
    1410998400,
    453.887,
    1,
    0
  ],
  [
    1411084800,
    421.307,
    1,
    0
  ],
  [
    1411171200,
    392.137,
    1,
    0
  ],
  [
    1411257600,
    410.753,
    1,
    0
  ],
  [
    1411344000,
    399.237,
    1,
    0
  ],
  [
    1411430400,
    403.363,
    1,
    0
  ],
  [
    1411516800,
    435.751,
    1,
    0
  ],
  [
    1411603200,
    423.494,
    1,
    0
  ],
  [
    1411689600,
    411.754,
    1,
    0
  ],
  [
    1411776000,
    404.486,
    1,
    0
  ],
  [
    1411862400,
    399.801,
    1,
    0
  ],
  [
    1411948800,
    376.759,
    1,
    0
  ],
  [
    1412035200,
    376.128,
    1,
    0
  ],
  [
    1412121600,
    388.681,
    1,
    0
  ],
  [
    1412208000,
    384.77,
    1,
    0
  ],
  [
    1412294400,
    373.909,
    1,
    0
  ],
  [
    1412380800,
    359.666,
    1,
    0
  ],
  [
    1412467200,
    331.805,
    1,
    0
  ],
  [
    1412553600,
    303.543,
    1,
    0
  ],
  [
    1412640000,
    334.357,
    1,
    0
  ],
  [
    1412726400,
    330.518,
    1,
    0
  ],
  [
    1412812800,
    353.411,
    1,
    0
  ],
  [
    1412899200,
    362.961,
    1,
    0
  ],
  [
    1412985600,
    365.493,
    1,
    0
  ],
  [
    1413072000,
    361.052,
    1,
    0
  ],
  [
    1413158400,
    372.87,
    1,
    0
  ],
  [
    1413244800,
    395.9,
    1,
    0
  ],
  [
    1413331200,
    399.367,
    1,
    0
  ],
  [
    1413417600,
    397.474,
    1,
    0
  ],
  [
    1413504000,
    382.018,
    1,
    0
  ],
  [
    1413590400,
    380.599,
    1,
    0
  ],
  [
    1413676800,
    391.571,
    1,
    0
  ],
  [
    1413763200,
    388.692,
    1,
    0
  ],
  [
    1413849600,
    381.607,
    1,
    0
  ],
  [
    1413936000,
    386.72,
    1,
    0
  ],
  [
    1414022400,
    383.653,
    1,
    0
  ],
  [
    1414108800,
    357.542,
    1,
    0
  ],
  [
    1414195200,
    357.044,
    1,
    0
  ],
  [
    1414281600,
    346.996,
    1,
    0
  ],
  [
    1414368000,
    354.227,
    1,
    0
  ],
  [
    1414454400,
    353.644,
    1,
    0
  ],
  [
    1414540800,
    355.468,
    1,
    0
  ],
  [
    1414627200,
    338.198,
    1,
    0
  ],
  [
    1414713600,
    346.961,
    1,
    0
  ],
  [
    1414800000,
    339.744,
    1,
    0
  ],
  [
    1414886400,
    326.075,
    1,
    0
  ],
  [
    1414972800,
    326.223,
    1,
    0
  ],
  [
    1415059200,
    327.05,
    1,
    0
  ],
  [
    1415145600,
    336.295,
    1,
    0
  ],
  [
    1415232000,
    338.947,
    1,
    0
  ],
  [
    1415318400,
    348.818,
    1,
    0
  ],
  [
    1415404800,
    344.083,
    1,
    0
  ],
  [
    1415491200,
    345.521,
    1,
    0
  ],
  [
    1415577600,
    359.312,
    1,
    0
  ],
  [
    1415664000,
    368.553,
    1,
    0
  ],
  [
    1415750400,
    370.943,
    1,
    0
  ],
  [
    1415836800,
    429.324,
    1,
    0
  ],
  [
    1415923200,
    411.933,
    1,
    0
  ],
  [
    1416009600,
    403.606,
    1,
    0
  ],
  [
    1416096000,
    375.792,
    1,
    0
  ],
  [
    1416182400,
    398.31,
    1,
    0
  ],
  [
    1416268800,
    391.418,
    1,
    0
  ],
  [
    1416355200,
    376.607,
    1,
    0
  ],
  [
    1416441600,
    381.807,
    1,
    0
  ],
  [
    1416528000,
    349.899,
    1,
    0
  ],
  [
    1416614400,
    351.841,
    1,
    0
  ],
  [
    1416700800,
    353.416,
    1,
    0
  ],
  [
    1416787200,
    369.09,
    1,
    0
  ],
  [
    1416873600,
    382.066,
    1,
    0
  ],
  [
    1416960000,
    371.908,
    1,
    0
  ],
  [
    1417046400,
    371.814,
    1,
    0
  ],
  [
    1417132800,
    369.895,
    1,
    0
  ],
  [
    1417219200,
    373.979,
    1,
    0
  ],
  [
    1417305600,
    375.225,
    1,
    0
  ],
  [
    1417392000,
    377.408,
    1,
    0
  ],
  [
    1417478400,
    379.282,
    1,
    0
  ],
  [
    1417564800,
    381.6,
    1,
    0
  ],
  [
    1417651200,
    376.219,
    1,
    0
  ],
  [
    1417737600,
    365.756,
    1,
    0
  ],
  [
    1417824000,
    376.715,
    1,
    0
  ],
  [
    1417910400,
    375.385,
    1,
    0
  ],
  [
    1417996800,
    375.494,
    1,
    0
  ],
  [
    1418083200,
    362.874,
    1,
    0
  ],
  [
    1418169600,
    349.207,
    1,
    0
  ],
  [
    1418256000,
    345.202,
    1,
    0
  ],
  [
    1418342400,
    351.571,
    1,
    0
  ],
  [
    1418428800,
    351.508,
    1,
    0
  ],
  [
    1418515200,
    346.427,
    1,
    0
  ],
  [
    1418601600,
    351.262,
    1,
    0
  ],
  [
    1418688000,
    345.019,
    1,
    0
  ],
  [
    1418774400,
    330.919,
    1,
    0
  ],
  [
    1418860800,
    321.223,
    1,
    0
  ],
  [
    1418947200,
    311.585,
    1,
    0
  ],
  [
    1419033600,
    316.262,
    1,
    0
  ],
  [
    1419120000,
    328.107,
    1,
    0
  ],
  [
    1419206400,
    323.156,
    1,
    0
  ],
  [
    1419292800,
    333.163,
    1,
    0
  ],
  [
    1419379200,
    334.385,
    1,
    0
  ],
  [
    1419465600,
    322.286,
    1,
    0
  ],
  [
    1419552000,
    319.152,
    1,
    0
  ],
  [
    1419638400,
    327.583,
    1,
    0
  ],
  [
    1419724800,
    316.16,
    1,
    0
  ],
  [
    1419811200,
    317.701,
    1,
    0
  ],
  [
    1419897600,
    312.719,
    1,
    0
  ],
  [
    1419984000,
    310.914,
    1,
    0
  ],
  [
    1420070400,
    320.435,
    1,
    0
  ],
  [
    1420156800,
    314.079,
    1,
    0
  ],
  [
    1420243200,
    314.846,
    1,
    0
  ],
  [
    1420329600,
    281.146,
    1,
    0
  ],
  [
    1420416000,
    265.084,
    1,
    0
  ],
  [
    1420502400,
    274.611,
    1,
    0
  ],
  [
    1420588800,
    286.077,
    1,
    0
  ],
  [
    1420675200,
    294.135,
    1,
    0
  ],
  [
    1420761600,
    282.383,
    1,
    0
  ],
  [
    1420848000,
    285.571,
    1,
    0
  ],
  [
    1420934400,
    274.433,
    1,
    0
  ],
  [
    1421020800,
    267.392,
    1,
    0
  ],
  [
    1421107200,
    267.301,
    1,
    0
  ],
  [
    1421193600,
    219.015,
    1,
    0
  ],
  [
    1421280000,
    183.13,
    1,
    0
  ],
  [
    1421366400,
    212.578,
    1,
    0
  ],
  [
    1421452800,
    206.659,
    1,
    0
  ],
  [
    1421539200,
    200.638,
    1,
    0
  ],
  [
    1421625600,
    212.446,
    1,
    0
  ],
  [
    1421712000,
    214.244,
    1,
    0
  ],
  [
    1421798400,
    212.022,
    1,
    0
  ],
  [
    1421884800,
    226.741,
    1,
    0
  ],
  [
    1421971200,
    232.165,
    1,
    0
  ],
  [
    1422057600,
    231.142,
    1,
    0
  ],
  [
    1422144000,
    246.746,
    1,
    0
  ],
  [
    1422230400,
    260.878,
    1,
    0
  ],
  [
    1422316800,
    267.793,
    1,
    0
  ],
  [
    1422403200,
    266.272,
    1,
    0
  ],
  [
    1422489600,
    235.326,
    1,
    0
  ],
  [
    1422576000,
    234.854,
    1,
    0
  ],
  [
    1422662400,
    228.224,
    1,
    0
  ],
  [
    1422748800,
    216.507,
    1,
    0
  ],
  [
    1422835200,
    229.733,
    1,
    0
  ],
  [
    1422921600,
    242.723,
    1,
    0
  ],
  [
    1423008000,
    227.984,
    1,
    0
  ],
  [
    1423094400,
    226.269,
    1,
    0
  ],
  [
    1423180800,
    218.299,
    1,
    0
  ],
  [
    1423267200,
    223.599,
    1,
    0
  ],
  [
    1423353600,
    226.965,
    1,
    0
  ],
  [
    1423440000,
    223.353,
    1,
    0
  ],
  [
    1423526400,
    220.019,
    1,
    0
  ],
  [
    1423612800,
    220.892,
    1,
    0
  ],
  [
    1423699200,
    218.883,
    1,
    0
  ],
  [
    1423785600,
    221.71,
    1,
    0
  ],
  [
    1423872000,
    237.865,
    1,
    0
  ],
  [
    1423958400,
    258.438,
    1,
    0
  ],
  [
    1424044800,
    233.009,
    1,
    0
  ],
  [
    1424131200,
    233.672,
    1,
    0
  ],
  [
    1424217600,
    243.249,
    1,
    0
  ],
  [
    1424304000,
    236.714,
    1,
    0
  ],
  [
    1424390400,
    240.894,
    1,
    0
  ],
  [
    1424476800,
    243.93,
    1,
    0
  ],
  [
    1424563200,
    246.177,
    1,
    0
  ],
  [
    1424649600,
    236.482,
    1,
    0
  ],
  [
    1424736000,
    239.214,
    1,
    0
  ],
  [
    1424822400,
    238.922,
    1,
    0
  ],
  [
    1424908800,
    237.573,
    1,
    0
  ],
  [
    1424995200,
    236.599,
    1,
    0
  ],
  [
    1425081600,
    253.031,
    1,
    0
  ],
  [
    1425168000,
    253.444,
    1,
    0
  ],
  [
    1425254400,
    259.001,
    1,
    0
  ],
  [
    1425340800,
    272.559,
    1,
    0
  ],
  [
    1425427200,
    283.432,
    1,
    0
  ],
  [
    1425513600,
    272.866,
    1,
    0
  ],
  [
    1425600000,
    277.461,
    1,
    0
  ],
  [
    1425686400,
    272.639,
    1,
    0
  ],
  [
    1425772800,
    276.724,
    1,
    0
  ],
  [
    1425859200,
    273.908,
    1,
    0
  ],
  [
    1425945600,
    292.2,
    1,
    0
  ],
  [
    1426032000,
    291.873,
    1,
    0
  ],
  [
    1426118400,
    294.944,
    1,
    0
  ],
  [
    1426204800,
    294.364,
    1,
    0
  ],
  [
    1426291200,
    283.587,
    1,
    0
  ],
  [
    1426377600,
    281.925,
    1,
    0
  ],
  [
    1426464000,
    289.35,
    1,
    0
  ],
  [
    1426550400,
    290.215,
    1,
    0
  ],
  [
    1426636800,
    284.176,
    1,
    0
  ],
  [
    1426723200,
    252.055,
    1,
    0
  ],
  [
    1426809600,
    262.37,
    1,
    0
  ],
  [
    1426896000,
    260.699,
    1,
    0
  ],
  [
    1426982400,
    259.917,
    1,
    0
  ],
  [
    1427068800,
    267.895,
    1,
    0
  ],
  [
    1427155200,
    266.577,
    1,
    0
  ],
  [
    1427241600,
    247.472,
    1,
    0
  ],
  [
    1427328000,
    246.276,
    1,
    0
  ],
  [
    1427414400,
    248.566,
    1,
    0
  ],
  [
    1427500800,
    246.975,
    1,
    0
  ],
  [
    1427587200,
    252.776,
    1,
    0
  ],
  [
    1427673600,
    241.131,
    1,
    0
  ],
  [
    1427760000,
    247.871,
    1,
    0
  ],
  [
    1427846400,
    244.002,
    1,
    0
  ],
  [
    1427932800,
    247.106,
    1,
    0
  ],
  [
    1428019200,
    253.097,
    1,
    0
  ],
  [
    1428105600,
    254.629,
    1,
    0
  ],
  [
    1428192000,
    253.402,
    1,
    0
  ],
  [
    1428278400,
    260.7,
    1,
    0
  ],
  [
    1428364800,
    255.405,
    1,
    0
  ],
  [
    1428451200,
    253.25,
    1,
    0
  ],
  [
    1428537600,
    246.099,
    1,
    0
  ],
  [
    1428624000,
    243.555,
    1,
    0
  ],
  [
    1428710400,
    235.948,
    1,
    0
  ],
  [
    1428796800,
    237.124,
    1,
    0
  ],
  [
    1428883200,
    235.289,
    1,
    0
  ],
  [
    1428969600,
    224.264,
    1,
    0
  ],
  [
    1429056000,
    221.748,
    1,
    0
  ],
  [
    1429142400,
    225.68,
    1,
    0
  ],
  [
    1429228800,
    228.456,
    1,
    0
  ],
  [
    1429315200,
    223.394,
    1,
    0
  ],
  [
    1429401600,
    223.757,
    1,
    0
  ],
  [
    1429488000,
    222.866,
    1,
    0
  ],
  [
    1429574400,
    224.531,
    1,
    0
  ],
  [
    1429660800,
    234.37,
    1,
    0
  ],
  [
    1429747200,
    234.084,
    1,
    0
  ],
  [
    1429833600,
    236.165,
    1,
    0
  ],
  [
    1429920000,
    231.459,
    1,
    0
  ],
  [
    1430006400,
    221.48,
    1,
    0
  ],
  [
    1430092800,
    219.103,
    1,
    0
  ],
  [
    1430179200,
    228.561,
    1,
    0
  ],
  [
    1430265600,
    225.483,
    1,
    0
  ],
  [
    1430352000,
    225.649,
    1,
    0
  ],
  [
    1430438400,
    236.699,
    1,
    0
  ],
  [
    1430524800,
    233.028,
    1,
    0
  ],
  [
    1430611200,
    234.226,
    1,
    0
  ],
  [
    1430697600,
    239.758,
    1,
    0
  ],
  [
    1430784000,
    238.655,
    1,
    0
  ],
  [
    1430870400,
    236.245,
    1,
    0
  ],
  [
    1430956800,
    229.944,
    1,
    0
  ],
  [
    1431043200,
    238.167,
    1,
    0
  ],
  [
    1431129600,
    245.249,
    1,
    0
  ],
  [
    1431216000,
    241.333,
    1,
    0
  ],
  [
    1431302400,
    240.855,
    1,
    0
  ],
  [
    1431388800,
    242.239,
    1,
    0
  ],
  [
    1431475200,
    241.636,
    1,
    0
  ],
  [
    1431561600,
    236.301,
    1,
    0
  ],
  [
    1431648000,
    237.595,
    1,
    0
  ],
  [
    1431734400,
    237.324,
    1,
    0
  ],
  [
    1431820800,
    236.521,
    1,
    0
  ],
  [
    1431907200,
    236.508,
    1,
    0
  ],
  [
    1431993600,
    233.46,
    1,
    0
  ],
  [
    1432080000,
    232.296,
    1,
    0
  ],
  [
    1432166400,
    233.963,
    1,
    0
  ],
  [
    1432252800,
    235.637,
    1,
    0
  ],
  [
    1432339200,
    239.296,
    1,
    0
  ],
  [
    1432425600,
    239.455,
    1,
    0
  ],
  [
    1432512000,
    240.87,
    1,
    0
  ],
  [
    1432598400,
    237.12,
    1,
    0
  ],
  [
    1432684800,
    236.966,
    1,
    0
  ],
  [
    1432771200,
    236.971,
    1,
    0
  ],
  [
    1432857600,
    237.346,
    1,
    0
  ],
  [
    1432944000,
    236.61,
    1,
    0
  ],
  [
    1433030400,
    233.085,
    1,
    0
  ],
  [
    1433116800,
    230.666,
    1,
    0
  ],
  [
    1433203200,
    222.961,
    1,
    0
  ],
  [
    1433289600,
    226.435,
    1,
    0
  ],
  [
    1433376000,
    225.925,
    1,
    0
  ],
  [
    1433462400,
    224.081,
    1,
    0
  ],
  [
    1433548800,
    225.042,
    1,
    0
  ],
  [
    1433635200,
    225.593,
    1,
    0
  ],
  [
    1433721600,
    223.412,
    1,
    0
  ],
  [
    1433808000,
    228.406,
    1,
    0
  ],
  [
    1433894400,
    228.951,
    1,
    0
  ],
  [
    1433980800,
    228.843,
    1,
    0
  ],
  [
    1434067200,
    229.809,
    1,
    0
  ],
  [
    1434153600,
    229.21,
    1,
    0
  ],
  [
    1434240000,
    232.219,
    1,
    0
  ],
  [
    1434326400,
    233.765,
    1,
    0
  ],
  [
    1434412800,
    236.122,
    1,
    0
  ],
  [
    1434499200,
    248.447,
    1,
    0
  ],
  [
    1434585600,
    250.664,
    1,
    0
  ],
  [
    1434672000,
    250.894,
    1,
    0
  ],
  [
    1434758400,
    244.022,
    1,
    0
  ],
  [
    1434844800,
    244.157,
    1,
    0
  ],
  [
    1434931200,
    244.456,
    1,
    0
  ],
  [
    1435017600,
    246.688,
    1,
    0
  ],
  [
    1435104000,
    244.282,
    1,
    0
  ],
  [
    1435190400,
    240.365,
    1,
    0
  ],
  [
    1435276800,
    242.604,
    1,
    0
  ],
  [
    1435363200,
    243.549,
    1,
    0
  ],
  [
    1435449600,
    250.955,
    1,
    0
  ],
  [
    1435536000,
    248.721,
    1,
    0
  ],
  [
    1435622400,
    257.036,
    1,
    0
  ],
  [
    1435708800,
    263.345,
    1,
    0
  ],
  [
    1435795200,
    258.552,
    1,
    0
  ],
  [
    1435881600,
    255.459,
    1,
    0
  ],
  [
    1435968000,
    256.491,
    1,
    0
  ],
  [
    1436054400,
    260.805,
    1,
    0
  ],
  [
    1436140800,
    271.108,
    1,
    0
  ],
  [
    1436227200,
    269.963,
    1,
    0
  ],
  [
    1436313600,
    265.982,
    1,
    0
  ],
  [
    1436400000,
    270.827,
    1,
    0
  ],
  [
    1436486400,
    269.156,
    1,
    0
  ],
  [
    1436572800,
    284.88,
    1,
    0
  ],
  [
    1436659200,
    293.14,
    1,
    0
  ],
  [
    1436745600,
    310.827,
    1,
    0
  ],
  [
    1436832000,
    292.034,
    1,
    0
  ],
  [
    1436918400,
    288.715,
    1,
    0
  ],
  [
    1437004800,
    286.548,
    1,
    0
  ],
  [
    1437091200,
    278.094,
    1,
    0
  ],
  [
    1437177600,
    279.408,
    1,
    0
  ],
  [
    1437264000,
    274.598,
    1,
    0
  ],
  [
    1437350400,
    273.292,
    1,
    0
  ],
  [
    1437436800,
    278.633,
    1,
    0
  ],
  [
    1437523200,
    275.403,
    1,
    0
  ],
  [
    1437609600,
    277.338,
    1,
    0
  ],
  [
    1437696000,
    275.737,
    1,
    0
  ],
  [
    1437782400,
    287.729,
    1,
    0
  ],
  [
    1437868800,
    288.535,
    1,
    0
  ],
  [
    1437955200,
    292.172,
    1,
    0
  ],
  [
    1438041600,
    294.066,
    1,
    0
  ],
  [
    1438128000,
    294.483,
    1,
    0
  ],
  [
    1438214400,
    288.54,
    1,
    0
  ],
  [
    1438300800,
    287.504,
    1,
    0
  ],
  [
    1438387200,
    284.649,
    1,
    0
  ],
  [
    1438473600,
    279.839,
    1,
    0
  ],
  [
    1438560000,
    282.674,
    1,
    0
  ],
  [
    1438646400,
    281.801,
    1,
    0
  ],
  [
    1438732800,
    284.539,
    1,
    0
  ],
  [
    1438819200,
    281.409,
    1,
    0
  ],
  [
    1438905600,
    278.651,
    1,
    98.40691901
  ],
  [
    1438992000,
    279.651,
    1,
    99.96103803
  ],
  [
    1439078400,
    260.468,
    1,
    359.15474508
  ],
  [
    1439164800,
    265.935,
    1,
    395.89685111
  ],
  [
    1439251200,
    264.332,
    1,
    367.69975517
  ],
  [
    1439337600,
    269.24,
    1,
    262.53229974
  ],
  [
    1439424000,
    266.065,
    1,
    223.33817394
  ],
  [
    1439510400,
    264.285,
    1,
    141.03925629
  ],
  [
    1439596800,
    265.831,
    1,
    160.19416306
  ],
  [
    1439683200,
    261.501,
    1,
    156.72538102
  ],
  [
    1439769600,
    258.006,
    1,
    173.30261426
  ],
  [
    1439856000,
    257.955,
    1,
    219.8954888
  ],
  [
    1439942400,
    229.554,
    1,
    179.62815156
  ],
  [
    1440028800,
    229.022,
    1,
    178.164845
  ],
  [
    1440115200,
    235.137,
    1,
    159.03646238
  ],
  [
    1440201600,
    232.824,
    1,
    166.60393425
  ],
  [
    1440288000,
    230.511,
    1,
    167.38385349
  ],
  [
    1440374400,
    226.501,
    1,
    169.04321218
  ],
  [
    1440460800,
    203.868,
    1,
    170.39550667
  ],
  [
    1440547200,
    224.564,
    1,
    196.70471168
  ],
  [
    1440633600,
    227.143,
    1,
    195.33133825
  ],
  [
    1440720000,
    221.128,
    1,
    195.62098037
  ],
  [
    1440806400,
    231.634,
    1,
    194.19023826
  ],
  [
    1440892800,
    230.368,
    1,
    195.41425263
  ],
  [
    1440979200,
    228.319,
    1,
    181.3206798
  ],
  [
    1441065600,
    230.909,
    1,
    170.07615933
  ],
  [
    1441152000,
    227.912,
    1,
    169.50171055
  ],
  [
    1441238400,
    229.438,
    1,
    175.87250972
  ],
  [
    1441324800,
    227.807,
    1,
    178.78433527
  ],
  [
    1441411200,
    229.621,
    1,
    179.32976164
  ],
  [
    1441497600,
    235.769,
    1,
    176.14155933
  ],
  [
    1441584000,
    241.211,
    1,
    186.04208123
  ],
  [
    1441670400,
    239.956,
    1,
    193.21217782
  ],
  [
    1441756800,
    243.358,
    1,
    197.76842311
  ],
  [
    1441843200,
    238.636,
    1,
    196.98377977
  ],
  [
    1441929600,
    239.611,
    1,
    205.90975105
  ],
  [
    1442016000,
    239.862,
    1,
    240.44074086
  ],
  [
    1442102400,
    234.746,
    1,
    232.23093893
  ],
  [
    1442188800,
    231.281,
    1,
    246.13866844
  ],
  [
    1442275200,
    231.567,
    1,
    260.58349313
  ],
  [
    1442361600,
    230.587,
    1,
    244.96705613
  ],
  [
    1442448000,
    230.012,
    1,
    255.94341943
  ],
  [
    1442534400,
    233.877,
    1,
    269.86420076
  ],
  [
    1442620800,
    233.029,
    1,
    274.27986954
  ],
  [
    1442707200,
    231.291,
    1,
    263.90579805
  ],
  [
    1442793600,
    230.805,
    1,
    241.09342063
  ],
  [
    1442880000,
    227.498,
    1,
    248.19795789
  ],
  [
    1442966400,
    231.754,
    1,
    256.41780278
  ],
  [
    1443052800,
    230.358,
    1,
    256.86778264
  ],
  [
    1443139200,
    234.362,
    1,
    285.79651257
  ],
  [
    1443225600,
    235.126,
    1,
    318.75872728
  ],
  [
    1443312000,
    234.233,
    1,
    298.33354136
  ],
  [
    1443398400,
    233.348,
    1,
    322.32657273
  ],
  [
    1443484800,
    237.898,
    1,
    411.92247689
  ],
  [
    1443571200,
    237.42,
    1,
    355.83465723
  ],
  [
    1443657600,
    236.385,
    1,
    323.70421089
  ],
  [
    1443744000,
    237.72,
    1,
    346.15418219
  ],
  [
    1443830400,
    237.334,
    1,
    348.94053717
  ],
  [
    1443916800,
    238.477,
    1,
    345.6428853
  ],
  [
    1444003200,
    238.228,
    1,
    355.49520242
  ],
  [
    1444089600,
    240.254,
    1,
    390.35161118
  ],
  [
    1444176000,
    246.061,
    1,
    379.77416756
  ],
  [
    1444262400,
    243.216,
    1,
    398.88542475
  ],
  [
    1444348800,
    242.22,
    1,
    373.51443124
  ],
  [
    1444435200,
    243.727,
    1,
    381.24518999
  ],
  [
    1444521600,
    244.747,
    1,
    388.3658602
  ],
  [
    1444608000,
    246.815,
    1,
    390.707489
  ],
  [
    1444694400,
    244.952,
    1,
    388.66463569
  ],
  [
    1444780800,
    249.645,
    1,
    411.22189625
  ],
  [
    1444867200,
    252.216,
    1,
    483.11318153
  ],
  [
    1444953600,
    254.196,
    1,
    454.60821169
  ],
  [
    1445040000,
    262.721,
    1,
    494.4638404
  ],
  [
    1445126400,
    270.994,
    1,
    494.64186953
  ],
  [
    1445212800,
    261.989,
    1,
    505.26987752
  ],
  [
    1445299200,
    264.171,
    1,
    532.86495776
  ],
  [
    1445385600,
    269.391,
    1,
    613.31023288
  ],
  [
    1445472000,
    266.613,
    1,
    627.30699368
  ],
  [
    1445558400,
    274.444,
    1,
    469.09094017
  ],
  [
    1445644800,
    277.719,
    1,
    515.5039695
  ],
  [
    1445731200,
    282.673,
    1,
    501.39862249
  ],
  [
    1445817600,
    283.479,
    1,
    469.12405486
  ],
  [
    1445904000,
    289.689,
    1,
    391.94089139
  ],
  [
    1445990400,
    296.799,
    1,
    354.40209967
  ],
  [
    1446076800,
    303.445,
    1,
    303.64570981
  ],
  [
    1446163200,
    315.523,
    1,
    263.5904162
  ],
  [
    1446249600,
    330.743,
    1,
    332.3689489
  ],
  [
    1446336000,
    315.796,
    1,
    351.24271201
  ],
  [
    1446422400,
    326.828,
    1,
    313.10462432
  ],
  [
    1446508800,
    360.057,
    1,
    367.44822096
  ],
  [
    1446595200,
    410.019,
    1,
    406.34160844
  ],
  [
    1446681600,
    407.942,
    1,
    436.2913386
  ],
  [
    1446768000,
    380.75,
    1,
    426.99098917
  ],
  [
    1446854400,
    378.916,
    1,
    413.80289442
  ],
  [
    1446940800,
    386.5,
    1,
    416.35336928
  ],
  [
    1447027200,
    366.117,
    1,
    332.59175145
  ],
  [
    1447113600,
    374.731,
    1,
    379.48403757
  ],
  [
    1447200000,
    332.569,
    1,
    371.39085775
  ],
  [
    1447286400,
    318.552,
    1,
    399.88702054
  ],
  [
    1447372800,
    339.868,
    1,
    372.66963602
  ],
  [
    1447459200,
    334.313,
    1,
    375.95066388
  ],
  [
    1447545600,
    333.051,
    1,
    373.70359856
  ],
  [
    1447632000,
    319.735,
    1,
    352.84373945
  ],
  [
    1447718400,
    330.362,
    1,
    357.16818675
  ],
  [
    1447804800,
    334.593,
    1,
    336.87906131
  ],
  [
    1447891200,
    334.679,
    1,
    338.4996455
  ],
  [
    1447977600,
    326.411,
    1,
    341.52877105
  ],
  [
    1448064000,
    322.092,
    1,
    346.79862784
  ],
  [
    1448150400,
    326.332,
    1,
    336.93848662
  ],
  [
    1448236800,
    323.8,
    1,
    334.13927148
  ],
  [
    1448323200,
    321.847,
    1,
    342.12946083
  ],
  [
    1448409600,
    320.033,
    1,
    360.7123334
  ],
  [
    1448496000,
    332.103,
    1,
    381.48159635
  ],
  [
    1448582400,
    350.123,
    1,
    398.00363306
  ],
  [
    1448668800,
    355.021,
    1,
    397.53141992
  ],
  [
    1448755200,
    357.805,
    1,
    394.22378773
  ],
  [
    1448841600,
    377.651,
    1,
    429.57844208
  ],
  [
    1448928000,
    376.392,
    1,
    429.11720142
  ],
  [
    1449014400,
    351.764,
    1,
    411.61630345
  ],
  [
    1449100800,
    359.819,
    1,
    451.72293446
  ],
  [
    1449187200,
    361.451,
    1,
    445.22497687
  ],
  [
    1449273600,
    366.922,
    1,
    440.91609878
  ],
  [
    1449360000,
    394.666,
    1,
    455.75283066
  ],
  [
    1449446400,
    393.144,
    1,
    471.19793852
  ],
  [
    1449532800,
    394.135,
    1,
    500.98956036
  ],
  [
    1449619200,
    414.053,
    1,
    522.327263
  ],
  [
    1449705600,
    416.823,
    1,
    525.66975687
  ],
  [
    1449792000,
    417.8,
    1,
    498.82457445
  ],
  [
    1449878400,
    451.929,
    1,
    466.64752974
  ],
  [
    1449964800,
    433.242,
    1,
    443.85865517
  ],
  [
    1450051200,
    434.351,
    1,
    451.58248444
  ],
  [
    1450137600,
    449.68,
    1,
    449.6826981
  ],
  [
    1450224000,
    464.222,
    1,
    456.00031433
  ],
  [
    1450310400,
    450.092,
    1,
    456.29949838
  ],
  [
    1450396800,
    455.355,
    1,
    491.09757242
  ],
  [
    1450483200,
    463.285,
    1,
    503.26923766
  ],
  [
    1450569600,
    462.123,
    1,
    505.66091001
  ],
  [
    1450656000,
    437.627,
    1,
    482.91847502
  ],
  [
    1450742400,
    437.92,
    1,
    487.94728315
  ],
  [
    1450828800,
    436.852,
    1,
    506.07964708
  ],
  [
    1450915200,
    443.091,
    1,
    515.56200425
  ],
  [
    1451001600,
    454.855,
    1,
    527.67829901
  ],
  [
    1451088000,
    455.756,
    1,
    523.39664158
  ],
  [
    1451174400,
    416.514,
    1,
    488.19807634
  ],
  [
    1451260800,
    423.343,
    1,
    494.35398362
  ],
  [
    1451347200,
    422.098,
    1,
    500.21390438
  ],
  [
    1451433600,
    433.3,
    1,
    495.620286
  ],
  [
    1451520000,
    425.875,
    1,
    466.91802855
  ],
  [
    1451606400,
    430.721,
    1,
    461.29962986
  ],
  [
    1451692800,
    434.622,
    1,
    458.7518907
  ],
  [
    1451779200,
    433.578,
    1,
    462.02487133
  ],
  [
    1451865600,
    430.061,
    1,
    442.42910565
  ],
  [
    1451952000,
    433.069,
    1,
    454.35698796
  ],
  [
    1452038400,
    431.856,
    1,
    454.57186525
  ],
  [
    1452124800,
    430.011,
    1,
    449.89595114
  ],
  [
    1452211200,
    457.522,
    1,
    485.30472489
  ],
  [
    1452297600,
    453.281,
    1,
    459.94981233
  ],
  [
    1452384000,
    448.228,
    1,
    454.79662769
  ],
  [
    1452470400,
    447.947,
    1,
    448.298466
  ],
  [
    1452556800,
    445.767,
    1,
    418.12869337
  ],
  [
    1452643200,
    435.186,
    1,
    379.09509042
  ],
  [
    1452729600,
    431.614,
    1,
    385.66577908
  ],
  [
    1452816000,
    429.549,
    1,
    360.75636816
  ],
  [
    1452902400,
    366.679,
    1,
    303.98007063
  ],
  [
    1452988800,
    387.269,
    1,
    316.61352562
  ],
  [
    1453075200,
    383.551,
    1,
    288.68374705
  ],
  [
    1453161600,
    386.727,
    1,
    271.17993956
  ],
  [
    1453248000,
    376.689,
    1,
    275.54276268
  ],
  [
    1453334400,
    419.392,
    1,
    275.72350861
  ],
  [
    1453420800,
    408.675,
    1,
    259.99783693
  ],
  [
    1453507200,
    388.247,
    1,
    259.08005018
  ],
  [
    1453593600,
    389.958,
    1,
    195.58531447
  ],
  [
    1453680000,
    396.617,
    1,
    184.72562481
  ],
  [
    1453766400,
    393.291,
    1,
    154.01131713
  ],
  [
    1453852800,
    392.297,
    1,
    174.35112265
  ],
  [
    1453939200,
    394.889,
    1,
    167.76587745
  ],
  [
    1454025600,
    368.763,
    1,
    144.38362601
  ],
  [
    1454112000,
    379.522,
    1,
    148.45258398
  ],
  [
    1454198400,
    378.134,
    1,
    155.71707422
  ],
  [
    1454284800,
    368.529,
    1,
    160.74157754
  ],
  [
    1454371200,
    374.138,
    1,
    169.45193009
  ],
  [
    1454457600,
    374.443,
    1,
    157.10191991
  ],
  [
    1454544000,
    373.221,
    1,
    146.40653379
  ],
  [
    1454630400,
    388.853,
    1,
    150.13165617
  ],
  [
    1454716800,
    381.347,
    1,
    151.16381395
  ],
  [
    1454803200,
    376.043,
    1,
    147.90576019
  ],
  [
    1454889600,
    378.584,
    1,
    131.66444666
  ],
  [
    1454976000,
    374.379,
    1,
    118.14721893
  ],
  [
    1455062400,
    376.042,
    1,
    93.74707073
  ],
  [
    1455148800,
    382.492,
    1,
    86.21908446
  ],
  [
    1455235200,
    379.686,
    1,
    61.31761012
  ],
  [
    1455321600,
    384.641,
    1,
    69.44959032
  ],
  [
    1455408000,
    392.932,
    1,
    74.19046648
  ],
  [
    1455494400,
    407.568,
    1,
    76.48071037
  ],
  [
    1455580800,
    401.432,
    1,
    74.02410847
  ],
  [
    1455667200,
    407.656,
    1,
    91.19882236
  ],
  [
    1455753600,
    416.572,
    1,
    114.14119826
  ],
  [
    1455840000,
    422.73,
    1,
    96.53177079
  ],
  [
    1455926400,
    421.601,
    1,
    90.31397743
  ],
  [
    1456012800,
    437.773,
    1,
    100.13472588
  ],
  [
    1456099200,
    438.989,
    1,
    94.30868285
  ],
  [
    1456185600,
    438.255,
    1,
    76.78352112
  ],
  [
    1456272000,
    420.956,
    1,
    76.07175708
  ],
  [
    1456358400,
    425.251,
    1,
    67.98990186
  ],
  [
    1456444800,
    424.486,
    1,
    68.61255821
  ],
  [
    1456531200,
    432.927,
    1,
    72.48612402
  ],
  [
    1456617600,
    432.623,
    1,
    67.95068732
  ],
  [
    1456704000,
    432.38,
    1,
    67.22419934
  ],
  [
    1456790400,
    437.969,
    1,
    68.95488174
  ],
  [
    1456876800,
    435.171,
    1,
    56.7963769
  ],
  [
    1456963200,
    424.458,
    1,
    50.51664179
  ],
  [
    1457049600,
    422.263,
    1,
    46.18030379
  ],
  [
    1457136000,
    408.181,
    1,
    39.97541818
  ],
  [
    1457222400,
    397.797,
    1,
    36.00103171
  ],
  [
    1457308800,
    407.289,
    1,
    36.483988
  ],
  [
    1457395200,
    414.447,
    1,
    44.12042744
  ],
  [
    1457481600,
    412.424,
    1,
    42.19007343
  ],
  [
    1457568000,
    413.941,
    1,
    35.26834173
  ],
  [
    1457654400,
    417.168,
    1,
    36.83374979
  ],
  [
    1457740800,
    421.607,
    1,
    37.31035398
  ],
  [
    1457827200,
    412.581,
    1,
    29.63688475
  ],
  [
    1457913600,
    414.486,
    1,
    31.76843896
  ],
  [
    1458000000,
    416.216,
    1,
    32.65899265
  ],
  [
    1458086400,
    416.938,
    1,
    31.03233201
  ],
  [
    1458172800,
    418.175,
    1,
    33.28040938
  ],
  [
    1458259200,
    419.756,
    1,
    37.93341527
  ],
  [
    1458345600,
    408.714,
    1,
    38.33262992
  ],
  [
    1458432000,
    410.587,
    1,
    38.7488793
  ],
  [
    1458518400,
    413.106,
    1,
    40.16392008
  ],
  [
    1458604800,
    412.736,
    1,
    35.79794616
  ],
  [
    1458691200,
    418.085,
    1,
    36.77962225
  ],
  [
    1458777600,
    418.424,
    1,
    33.62942245
  ],
  [
    1458864000,
    416.508,
    1,
    36.98906779
  ],
  [
    1458950400,
    417.365,
    1,
    38.76011107
  ],
  [
    1459036800,
    418.14,
    1,
    38.06082231
  ],
  [
    1459123200,
    426.548,
    1,
    40.89077209
  ],
  [
    1459209600,
    424.304,
    1,
    36.10268279
  ],
  [
    1459296000,
    416.834,
    1,
    35.91755491
  ],
  [
    1459382400,
    415.257,
    1,
    34.83758117
  ],
  [
    1459468800,
    416.76,
    1,
    36.55597074
  ],
  [
    1459555200,
    418.422,
    1,
    35.97595997
  ],
  [
    1459641600,
    421.173,
    1,
    36.21965378
  ],
  [
    1459728000,
    421.305,
    1,
    36.36548039
  ],
  [
    1459814400,
    420.864,
    1,
    38.02632887
  ],
  [
    1459900800,
    424.405,
    1,
    40.19024801
  ],
  [
    1459987200,
    423.491,
    1,
    39.43412917
  ],
  [
    1460073600,
    422.587,
    1,
    41.59402744
  ],
  [
    1460160000,
    418.393,
    1,
    42.84522008
  ],
  [
    1460246400,
    419.652,
    1,
    45.87052473
  ],
  [
    1460332800,
    422.166,
    1,
    49.80069859
  ],
  [
    1460419200,
    423.552,
    1,
    50.10232158
  ],
  [
    1460505600,
    426.02,
    1,
    58.63109697
  ],
  [
    1460592000,
    424.89,
    1,
    52.58728345
  ],
  [
    1460678400,
    424.9,
    1,
    50.79522197
  ],
  [
    1460764800,
    429.16,
    1,
    52.14447748
  ],
  [
    1460851200,
    431.267,
    1,
    50.1735792
  ],
  [
    1460937600,
    428.324,
    1,
    46.93390722
  ],
  [
    1461024000,
    428.84,
    1,
    47.70040533
  ],
  [
    1461110400,
    434.9,
    1,
    49.39687876
  ],
  [
    1461196800,
    442.505,
    1,
    52.33351625
  ],
  [
    1461283200,
    446.732,
    1,
    54.92514265
  ],
  [
    1461369600,
    446.709,
    1,
    57.12610458
  ],
  [
    1461456000,
    453.833,
    1,
    54.78461362
  ],
  [
    1461542400,
    458.463,
    1,
    57.65285527
  ],
  [
    1461628800,
    463.604,
    1,
    62.43311997
  ],
  [
    1461715200,
    465.471,
    1,
    63.15701636
  ],
  [
    1461801600,
    440.169,
    1,
    57.21384563
  ],
  [
    1461888000,
    448.044,
    1,
    62.01438371
  ],
  [
    1461974400,
    454.768,
    1,
    60.74337059
  ],
  [
    1462060800,
    448.023,
    1,
    51.70955954
  ],
  [
    1462147200,
    451.909,
    1,
    50.63423182
  ],
  [
    1462233600,
    442.735,
    1,
    44.33197387
  ],
  [
    1462320000,
    449.916,
    1,
    48.49356262
  ],
  [
    1462406400,
    448.008,
    1,
    48.01198564
  ],
  [
    1462492800,
    447.66,
    1,
    45.3485015
  ],
  [
    1462579200,
    459.387,
    1,
    49.21166973
  ],
  [
    1462665600,
    457.983,
    1,
    49.15325545
  ],
  [
    1462752000,
    457.753,
    1,
    48.24185796
  ],
  [
    1462838400,
    461.401,
    1,
    49.72261436
  ],
  [
    1462924800,
    453.044,
    1,
    47.93512775
  ],
  [
    1463011200,
    452.734,
    1,
    45.38486523
  ],
  [
    1463097600,
    454.74,
    1,
    44.67652405
  ],
  [
    1463184000,
    456.74,
    1,
    43.88228625
  ],
  [
    1463270400,
    455.733,
    1,
    44.38640746
  ],
  [
    1463356800,
    457.72,
    1,
    45.52434755
  ],
  [
    1463443200,
    454.288,
    1,
    40.56541267
  ],
  [
    1463529600,
    453.957,
    1,
    36.55018881
  ],
  [
    1463616000,
    454.578,
    1,
    33.35789188
  ],
  [
    1463702400,
    440.839,
    1,
    29.818857
  ],
  [
    1463788800,
    443.348,
    1,
    33.51486196
  ],
  [
    1463875200,
    443.315,
    1,
    31.23872541
  ],
  [
    1463961600,
    439.555,
    1,
    31.06681179
  ],
  [
    1464048000,
    444.505,
    1,
    33.34270969
  ],
  [
    1464134400,
    446.784,
    1,
    34.79138439
  ],
  [
    1464220800,
    448.2,
    1,
    35.92123296
  ],
  [
    1464307200,
    462.846,
    1,
    38.37542492
  ],
  [
    1464393600,
    475.869,
    1,
    43.94841105
  ],
  [
    1464480000,
    528.905,
    1,
    44.22615414
  ],
  [
    1464566400,
    527.659,
    1,
    42.46513275
  ],
  [
    1464652800,
    536.82,
    1,
    42.10385964
  ],
  [
    1464739200,
    530.715,
    1,
    37.83740429
  ],
  [
    1464825600,
    536.485,
    1,
    38.52951738
  ],
  [
    1464912000,
    539.747,
    1,
    39.27260689
  ],
  [
    1464998400,
    568.73,
    1,
    41.57747756
  ],
  [
    1465084800,
    580.433,
    1,
    42.57808718
  ],
  [
    1465171200,
    578.081,
    1,
    41.37661761
  ],
  [
    1465257600,
    589.616,
    1,
    42.48841617
  ],
  [
    1465344000,
    574.556,
    1,
    39.27299074
  ],
  [
    1465430400,
    578.486,
    1,
    39.93138676
  ],
  [
    1465516800,
    575.041,
    1,
    40.1116769
  ],
  [
    1465603200,
    580.679,
    1,
    41.59830076
  ],
  [
    1465689600,
    615.767,
    1,
    42.79339509
  ],
  [
    1465776000,
    672.006,
    1,
    42.62969589
  ],
  [
    1465862400,
    696.032,
    1,
    39.67735132
  ],
  [
    1465948800,
    683.119,
    1,
    36.409323
  ],
  [
    1466035200,
    727.089,
    1,
    39.49145362
  ],
  [
    1466121600,
    769.733,
    1,
    37.29308482
  ],
  [
    1466208000,
    733.95,
    1,
    49.33553812
  ],
  [
    1466294400,
    753.582,
    1,
    69.31338012
  ],
  [
    1466380800,
    755.912,
    1,
    61.18812683
  ],
  [
    1466467200,
    698.16,
    1,
    60.54057804
  ],
  [
    1466553600,
    676.136,
    1,
    47.96651532
  ],
  [
    1466640000,
    562.754,
    1,
    42.16901957
  ],
  [
    1466726400,
    625.575,
    1,
    45.72214792
  ],
  [
    1466812800,
    665.281,
    1,
    46.51403921
  ],
  [
    1466899200,
    665.931,
    1,
    46.69562656
  ],
  [
    1466985600,
    629.349,
    1,
    45.57791747
  ],
  [
    1467072000,
    658.102,
    1,
    47.25164422
  ],
  [
    1467158400,
    644.122,
    1,
    52.90877429
  ],
  [
    1467244800,
    640.591,
    1,
    50.82885685
  ],
  [
    1467331200,
    672.515,
    1,
    54.06894944
  ],
  [
    1467417600,
    676.734,
    1,
    55.39734774
  ],
  [
    1467504000,
    704.968,
    1,
    58.1618375
  ],
  [
    1467590400,
    658.804,
    1,
    56.32680979
  ],
  [
    1467676800,
    683.209,
    1,
    59.63036989
  ],
  [
    1467763200,
    670.418,
    1,
    63.39170559
  ],
  [
    1467849600,
    678.09,
    1,
    64.35995027
  ],
  [
    1467936000,
    640.688,
    1,
    63.33850701
  ],
  [
    1468022400,
    666.384,
    1,
    58.36411887
  ],
  [
    1468108800,
    650.599,
    1,
    59.35905624
  ],
  [
    1468195200,
    648.484,
    1,
    59.27045727
  ],
  [
    1468281600,
    648.283,
    1,
    62.0544654
  ],
  [
    1468368000,
    664.797,
    1,
    63.27422763
  ],
  [
    1468454400,
    652.923,
    1,
    61.61452878
  ],
  [
    1468540800,
    659.171,
    1,
    57.32370359
  ],
  [
    1468627200,
    663.781,
    1,
    55.68912865
  ],
  [
    1468713600,
    661.993,
    1,
    56.95298318
  ],
  [
    1468800000,
    680.045,
    1,
    60.71017274
  ],
  [
    1468886400,
    672.229,
    1,
    60.97536419
  ],
  [
    1468972800,
    672.895,
    1,
    58.17418669
  ],
  [
    1469059200,
    665.128,
    1,
    52.90802934
  ],
  [
    1469145600,
    664.703,
    1,
    52.59974678
  ],
  [
    1469232000,
    650.568,
    1,
    44.21361678
  ],
  [
    1469318400,
    655.413,
    1,
    45.88024053
  ],
  [
    1469404800,
    661.268,
    1,
    51.8686318
  ],
  [
    1469491200,
    654.262,
    1,
    47.47772198
  ],
  [
    1469577600,
    651.37,
    1,
    54.28173803
  ],
  [
    1469664000,
    654.735,
    1,
    50.49551912
  ],
  [
    1469750400,
    655.259,
    1,
    51.07201035
  ],
  [
    1469836800,
    657.141,
    1,
    51.33392702
  ],
  [
    1469923200,
    655.099,
    1,
    52.64799486
  ],
  [
    1470009600,
    624.52,
    1,
    52.29697366
  ],
  [
    1470096000,
    608.793,
    1,
    55.52856726
  ],
  [
    1470182400,
    546.979,
    1,
    61.36082673
  ],
  [
    1470268800,
    567.696,
    1,
    55.09739409
  ],
  [
    1470355200,
    577.557,
    1,
    52.24490719
  ],
  [
    1470441600,
    575.25,
    1,
    52.72733939
  ],
  [
    1470528000,
    590.251,
    1,
    54.40051244
  ],
  [
    1470614400,
    592.249,
    1,
    54.24767575
  ],
  [
    1470700800,
    590.558,
    1,
    52.45115106
  ],
  [
    1470787200,
    586.795,
    1,
    47.97879038
  ],
  [
    1470873600,
    592.348,
    1,
    48.81720785
  ],
  [
    1470960000,
    589.347,
    1,
    50.41678429
  ],
  [
    1471046400,
    587.124,
    1,
    49.90174747
  ],
  [
    1471132800,
    585.532,
    1,
    50.5592733
  ],
  [
    1471219200,
    570.579,
    1,
    50.69965613
  ],
  [
    1471305600,
    568.233,
    1,
    50.86270017
  ],
  [
    1471392000,
    577.905,
    1,
    51.87750229
  ],
  [
    1471478400,
    573.757,
    1,
    52.88619123
  ],
  [
    1471564800,
    574.299,
    1,
    53.32395543
  ],
  [
    1471651200,
    576.55,
    1,
    52.80197086
  ],
  [
    1471737600,
    581.546,
    1,
    51.914943
  ],
  [
    1471824000,
    581.673,
    1,
    51.86285174
  ],
  [
    1471910400,
    587.012,
    1,
    52.4309792
  ],
  [
    1471996800,
    582.881,
    1,
    52.42631385
  ],
  [
    1472083200,
    580.065,
    1,
    52.57926796
  ],
  [
    1472169600,
    577.732,
    1,
    50.62850533
  ],
  [
    1472256000,
    579.444,
    1,
    51.40515077
  ],
  [
    1472342400,
    570.716,
    1,
    51.36218006
  ],
  [
    1472428800,
    576.07,
    1,
    52.62306912
  ],
  [
    1472515200,
    574.645,
    1,
    52.06626921
  ],
  [
    1472601600,
    577.781,
    1,
    51.46673436
  ],
  [
    1472688000,
    575.629,
    1,
    48.99345482
  ],
  [
    1472774400,
    571.888,
    1,
    47.75124411
  ],
  [
    1472860800,
    575.427,
    1,
    47.16228178
  ],
  [
    1472947200,
    598.116,
    1,
    51.05687726
  ],
  [
    1473033600,
    607.134,
    1,
    52.18976722
  ],
  [
    1473120000,
    606.734,
    1,
    51.99670914
  ],
  [
    1473206400,
    610.54,
    1,
    52.40776666
  ],
  [
    1473292800,
    614.244,
    1,
    53.14402886
  ],
  [
    1473379200,
    626.545,
    1,
    54.93262141
  ],
  [
    1473465600,
    622.822,
    1,
    53.29414281
  ],
  [
    1473552000,
    623.171,
    1,
    51.98289957
  ],
  [
    1473638400,
    606.681,
    1,
    51.93030661
  ],
  [
    1473724800,
    607.638,
    1,
    50.45653835
  ],
  [
    1473811200,
    608.914,
    1,
    51.06025794
  ],
  [
    1473897600,
    610.152,
    1,
    50.74788742
  ],
  [
    1473984000,
    607.977,
    1,
    50.96587337
  ],
  [
    1474070400,
    607.651,
    1,
    48.72121552
  ],
  [
    1474156800,
    606.638,
    1,
    47.96922444
  ],
  [
    1474243200,
    610.309,
    1,
    48.01461738
  ],
  [
    1474329600,
    609.097,
    1,
    45.93630275
  ],
  [
    1474416000,
    598.404,
    1,
    42.05937755
  ],
  [
    1474502400,
    598.487,
    1,
    44.65587739
  ],
  [
    1474588800,
    596.263,
    1,
    44.90371798
  ],
  [
    1474675200,
    602.961,
    1,
    45.25952726
  ],
  [
    1474761600,
    602.749,
    1,
    46.89741295
  ],
  [
    1474848000,
    600.807,
    1,
    45.62074778
  ],
  [
    1474934400,
    608.022,
    1,
    47.26575922
  ],
  [
    1475020800,
    606.243,
    1,
    46.25196454
  ],
  [
    1475107200,
    605.019,
    1,
    45.56964027
  ],
  [
    1475193600,
    605.715,
    1,
    46.32265219
  ],
  [
    1475280000,
    609.929,
    1,
    46.1955435
  ],
  [
    1475366400,
    613.948,
    1,
    46.58108375
  ],
  [
    1475452800,
    610.968,
    1,
    46.27493751
  ],
  [
    1475539200,
    612.052,
    1,
    45.50403331
  ],
  [
    1475625600,
    610.218,
    1,
    45.93838927
  ],
  [
    1475712000,
    612.47,
    1,
    47.01795598
  ],
  [
    1475798400,
    612.608,
    1,
    47.66783902
  ],
  [
    1475884800,
    617.341,
    1,
    48.70924728
  ],
  [
    1475971200,
    619.172,
    1,
    50.68699041
  ],
  [
    1476057600,
    616.822,
    1,
    51.14313431
  ],
  [
    1476144000,
    619.238,
    1,
    52.64554853
  ],
  [
    1476230400,
    640.871,
    1,
    54.38207491
  ],
  [
    1476316800,
    636.03,
    1,
    53.40661002
  ],
  [
    1476403200,
    637.2,
    1,
    53.14827635
  ],
  [
    1476489600,
    640.634,
    1,
    53.67196991
  ],
  [
    1476576000,
    639.226,
    1,
    53.35019238
  ],
  [
    1476662400,
    642.279,
    1,
    53.72202148
  ],
  [
    1476748800,
    639.651,
    1,
    53.32246851
  ],
  [
    1476835200,
    638.109,
    1,
    50.67333196
  ],
  [
    1476921600,
    630.717,
    1,
    52.3577364
  ],
  [
    1477008000,
    631.011,
    1,
    52.02197911
  ],
  [
    1477094400,
    633.334,
    1,
    51.99615776
  ],
  [
    1477180800,
    659.085,
    1,
    54.33332784
  ],
  [
    1477267200,
    657.252,
    1,
    54.60173462
  ],
  [
    1477353600,
    653.955,
    1,
    54.64378823
  ],
  [
    1477440000,
    658.365,
    1,
    57.83197618
  ],
  [
    1477526400,
    679.088,
    1,
    58.89135563
  ],
  [
    1477612800,
    687.821,
    1,
    59.94135025
  ],
  [
    1477699200,
    690.298,
    1,
    62.18732827
  ],
  [
    1477785600,
    708.684,
    1,
    67.96037553
  ],
  [
    1477872000,
    702.442,
    1,
    62.51653154
  ],
  [
    1477958400,
    703.115,
    1,
    64.21669361
  ],
  [
    1478044800,
    728.154,
    1,
    67.62391226
  ],
  [
    1478131200,
    741.747,
    1,
    68.76814819
  ],
  [
    1478217600,
    700.908,
    1,
    64.52785373
  ],
  [
    1478304000,
    702.864,
    1,
    63.34162431
  ],
  [
    1478390400,
    703.678,
    1,
    63.77649884
  ],
  [
    1478476800,
    704.152,
    1,
    64.92932162
  ],
  [
    1478563200,
    705.36,
    1,
    65.00234995
  ],
  [
    1478649600,
    708.802,
    1,
    65.72536001
  ],
  [
    1478736000,
    722.844,
    1,
    67.93518919
  ],
  [
    1478822400,
    715.555,
    1,
    67.6794953
  ],
  [
    1478908800,
    716.752,
    1,
    69.95842036
  ],
  [
    1478995200,
    705.196,
    1,
    71.40176885
  ],
  [
    1479081600,
    701.997,
    1,
    69.73734143
  ],
  [
    1479168000,
    705.794,
    1,
    71.62549029
  ],
  [
    1479254400,
    711.063,
    1,
    69.78458005
  ],
  [
    1479340800,
    744.876,
    1,
    73.43237675
  ],
  [
    1479427200,
    740.705,
    1,
    74.16298124
  ],
  [
    1479513600,
    751.833,
    1,
    79.185243
  ],
  [
    1479600000,
    751.879,
    1,
    77.71809014
  ],
  [
    1479686400,
    731.265,
    1,
    76.32218626
  ],
  [
    1479772800,
    739.643,
    1,
    77.03106058
  ],
  [
    1479859200,
    751.741,
    1,
    75.92139428
  ],
  [
    1479945600,
    745.466,
    1,
    75.90182712
  ],
  [
    1480032000,
    739.839,
    1,
    79.94491245
  ],
  [
    1480118400,
    741.639,
    1,
    78.17003618
  ],
  [
    1480204800,
    735.174,
    1,
    78.76706752
  ],
  [
    1480291200,
    733.268,
    1,
    81.66086266
  ],
  [
    1480377600,
    736.364,
    1,
    84.603365
  ],
  [
    1480464000,
    736.686,
    1,
    89.70170299
  ],
  [
    1480550400,
    746.633,
    1,
    86.75252455
  ],
  [
    1480636800,
    758.565,
    1,
    91.43159851
  ],
  [
    1480723200,
    777.72,
    1,
    98.48745796
  ],
  [
    1480809600,
    770.759,
    1,
    97.46856573
  ],
  [
    1480896000,
    772.204,
    1,
    103.13338489
  ],
  [
    1480982400,
    760.913,
    1,
    112.34653938
  ],
  [
    1481068800,
    763.894,
    1,
    100.9912797
  ],
  [
    1481155200,
    768.131,
    1,
    88.75458574
  ],
  [
    1481241600,
    770.859,
    1,
    94.95687972
  ],
  [
    1481328000,
    773.294,
    1,
    91.92540825
  ],
  [
    1481414400,
    769.559,
    1,
    93.84831519
  ],
  [
    1481500800,
    770.856,
    1,
    93.25397401
  ],
  [
    1481587200,
    780.792,
    1,
    91.62891786
  ],
  [
    1481673600,
    778.911,
    1,
    92.01797568
  ],
  [
    1481760000,
    779.301,
    1,
    95.61869869
  ],
  [
    1481846400,
    779.578,
    1,
    99.483554
  ],
  [
    1481932800,
    786.209,
    1,
    99.92501261
  ],
  [
    1482019200,
    793.019,
    1,
    101.5841886
  ],
  [
    1482105600,
    791.061,
    1,
    101.03892189
  ],
  [
    1482192000,
    792.349,
    1,
    103.50318929
  ],
  [
    1482278400,
    800.967,
    1,
    103.76806299
  ],
  [
    1482364800,
    841.001,
    1,
    110.25848439
  ],
  [
    1482451200,
    867.594,
    1,
    114.29862501
  ],
  [
    1482537600,
    922.18,
    1,
    128.93258506
  ],
  [
    1482624000,
    899.652,
    1,
    123.83628017
  ],
  [
    1482710400,
    896.905,
    1,
    125.75608867
  ],
  [
    1482796800,
    908.354,
    1,
    124.83614769
  ],
  [
    1482883200,
    934.831,
    1,
    130.76479763
  ],
  [
    1482969600,
    975.125,
    1,
    129.34184714
  ],
  [
    1483056000,
    972.535,
    1,
    117.3159322
  ],
  [
    1483142400,
    960.627,
    1,
    117.85171418
  ],
  [
    1483228800,
    963.658,
    1,
    120.72420139
  ],
  [
    1483315200,
    998.617,
    1,
    122.21672845
  ],
  [
    1483401600,
    1021.6,
    1,
    121.98820717
  ],
  [
    1483488000,
    1044.4,
    1,
    107.56708266
  ],
  [
    1483574400,
    1156.73,
    1,
    102.48520396
  ],
  [
    1483660800,
    1014.24,
    1,
    98.60584495
  ],
  [
    1483747200,
    904.608,
    1,
    88.33286137
  ],
  [
    1483833600,
    908.175,
    1,
    91.98963189
  ],
  [
    1483920000,
    913.244,
    1,
    88.5458318
  ],
  [
    1484006400,
    902.44,
    1,
    87.02830416
  ],
  [
    1484092800,
    908.115,
    1,
    86.14665845
  ],
  [
    1484179200,
    775.178,
    1,
    79.73941998
  ],
  [
    1484265600,
    803.428,
    1,
    81.75536927
  ],
  [
    1484352000,
    827.999,
    1,
    84.60667556
  ],
  [
    1484438400,
    821.078,
    1,
    83.53457046
  ],
  [
    1484524800,
    822.023,
    1,
    83.17099255
  ],
  [
    1484611200,
    830.957,
    1,
    86.20225694
  ],
  [
    1484697600,
    910.446,
    1,
    88.42372091
  ],
  [
    1484784000,
    891.251,
    1,
    87.03198086
  ],
  [
    1484870400,
    898.148,
    1,
    86.47013517
  ],
  [
    1484956800,
    895.535,
    1,
    84.52031523
  ],
  [
    1485043200,
    922.462,
    1,
    84.55119568
  ],
  [
    1485129600,
    924.99,
    1,
    86.3677532
  ],
  [
    1485216000,
    908.956,
    1,
    84.72823199
  ],
  [
    1485302400,
    891.935,
    1,
    83.99821067
  ],
  [
    1485388800,
    902.224,
    1,
    85.37966538
  ],
  [
    1485475200,
    918.526,
    1,
    86.78357158
  ],
  [
    1485561600,
    920.383,
    1,
    87.33695188
  ],
  [
    1485648000,
    922.159,
    1,
    87.31160703
  ],
  [
    1485734400,
    920.536,
    1,
    87.72189293
  ],
  [
    1485820800,
    921.32,
    1,
    87.21152572
  ],
  [
    1485907200,
    971.091,
    1,
    90.40721328
  ],
  [
    1485993600,
    993.837,
    1,
    92.57482185
  ],
  [
    1486080000,
    1008.79,
    1,
    93.29849711
  ],
  [
    1486166400,
    1031.84,
    1,
    92.7188261
  ],
  [
    1486252800,
    1039.78,
    1,
    90.87238468
  ],
  [
    1486339200,
    1030.06,
    1,
    90.72301148
  ],
  [
    1486425600,
    1040.29,
    1,
    91.05143847
  ],
  [
    1486512000,
    1064.26,
    1,
    92.22197189
  ],
  [
    1486598400,
    1068.93,
    1,
    93.47143644
  ],
  [
    1486684800,
    987.04,
    1,
    89.36532368
  ],
  [
    1486771200,
    985.562,
    1,
    87.28894321
  ],
  [
    1486857600,
    1002.11,
    1,
    88.26596672
  ],
  [
    1486944000,
    999.179,
    1,
    87.77355142
  ],
  [
    1487030400,
    991.649,
    1,
    87.86229444
  ],
  [
    1487116800,
    1007.56,
    1,
    77.73183151
  ],
  [
    1487203200,
    1007.77,
    1,
    78.07268304
  ],
  [
    1487289600,
    1028.08,
    1,
    79.72764426
  ],
  [
    1487376000,
    1049.8,
    1,
    82.5996302
  ],
  [
    1487462400,
    1053.31,
    1,
    82.23138238
  ],
  [
    1487548800,
    1047.04,
    1,
    82.02428515
  ],
  [
    1487635200,
    1077.15,
    1,
    88.21795071
  ],
  [
    1487721600,
    1112.76,
    1,
    87.60786043
  ],
  [
    1487808000,
    1119.51,
    1,
    88.84647435
  ],
  [
    1487894400,
    1174.89,
    1,
    89.59529638
  ],
  [
    1487980800,
    1171.39,
    1,
    89.7651251
  ],
  [
    1488067200,
    1143.15,
    1,
    84.60570625
  ],
  [
    1488153600,
    1171.08,
    1,
    80.83382226
  ],
  [
    1488240000,
    1187.52,
    1,
    76.69336089
  ],
  [
    1488326400,
    1179.76,
    1,
    74.46710472
  ],
  [
    1488412800,
    1226.16,
    1,
    71.32818317
  ],
  [
    1488499200,
    1255.29,
    1,
    66.09920489
  ],
  [
    1488585600,
    1277.79,
    1,
    65.2090042
  ],
  [
    1488672000,
    1253.12,
    1,
    68.13841703
  ],
  [
    1488758400,
    1269.12,
    1,
    65.58217408
  ],
  [
    1488844800,
    1272.77,
    1,
    64.84626162
  ],
  [
    1488931200,
    1225.7,
    1,
    65.12683181
  ],
  [
    1489017600,
    1141.23,
    1,
    69.23972989
  ],
  [
    1489104000,
    1195.44,
    1,
    67.23396118
  ],
  [
    1489190400,
    1127.69,
    1,
    58.49773051
  ],
  [
    1489276800,
    1175.36,
    1,
    54.83777655
  ],
  [
    1489363200,
    1219.16,
    1,
    51.0662182
  ],
  [
    1489449600,
    1230.71,
    1,
    42.95087231
  ],
  [
    1489536000,
    1240.53,
    1,
    43.34062356
  ],
  [
    1489622400,
    1255.26,
    1,
    34.30628208
  ],
  [
    1489708800,
    1163.37,
    1,
    25.67635569
  ],
  [
    1489795200,
    1110.13,
    1,
    23.91310435
  ],
  [
    1489881600,
    991.744,
    1,
    26.92132709
  ],
  [
    1489968000,
    1038.52,
    1,
    23.20626926
  ],
  [
    1490054400,
    1063.95,
    1,
    24.22345824
  ],
  [
    1490140800,
    1110.42,
    1,
    25.75143724
  ],
  [
    1490227200,
    1045.95,
    1,
    24.27885341
  ],
  [
    1490313600,
    1038.45,
    1,
    23.79692012
  ],
  [
    1490400000,
    936.54,
    1,
    17.48062095
  ],
  [
    1490486400,
    974.015,
    1,
    19.18489105
  ],
  [
    1490572800,
    972.055,
    1,
    18.94397228
  ],
  [
    1490659200,
    1044.58,
    1,
    21.01330909
  ],
  [
    1490745600,
    1046.08,
    1,
    20.68606842
  ],
  [
    1490832000,
    1042.21,
    1,
    19.61825523
  ],
  [
    1490918400,
    1026.64,
    1,
    19.83703581
  ],
  [
    1491004800,
    1071.71,
    1,
    21.41976308
  ],
  [
    1491091200,
    1080.61,
    1,
    21.29767592
  ],
  [
    1491177600,
    1102.95,
    1,
    22.59162003
  ],
  [
    1491264000,
    1145.52,
    1,
    26.08089832
  ],
  [
    1491350400,
    1134.14,
    1,
    25.39435885
  ],
  [
    1491436800,
    1125.81,
    1,
    24.89600955
  ],
  [
    1491523200,
    1178.94,
    1,
    27.50349352
  ],
  [
    1491609600,
    1183.59,
    1,
    28.31663413
  ],
  [
    1491696000,
    1184.03,
    1,
    26.73937001
  ],
  [
    1491782400,
    1209.23,
    1,
    27.94124443
  ],
  [
    1491868800,
    1209.14,
    1,
    27.79542727
  ],
  [
    1491955200,
    1223.71,
    1,
    28.15534204
  ],
  [
    1492041600,
    1215.54,
    1,
    26.24755456
  ],
  [
    1492128000,
    1178.08,
    1,
    23.57508495
  ],
  [
    1492214400,
    1179.16,
    1,
    24.74435353
  ],
  [
    1492300800,
    1183.09,
    1,
    24.12608233
  ],
  [
    1492387200,
    1176.43,
    1,
    24.17668009
  ],
  [
    1492473600,
    1198.11,
    1,
    24.79583601
  ],
  [
    1492560000,
    1204.77,
    1,
    23.74913757
  ],
  [
    1492646400,
    1218.48,
    1,
    25.31522491
  ],
  [
    1492732800,
    1242.32,
    1,
    25.02492784
  ],
  [
    1492819200,
    1255.94,
    1,
    26.06636448
  ],
  [
    1492905600,
    1239.02,
    1,
    25.49817359
  ],
  [
    1492992000,
    1244.78,
    1,
    25.47297035
  ],
  [
    1493078400,
    1251.38,
    1,
    24.99031445
  ],
  [
    1493164800,
    1271.83,
    1,
    25.48277479
  ],
  [
    1493251200,
    1286.05,
    1,
    24.35437841
  ],
  [
    1493337600,
    1323.55,
    1,
    21.26974026
  ],
  [
    1493424000,
    1349.58,
    1,
    18.90443426
  ],
  [
    1493510400,
    1326.27,
    1,
    19.350137
  ],
  [
    1493596800,
    1357.93,
    1,
    17.11929664
  ],
  [
    1493683200,
    1404.78,
    1,
    18.32638865
  ],
  [
    1493769600,
    1459.78,
    1,
    18.89585005
  ],
  [
    1493856000,
    1485.82,
    1,
    18.63788942
  ],
  [
    1493942400,
    1530.05,
    1,
    15.81697157
  ],
  [
    1494028800,
    1530.07,
    1,
    16.12091609
  ],
  [
    1494115200,
    1546.25,
    1,
    15.79911596
  ],
  [
    1494201600,
    1569.31,
    1,
    16.73984234
  ],
  [
    1494288000,
    1665.32,
    1,
    18.25448327
  ],
  [
    1494374400,
    1705.61,
    1,
    18.71189706
  ],
  [
    1494460800,
    1750.81,
    1,
    19.5031341
  ],
  [
    1494547200,
    1809.09,
    1,
    20.22943443
  ],
  [
    1494633600,
    1653.92,
    1,
    18.64740051
  ],
  [
    1494720000,
    1783.11,
    1,
    19.62230224
  ],
  [
    1494806400,
    1770.79,
    1,
    19.50550812
  ],
  [
    1494892800,
    1712.1,
    1,
    18.53168766
  ],
  [
    1494979200,
    1734.88,
    1,
    19.43836603
  ],
  [
    1495065600,
    1809.63,
    1,
    20.14271976
  ],
  [
    1495152000,
    1904.82,
    1,
    19.62663622
  ],
  [
    1495238400,
    1977.79,
    1,
    15.30536596
  ],
  [
    1495324800,
    2049.84,
    1,
    16.07251229
  ],
  [
    1495411200,
    2056.37,
    1,
    13.00076499
  ],
  [
    1495497600,
    2146.16,
    1,
    12.5640155
  ],
  [
    1495584000,
    2300.15,
    1,
    12.59328001
  ],
  [
    1495670400,
    2485.46,
    1,
    13.09177294
  ],
  [
    1495756800,
    2407.19,
    1,
    13.58758417
  ],
  [
    1495843200,
    2264.84,
    1,
    14.19855559
  ],
  [
    1495929600,
    2105.06,
    1,
    13.02474941
  ],
  [
    1496016000,
    2201.46,
    1,
    12.95083124
  ],
  [
    1496102400,
    2248.66,
    1,
    11.62032133
  ],
  [
    1496188800,
    2227.12,
    1,
    9.55665025
  ],
  [
    1496275200,
    2321.31,
    1,
    10.11045537
  ],
  [
    1496361600,
    2402.31,
    1,
    10.63919964
  ],
  [
    1496448000,
    2496.18,
    1,
    11.14709442
  ],
  [
    1496534400,
    2515.54,
    1,
    11.1793828
  ],
  [
    1496620800,
    2549.59,
    1,
    10.30570422
  ],
  [
    1496707200,
    2756.25,
    1,
    11.11718369
  ],
  [
    1496793600,
    2834.16,
    1,
    10.70835915
  ],
  [
    1496880000,
    2678.14,
    1,
    10.49880434
  ],
  [
    1496966400,
    2799.56,
    1,
    10.67316307
  ],
  [
    1497052800,
    2816.27,
    1,
    9.82456324
  ],
  [
    1497139200,
    2888.21,
    1,
    8.91098304
  ],
  [
    1497225600,
    2978.85,
    1,
    8.7105207
  ],
  [
    1497312000,
    2687.9,
    1,
    6.68178078
  ],
  [
    1497398400,
    2733.28,
    1,
    6.92392067
  ],
  [
    1497484800,
    2463.83,
    1,
    7.0999245
  ],
  [
    1497571200,
    2384.16,
    1,
    6.77179668
  ],
  [
    1497657600,
    2485.25,
    1,
    6.74633875
  ],
  [
    1497744000,
    2636.92,
    1,
    6.95801317
  ],
  [
    1497830400,
    2547.87,
    1,
    6.8090275
  ],
  [
    1497916800,
    2647.11,
    1,
    7.11051598
  ],
  [
    1498003200,
    2750.01,
    1,
    7.61052518
  ],
  [
    1498089600,
    2685.4,
    1,
    7.90453567
  ],
  [
    1498176000,
    2738.25,
    1,
    8.16398633
  ],
  [
    1498262400,
    2725.37,
    1,
    7.97766557
  ],
  [
    1498348800,
    2565.81,
    1,
    7.93224017
  ],
  [
    1498435200,
    2549.47,
    1,
    8.41534221
  ],
  [
    1498521600,
    2438.48,
    1,
    9.07513612
  ],
  [
    1498608000,
    2535.19,
    1,
    8.65128548
  ],
  [
    1498694400,
    2570.84,
    1,
    7.81348586
  ],
  [
    1498780800,
    2566,
    1,
    8.40583888
  ],
  [
    1498867200,
    2488.55,
    1,
    8.54276446
  ],
  [
    1498953600,
    2380.4,
    1,
    8.75111668
  ],
  [
    1499040000,
    2521.24,
    1,
    8.86057087
  ],
  [
    1499126400,
    2591.97,
    1,
    9.13614494
  ],
  [
    1499212800,
    2588.2,
    1,
    9.54685454
  ],
  [
    1499299200,
    2628.61,
    1,
    9.8135192
  ],
  [
    1499385600,
    2615.09,
    1,
    9.69233905
  ],
  [
    1499472000,
    2501.95,
    1,
    10.24499206
  ],
  [
    1499558400,
    2567.4,
    1,
    10.23206877
  ],
  [
    1499644800,
    2501.88,
    1,
    10.34754017
  ],
  [
    1499731200,
    2363.2,
    1,
    10.99725441
  ],
  [
    1499817600,
    2305.35,
    1,
    11.9760308
  ],
  [
    1499904000,
    2405.08,
    1,
    10.44469922
  ],
  [
    1499990400,
    2352.76,
    1,
    11.39544233
  ],
  [
    1500076800,
    2223.11,
    1,
    11.12845901
  ],
  [
    1500163200,
    1961.62,
    1,
    11.58439288
  ],
  [
    1500249600,
    1940.93,
    1,
    12.17357217
  ],
  [
    1500336000,
    2205.42,
    1,
    11.57870973
  ],
  [
    1500422400,
    2276.94,
    1,
    9.94970395
  ],
  [
    1500508800,
    2297,
    1,
    11.03843991
  ],
  [
    1500595200,
    2755.55,
    1,
    12.30337505
  ],
  [
    1500681600,
    2681.81,
    1,
    12.29562012
  ],
  [
    1500768000,
    2853.64,
    1,
    12.38559028
  ],
  [
    1500854400,
    2765.25,
    1,
    12.2151348
  ],
  [
    1500940800,
    2771.84,
    1,
    12.36153788
  ],
  [
    1501027200,
    2610.54,
    1,
    12.44091786
  ],
  [
    1501113600,
    2569.64,
    1,
    12.50141086
  ],
  [
    1501200000,
    2737.95,
    1,
    13.39827062
  ],
  [
    1501286400,
    2800.04,
    1,
    14.43111304
  ],
  [
    1501372800,
    2709.8,
    1,
    13.22524598
  ],
  [
    1501459200,
    2740.4,
    1,
    13.87846468
  ],
  [
    1501545600,
    2855.86,
    1,
    13.63185504
  ],
  [
    1501632000,
    2713.39,
    1,
    11.90830214
  ],
  [
    1501718400,
    2730.97,
    1,
    12.36930784
  ],
  [
    1501804800,
    2814.05,
    1,
    12.46423351
  ],
  [
    1501891200,
    2884.26,
    1,
    12.96593826
  ],
  [
    1501977600,
    3271.93,
    1,
    12.75621157
  ],
  [
    1502064000,
    3214.96,
    1,
    12.24233654
  ],
  [
    1502150400,
    3373.38,
    1,
    12.64404264
  ],
  [
    1502236800,
    3390.45,
    1,
    11.26286836
  ],
  [
    1502323200,
    3337.48,
    1,
    11.18589647
  ],
  [
    1502409600,
    3426.07,
    1,
    11.52631384
  ],
  [
    1502496000,
    3642.35,
    1,
    11.55358961
  ],
  [
    1502582400,
    3931.29,
    1,
    12.67622392
  ],
  [
    1502668800,
    4084.03,
    1,
    13.70516658
  ],
  [
    1502755200,
    4352.01,
    1,
    14.52664993
  ],
  [
    1502841600,
    4146.56,
    1,
    14.15807372
  ],
  [
    1502928000,
    4368.94,
    1,
    14.31369337
  ],
  [
    1503014400,
    4265.4,
    1,
    14.18801599
  ],
  [
    1503100800,
    4168.58,
    1,
    13.96836779
  ],
  [
    1503187200,
    4104.05,
    1,
    13.9842781
  ],
  [
    1503273600,
    4091.57,
    1,
    13.6057315
  ],
  [
    1503360000,
    3888.09,
    1,
    11.87797859
  ],
  [
    1503446400,
    4073.93,
    1,
    12.92962217
  ],
  [
    1503532800,
    4143.6,
    1,
    13.04347826
  ],
  [
    1503619200,
    4377.48,
    1,
    13.4337867
  ],
  [
    1503705600,
    4367.44,
    1,
    13.155336
  ],
  [
    1503792000,
    4353.13,
    1,
    12.90887255
  ],
  [
    1503878400,
    4312.68,
    1,
    12.26611451
  ],
  [
    1503964800,
    4377.17,
    1,
    12.5772796
  ],
  [
    1504051200,
    4603.75,
    1,
    12.4566065
  ],
  [
    1504137600,
    4602.05,
    1,
    12.17241689
  ],
  [
    1504224000,
    4762.56,
    1,
    12.24900466
  ],
  [
    1504310400,
    4921.65,
    1,
    12.79214534
  ],
  [
    1504396800,
    4627.28,
    1,
    13.17979202
  ],
  [
    1504483200,
    4556.28,
    1,
    13.37874859
  ],
  [
    1504569600,
    4189.19,
    1,
    15.14349336
  ],
  [
    1504656000,
    4467.07,
    1,
    13.63195816
  ],
  [
    1504742400,
    4562.9,
    1,
    14.1763083
  ],
  [
    1504828800,
    4570.61,
    1,
    13.96360781
  ],
  [
    1504915200,
    4159.17,
    1,
    14.08198298
  ],
  [
    1505001600,
    4199.76,
    1,
    14.30996473
  ],
  [
    1505088000,
    4165.2,
    1,
    14.35731274
  ],
  [
    1505174400,
    4216,
    1,
    14.23449095
  ],
  [
    1505260800,
    4107.59,
    1,
    14.22305556
  ],
  [
    1505347200,
    3897.77,
    1,
    13.98127589
  ],
  [
    1505433600,
    3358.2,
    1,
    14.49280366
  ],
  [
    1505520000,
    3690.25,
    1,
    14.50684016
  ],
  [
    1505606400,
    3552.17,
    1,
    14.61684066
  ],
  [
    1505692800,
    3658.98,
    1,
    13.91538154
  ],
  [
    1505779200,
    3980.6,
    1,
    13.92319612
  ],
  [
    1505865600,
    3926.58,
    1,
    13.86210549
  ],
  [
    1505952000,
    3874.66,
    1,
    13.77407119
  ],
  [
    1506038400,
    3679.07,
    1,
    13.96194408
  ],
  [
    1506124800,
    3598.12,
    1,
    13.74492891
  ],
  [
    1506211200,
    3796.15,
    1,
    13.26332744
  ],
  [
    1506297600,
    3681.58,
    1,
    13.04516367
  ],
  [
    1506384000,
    3928.41,
    1,
    13.43753634
  ],
  [
    1506470400,
    3892.94,
    1,
    13.56330569
  ],
  [
    1506556800,
    4197.13,
    1,
    13.6950315
  ],
  [
    1506643200,
    4171.62,
    1,
    13.94630917
  ],
  [
    1506729600,
    4166.11,
    1,
    14.29310031
  ],
  [
    1506816000,
    4341.05,
    1,
    14.39593165
  ],
  [
    1506902400,
    4395.81,
    1,
    14.53246805
  ],
  [
    1506988800,
    4408.46,
    1,
    14.81919975
  ],
  [
    1507075200,
    4319.37,
    1,
    14.75426467
  ],
  [
    1507161600,
    4229.88,
    1,
    14.44764374
  ],
  [
    1507248000,
    4324.46,
    1,
    14.6514882
  ],
  [
    1507334400,
    4369.35,
    1,
    14.14523523
  ],
  [
    1507420800,
    4429.67,
    1,
    14.2406103
  ],
  [
    1507507200,
    4614.52,
    1,
    14.94983267
  ],
  [
    1507593600,
    4776.21,
    1,
    16.04903881
  ],
  [
    1507680000,
    4789.25,
    1,
    15.9015675
  ],
  [
    1507766400,
    4829.58,
    1,
    15.91305379
  ],
  [
    1507852800,
    5464.16,
    1,
    17.96954081
  ],
  [
    1507939200,
    5643.53,
    1,
    16.65485227
  ],
  [
    1508025600,
    5835.96,
    1,
    17.18839922
  ],
  [
    1508112000,
    5687.57,
    1,
    16.89154794
  ],
  [
    1508198400,
    5741.58,
    1,
    17.21695794
  ],
  [
    1508284800,
    5603.82,
    1,
    17.68078904
  ],
  [
    1508371200,
    5583.74,
    1,
    17.75219528
  ],
  [
    1508457600,
    5708.11,
    1,
    18.5300572
  ],
  [
    1508544000,
    5996.79,
    1,
    19.75409458
  ],
  [
    1508630400,
    6036.66,
    1,
    20.08544364
  ],
  [
    1508716800,
    6006,
    1,
    20.35269878
  ],
  [
    1508803200,
    5935.52,
    1,
    20.67261076
  ],
  [
    1508889600,
    5524.6,
    1,
    18.51177971
  ],
  [
    1508976000,
    5747.95,
    1,
    19.28699895
  ],
  [
    1509062400,
    5899.74,
    1,
    19.8974058
  ],
  [
    1509148800,
    5787.82,
    1,
    19.42775624
  ],
  [
    1509235200,
    5754.44,
    1,
    19.41568454
  ],
  [
    1509321600,
    6114.85,
    1,
    20.06302866
  ],
  [
    1509408000,
    6132.02,
    1,
    19.94944336
  ],
  [
    1509494400,
    6440.97,
    1,
    21.0653057
  ],
  [
    1509580800,
    6777.77,
    1,
    23.31333735
  ],
  [
    1509667200,
    7087.53,
    1,
    24.56708389
  ],
  [
    1509753600,
    7164.48,
    1,
    23.45311165
  ],
  [
    1509840000,
    7404.52,
    1,
    24.67868963
  ],
  [
    1509926400,
    7403.22,
    1,
    24.97485047
  ],
  [
    1510012800,
    7023.1,
    1,
    23.52261461
  ],
  [
    1510099200,
    7141.38,
    1,
    24.26803864
  ],
  [
    1510185600,
    7446.83,
    1,
    24.12749275
  ],
  [
    1510272000,
    7173.73,
    1,
    22.37099707
  ],
  [
    1510358400,
    6618.61,
    1,
    22.16651149
  ],
  [
    1510444800,
    6295.45,
    1,
    20.00524326
  ],
  [
    1510531200,
    5938.25,
    1,
    19.34125886
  ],
  [
    1510617600,
    6561.48,
    1,
    20.71416169
  ],
  [
    1510704000,
    6677.16,
    1,
    19.75701554
  ],
  [
    1510790400,
    7297.5,
    1,
    21.88529974
  ],
  [
    1510876800,
    7906.5,
    1,
    23.94697229
  ],
  [
    1510963200,
    7666.41,
    1,
    23.09298753
  ],
  [
    1511049600,
    7770.49,
    1,
    22.36749462
  ],
  [
    1511136000,
    8040.63,
    1,
    22.70761436
  ],
  [
    1511222400,
    8207.02,
    1,
    22.33549149
  ],
  [
    1511308800,
    8097.15,
    1,
    22.47260707
  ],
  [
    1511395200,
    8245.31,
    1,
    21.61632659
  ],
  [
    1511481600,
    8024.29,
    1,
    19.45277708
  ],
  [
    1511568000,
    8249.41,
    1,
    17.34249783
  ],
  [
    1511654400,
    8789.4,
    1,
    18.8624258
  ],
  [
    1511740800,
    9382.15,
    1,
    19.86771305
  ],
  [
    1511827200,
    9789.36,
    1,
    20.4089138
  ],
  [
    1511913600,
    10110.3,
    1,
    21.17140793
  ],
  [
    1512000000,
    10167.1,
    1,
    23.12722922
  ],
  [
    1512086400,
    10219.3,
    1,
    22.85358392
  ],
  [
    1512172800,
    11054.3,
    1,
    23.62438798
  ],
  [
    1512259200,
    11120.8,
    1,
    23.89966302
  ],
  [
    1512345600,
    11396.5,
    1,
    24.27385053
  ],
  [
    1512432000,
    11696,
    1,
    24.84440385
  ],
  [
    1512518400,
    11964.2,
    1,
    25.86708639
  ],
  [
    1512604800,
    14617.1,
    1,
    34.46835804
  ],
  [
    1512691200,
    18245.7,
    1,
    42.140211
  ],
  [
    1512777600,
    16575.9,
    1,
    36.16665212
  ],
  [
    1512864000,
    14709.4,
    1,
    31.32472135
  ],
  [
    1512950400,
    15616.1,
    1,
    35.22733169
  ],
  [
    1513036800,
    16899.6,
    1,
    33.44594394
  ],
  [
    1513123200,
    17566.9,
    1,
    26.60833107
  ],
  [
    1513209600,
    16474.7,
    1,
    23.47318238
  ],
  [
    1513296000,
    17103.7,
    1,
    24.80842898
  ],
  [
    1513382400,
    17797.7,
    1,
    25.87465454
  ],
  [
    1513468800,
    19380,
    1,
    27.50422569
  ],
  [
    1513555200,
    18776.3,
    1,
    26.03202376
  ],
  [
    1513641600,
    18891.7,
    1,
    24.05537969
  ],
  [
    1513728000,
    16733.2,
    1,
    21.03788862
  ],
  [
    1513814400,
    16829.3,
    1,
    20.65152567
  ],
  [
    1513900800,
    15603.2,
    1,
    18.9517231
  ],
  [
    1513987200,
    13983.6,
    1,
    20.29064157
  ],
  [
    1514073600,
    14608.2,
    1,
    20.23943949
  ],
  [
    1514160000,
    13995.9,
    1,
    20.0264713
  ],
  [
    1514246400,
    14036.6,
    1,
    18.3876757
  ],
  [
    1514332800,
    16163.5,
    1,
    20.8569364
  ],
  [
    1514419200,
    15864.1,
    1,
    20.81334754
  ],
  [
    1514505600,
    14695.8,
    1,
    19.84875518
  ],
  [
    1514592000,
    14681.9,
    1,
    19.4767947
  ],
  [
    1514678400,
    12897.7,
    1,
    18.10935508
  ],
  [
    1514764800,
    14112.2,
    1,
    18.67293323
  ],
  [
    1514851200,
    13625,
    1,
    17.64105725
  ],
  [
    1514937600,
    14978.2,
    1,
    16.90541761
  ],
  [
    1515024000,
    15270.7,
    1,
    15.87864571
  ],
  [
    1515110400,
    15477.2,
    1,
    15.86184986
  ],
  [
    1515196800,
    17462.1,
    1,
    17.54713341
  ],
  [
    1515283200,
    17527.3,
    1,
    16.80453687
  ],
  [
    1515369600,
    16476.2,
    1,
    14.22495813
  ],
  [
    1515456000,
    15123.7,
    1,
    13.1969459
  ],
  [
    1515542400,
    14588.5,
    1,
    11.21898888
  ],
  [
    1515628800,
    14968.2,
    1,
    11.80373633
  ],
  [
    1515715200,
    13453.9,
    1,
    11.61531223
  ],
  [
    1515801600,
    13952.4,
    1,
    10.9820775
  ],
  [
    1515888000,
    14370.8,
    1,
    10.2833672
  ],
  [
    1515974400,
    13767.3,
    1,
    10.08438262
  ],
  [
    1516060800,
    13810.1,
    1,
    10.68372233
  ],
  [
    1516147200,
    11609.5,
    1,
    10.93853054
  ],
  [
    1516233600,
    11214.1,
    1,
    11.03272205
  ],
  [
    1516320000,
    11432.3,
    1,
    11.1120507
  ],
  [
    1516406400,
    11737.9,
    1,
    11.23297765
  ],
  [
    1516492800,
    12890.5,
    1,
    11.15403918
  ],
  [
    1516579200,
    11755.2,
    1,
    11.13867437
  ],
  [
    1516665600,
    10944.3,
    1,
    10.89885179
  ],
  [
    1516752000,
    10921.6,
    1,
    11.0601057
  ],
  [
    1516838400,
    11431.1,
    1,
    10.7513967
  ],
  [
    1516924800,
    11251.6,
    1,
    10.68832526
  ],
  [
    1517011200,
    11194.3,
    1,
    10.6031731
  ],
  [
    1517097600,
    11518,
    1,
    10.3599633
  ],
  [
    1517184000,
    11762.6,
    1,
    9.43498837
  ],
  [
    1517270400,
    11282.4,
    1,
    9.5280079
  ],
  [
    1517356800,
    10101.9,
    1,
    9.43142033
  ],
  [
    1517443200,
    10264.3,
    1,
    9.16971153
  ],
  [
    1517529600,
    9039.18,
    1,
    8.72701468
  ],
  [
    1517616000,
    8814.24,
    1,
    9.58891919
  ],
  [
    1517702400,
    9172.35,
    1,
    9.50830701
  ],
  [
    1517788800,
    8335.52,
    1,
    9.98316083
  ],
  [
    1517875200,
    7092.33,
    1,
    10.0211378
  ],
  [
    1517961600,
    7602.06,
    1,
    9.95940024
  ],
  [
    1518048000,
    7771.62,
    1,
    9.99488142
  ],
  [
    1518134400,
    8034.77,
    1,
    9.98919619
  ],
  [
    1518220800,
    8915.99,
    1,
    9.94054176
  ],
  [
    1518307200,
    8440.63,
    1,
    10.01099467
  ],
  [
    1518393600,
    8267.29,
    1,
    9.98249171
  ],
  [
    1518480000,
    8915.48,
    1,
    10.29984034
  ],
  [
    1518566400,
    8659.56,
    1,
    10.24415693
  ],
  [
    1518652800,
    9440.22,
    1,
    10.25738482
  ],
  [
    1518739200,
    10197.7,
    1,
    10.89908908
  ],
  [
    1518825600,
    10208.3,
    1,
    10.84650856
  ],
  [
    1518912000,
    11136,
    1,
    11.4611647
  ],
  [
    1518998400,
    10572.9,
    1,
    11.40355797
  ],
  [
    1519084800,
    11441.4,
    1,
    12.04210854
  ],
  [
    1519171200,
    11239.3,
    1,
    12.7692909
  ],
  [
    1519257600,
    10654.5,
    1,
    12.58460012
  ],
  [
    1519344000,
    9771.66,
    1,
    12.2199365
  ],
  [
    1519430400,
    10424.4,
    1,
    11.96476819
  ],
  [
    1519516800,
    9846.6,
    1,
    11.65169174
  ],
  [
    1519603200,
    9752.67,
    1,
    11.39868583
  ],
  [
    1519689600,
    10286.5,
    1,
    11.75941359
  ],
  [
    1519776000,
    10733.1,
    1,
    12.13408988
  ],
  [
    1519862400,
    10433.4,
    1,
    12.14694528
  ],
  [
    1519948800,
    11189,
    1,
    12.7946667
  ],
  [
    1520035200,
    11002.4,
    1,
    12.67313777
  ],
  [
    1520121600,
    11475.1,
    1,
    13.40829449
  ],
  [
    1520208000,
    11492.9,
    1,
    13.27822355
  ],
  [
    1520294400,
    11365.4,
    1,
    13.47396824
  ],
  [
    1520380800,
    10902.3,
    1,
    13.2887138
  ],
  [
    1520467200,
    10054.4,
    1,
    13.20071003
  ],
  [
    1520553600,
    9429.75,
    1,
    13.35159374
  ],
  [
    1520640000,
    9388.94,
    1,
    12.78036257
  ],
  [
    1520726400,
    8613.84,
    1,
    12.82800709
  ],
  [
    1520812800,
    9654.64,
    1,
    13.2901645
  ],
  [
    1520899200,
    9301.91,
    1,
    13.24320784
  ],
  [
    1520985600,
    9339.39,
    1,
    13.446155
  ],
  [
    1521072000,
    8164.08,
    1,
    13.47190136
  ],
  [
    1521158400,
    8120.61,
    1,
    13.2756301
  ],
  [
    1521244800,
    8314.1,
    1,
    13.80987974
  ],
  [
    1521331200,
    7752.84,
    1,
    14.5382469
  ],
  [
    1521417600,
    8316.97,
    1,
    14.96581979
  ],
  [
    1521504000,
    8559.36,
    1,
    15.50044277
  ],
  [
    1521590400,
    8956.79,
    1,
    16.08038406
  ],
  [
    1521676800,
    9028.42,
    1,
    15.94391985
  ],
  [
    1521763200,
    8716.3,
    1,
    16.18556682
  ],
  [
    1521849600,
    8901.95,
    1,
    16.40694691
  ],
  [
    1521936000,
    8612.81,
    1,
    16.47744513
  ],
  [
    1522022400,
    8498.47,
    1,
    16.20957605
  ],
  [
    1522108800,
    8215.02,
    1,
    16.82199242
  ],
  [
    1522195200,
    7849.03,
    1,
    17.56552078
  ],
  [
    1522281600,
    7982.24,
    1,
    17.72826509
  ],
  [
    1522368000,
    6822.97,
    1,
    18.40325504
  ],
  [
    1522454400,
    7057.41,
    1,
    17.36293989
  ],
  [
    1522540800,
    7040.34,
    1,
    17.65943518
  ],
  [
    1522627200,
    7002.35,
    1,
    18.15491314
  ],
  [
    1522713600,
    7076.55,
    1,
    18.39593948
  ],
  [
    1522800000,
    7427.88,
    1,
    17.93727647
  ],
  [
    1522886400,
    6691.08,
    1,
    17.99751465
  ],
  [
    1522972800,
    6804.88,
    1,
    17.84207973
  ],
  [
    1523059200,
    6785.24,
    1,
    17.95488284
  ],
  [
    1523145600,
    6939.34,
    1,
    17.94924588
  ],
  [
    1523232000,
    7112.78,
    1,
    17.29976237
  ],
  [
    1523318400,
    6747.16,
    1,
    16.95240775
  ],
  [
    1523404800,
    6848.89,
    1,
    16.46541942
  ],
  [
    1523491200,
    6921.82,
    1,
    16.06754953
  ],
  [
    1523577600,
    7811.96,
    1,
    16.18525709
  ],
  [
    1523664000,
    8001.8,
    1,
    15.84819262
  ],
  [
    1523750400,
    8065.69,
    1,
    15.68248456
  ],
  [
    1523836800,
    8353.88,
    1,
    15.84695349
  ],
  [
    1523923200,
    8058.03,
    1,
    15.84149521
  ],
  [
    1524009600,
    7922.73,
    1,
    15.63069426
  ],
  [
    1524096000,
    8160.41,
    1,
    15.42558098
  ],
  [
    1524182400,
    8334.23,
    1,
    14.29304215
  ],
  [
    1524268800,
    8796.75,
    1,
    14.53357257
  ],
  [
    1524355200,
    8827.78,
    1,
    14.75205962
  ],
  [
    1524441600,
    8818.68,
    1,
    14.02365932
  ],
  [
    1524528000,
    9156.62,
    1,
    13.72199702
  ],
  [
    1524614400,
    9602.05,
    1,
    13.7699927
  ],
  [
    1524700800,
    8852.71,
    1,
    14.36860002
  ],
  [
    1524787200,
    9263.27,
    1,
    14.02752119
  ],
  [
    1524873600,
    9058.56,
    1,
    13.65229933
  ],
  [
    1524960000,
    9466.71,
    1,
    13.63130559
  ],
  [
    1525046400,
    9434.29,
    1,
    13.66163796
  ],
  [
    1525132800,
    8993.13,
    1,
    13.90179996
  ],
  [
    1525219200,
    9015.14,
    1,
    13.40288154
  ],
  [
    1525305600,
    9231.81,
    1,
    13.32722682
  ],
  [
    1525392000,
    9635.54,
    1,
    12.41861334
  ],
  [
    1525478400,
    9731.62,
    1,
    12.30168883
  ],
  [
    1525564800,
    9911.53,
    1,
    11.89869543
  ],
  [
    1525651200,
    9381.71,
    1,
    12.28528889
  ],
  [
    1525737600,
    9440.66,
    1,
    12.28048541
  ],
  [
    1525824000,
    9187.01,
    1,
    12.33218607
  ],
  [
    1525910400,
    9353.61,
    1,
    12.3472991
  ],
  [
    1525996800,
    8979.57,
    1,
    12.29631709
  ],
  [
    1526083200,
    8483.35,
    1,
    12.38806951
  ],
  [
    1526169600,
    8490.01,
    1,
    12.45632954
  ],
  [
    1526256000,
    8710.5,
    1,
    11.85792543
  ],
  [
    1526342400,
    8699.55,
    1,
    11.92948078
  ],
  [
    1526428800,
    8373.35,
    1,
    12.07594272
  ],
  [
    1526515200,
    8361.17,
    1,
    11.74592181
  ],
  [
    1526601600,
    8018,
    1,
    11.95526245
  ],
  [
    1526688000,
    8209.01,
    1,
    11.92613621
  ],
  [
    1526774400,
    8226.32,
    1,
    11.80468639
  ],
  [
    1526860800,
    8482.32,
    1,
    11.92734186
  ],
  [
    1526947200,
    8324.82,
    1,
    12.07808487
  ],
  [
    1527033600,
    7911.83,
    1,
    12.47133911
  ],
  [
    1527120000,
    7649.95,
    1,
    12.83865316
  ],
  [
    1527206400,
    7562.7,
    1,
    12.57670548
  ],
  [
    1527292800,
    7461.64,
    1,
    12.63376402
  ],
  [
    1527379200,
    7321.67,
    1,
    12.50763612
  ],
  [
    1527465600,
    7362.49,
    1,
    12.87697899
  ],
  [
    1527552000,
    7123.11,
    1,
    13.67042183
  ],
  [
    1527638400,
    7553.12,
    1,
    12.98777762
  ],
  [
    1527724800,
    7380.14,
    1,
    13.19677847
  ],
  [
    1527811200,
    7505.93,
    1,
    12.98145637
  ],
  [
    1527897600,
    7499.95,
    1,
    12.94559018
  ],
  [
    1527984000,
    7668.27,
    1,
    12.91106696
  ],
  [
    1528070400,
    7722.53,
    1,
    12.40469425
  ],
  [
    1528156800,
    7509.51,
    1,
    12.62009196
  ],
  [
    1528243200,
    7636.5,
    1,
    12.51838045
  ],
  [
    1528329600,
    7662.08,
    1,
    12.58006945
  ],
  [
    1528416000,
    7683.36,
    1,
    12.66767018
  ],
  [
    1528502400,
    7625.73,
    1,
    12.68776933
  ],
  [
    1528588800,
    7479.03,
    1,
    12.70077641
  ],
  [
    1528675200,
    6789.25,
    1,
    12.93587176
  ],
  [
    1528761600,
    6902.61,
    1,
    13.00950654
  ],
  [
    1528848000,
    6597.71,
    1,
    13.23634678
  ],
  [
    1528934400,
    6361.03,
    1,
    13.28097662
  ],
  [
    1529020800,
    6681.08,
    1,
    12.83241235
  ],
  [
    1529107200,
    6450.7,
    1,
    13.11616407
  ],
  [
    1529193600,
    6546.75,
    1,
    13.07112224
  ],
  [
    1529280000,
    6508.35,
    1,
    13.03387865
  ],
  [
    1529366400,
    6741.99,
    1,
    12.95873649
  ],
  [
    1529452800,
    6772.57,
    1,
    12.59942701
  ],
  [
    1529539200,
    6778.9,
    1,
    12.64547925
  ],
  [
    1529625600,
    6731.37,
    1,
    12.77978812
  ],
  [
    1529712000,
    6097.41,
    1,
    13.06760536
  ],
  [
    1529798400,
    6164.28,
    1,
    12.98377313
  ],
  [
    1529884800,
    6171.97,
    1,
    13.53692185
  ],
  [
    1529971200,
    6266.88,
    1,
    13.60197772
  ],
  [
    1530057600,
    6066.52,
    1,
    14.03494323
  ],
  [
    1530144000,
    6153.16,
    1,
    13.91204866
  ],
  [
    1530230400,
    5898.13,
    1,
    13.95719698
  ],
  [
    1530316800,
    6214.22,
    1,
    14.24596925
  ],
  [
    1530403200,
    6411.68,
    1,
    14.0841135
  ],
  [
    1530489600,
    6380.38,
    1,
    14.05915068
  ],
  [
    1530576000,
    6596.66,
    1,
    13.87642884
  ],
  [
    1530662400,
    6550.87,
    1,
    14.11366129
  ],
  [
    1530748800,
    6599.71,
    1,
    14.1234616
  ],
  [
    1530835200,
    6638.69,
    1,
    13.99513447
  ],
  [
    1530921600,
    6668.71,
    1,
    14.0673168
  ],
  [
    1531008000,
    6857.8,
    1,
    13.93669168
  ],
  [
    1531094400,
    6775.08,
    1,
    13.85842685
  ],
  [
    1531180800,
    6739.21,
    1,
    14.15318736
  ],
  [
    1531267200,
    6330.77,
    1,
    14.56973867
  ],
  [
    1531353600,
    6396.78,
    1,
    14.32643079
  ],
  [
    1531440000,
    6235.03,
    1,
    14.47512542
  ],
  [
    1531526400,
    6247.5,
    1,
    14.37823208
  ],
  [
    1531612800,
    6272.7,
    1,
    14.39078835
  ],
  [
    1531699200,
    6357.01,
    1,
    14.1133596
  ],
  [
    1531785600,
    6739.65,
    1,
    14.03868546
  ],
  [
    1531872000,
    7316.62,
    1,
    14.6088724
  ],
  [
    1531958400,
    7378.62,
    1,
    15.34613249
  ],
  [
    1532044800,
    7464.92,
    1,
    15.90836343
  ],
  [
    1532131200,
    7352.72,
    1,
    16.31476062
  ],
  [
    1532217600,
    7417.8,
    1,
    16.0406368
  ],
  [
    1532304000,
    7414.71,
    1,
    16.13861688
  ],
  [
    1532390400,
    7716.51,
    1,
    17.10462034
  ],
  [
    1532476800,
    8387.2,
    1,
    17.51555311
  ],
  [
    1532563200,
    8187.64,
    1,
    17.31573204
  ],
  [
    1532681400,
    7978.73,
    1,
    17.137699058780065
  ],
  [
    1532768100,
    8203.41,
    1,
    17.439333159012495
  ],
  [
    1532854500,
    8162.3,
    1,
    17.571812077647245
  ],
  [
    1532940900,
    8160.75723646,
    1,
    17.634923296326203
  ],
  [
    1533027300,
    8111.91744258,
    1,
    18.103274934926468
  ],
  [
    1533113700,
    7658.34438101,
    1,
    17.915600359299347
  ],
  [
    1533200100,
    7675.30365783,
    1,
    18.213672841360577
  ],
  [
    1533372900,
    7479.96169523,
    1,
    17.87742369389947
  ],
  [
    1533459300,
    7013.82453441,
    1,
    17.256835964891014
  ],
  [
    1533545700,
    7004.35998646,
    1,
    17.122138724845467
  ],
  [
    1533632100,
    7057.31447207,
    1,
    17.210115055147874
  ],
  [
    1533718500,
    6517.31375628,
    1,
    17.64155945323341
  ],
  [
    1533804900,
    6345.65361994,
    1,
    17.49815760646557
  ],
  [
    1533891300,
    6460.90358763,
    1,
    17.88655775886468
  ],
  [
    1533977700,
    6153.02832358,
    1,
    19.153106071539106
  ],
  [
    1534064100,
    6345.79448404,
    1,
    19.506040036530734
  ],
  [
    1534150500,
    6445.39208946,
    1,
    20.06721152419437
  ],
  [
    1534236900,
    6065.66398165,
    1,
    22.62867812708982
  ],
  [
    1534323300,
    6468.47346407,
    1,
    22.4391733856152
  ],
  [
    1534409700,
    6408.22178119,
    1,
    22.014060805094232
  ],
  [
    1534496100,
    6499.07836273,
    1,
    21.573465083963885
  ],
  [
    1534582500,
    6522.81799375,
    1,
    21.024603211159235
  ],
  [
    1534668900,
    6397.8700452,
    1,
    21.541747603495285
  ],
  [
    1534755300,
    6469.57879433,
    1,
    22.047033702894918
  ],
  [
    1534841700,
    6475.59263789,
    1,
    22.72433683755797
  ],
  [
    1534928100,
    6681.43583853,
    1,
    23.477524206255026
  ],
  [
    1535014500,
    6451.32325067,
    1,
    23.40373641306653
  ],
  [
    1535100900,
    6533.13818013,
    1,
    23.67918793020017
  ],
  [
    1535187300,
    6731.63563588,
    1,
    24.007039757884627
  ],
  [
    1535273700,
    6661.81855031,
    1,
    24.374211693299554
  ],
  [
    1535360100,
    6721.22353738,
    1,
    24.223165630650467
  ],
  [
    1535446500,
    6920.70144878,
    1,
    24.205613708186377
  ],
  [
    1535532900,
    7072.26371003,
    1,
    24.139712887338565
  ],
  [
    1535619300,
    6989.42885056,
    1,
    24.67692002586238
  ],
  [
    1535705700,
    6985.1642047,
    1,
    24.83122112197648
  ],
  [
    1535792100,
    7068.77635507,
    1,
    24.623946277448212
  ],
  [
    1535878500,
    7291.44396137,
    1,
    24.42454346621992
  ],
  [
    1535964900,
    7253.93831058,
    1,
    25.007664116424344
  ],
  [
    1536051300,
    7299.80562134,
    1,
    25.498700030602617
  ],
  [
    1536137700,
    7380.98632516,
    1,
    26.04081007644237
  ],
  [
    1536224700,
    6454.63141299,
    1,
    28.14729515542617
  ],
  [
    1536310500,
    6492.25223262,
    1,
    28.810721514059733
  ],
  [
    1536396900,
    6518.98379833,
    1,
    29.645992013532357
  ],
  [
    1536483300,
    6231.694648,
    1,
    32.50743457774711
  ],
  [
    1536569700,
    6341.59918841,
    1,
    31.93893298887044
  ],
  [
    1536656100,
    6351.99793867,
    1,
    32.6137129860878
  ],
  [
    1536742500,
    6271.18242303,
    1,
    36.78850087125008
  ],
  [
    1536828900,
    6425.92884864,
    1,
    34.029606105458086
  ],
  [
    1536915300,
    6558.50946907,
    1,
    31.126983025020206
  ],
  [
    1537001700,
    6523.87465795,
    1,
    30.155868721998097
  ],
  [
    1537088100,
    6490.90778391,
    1,
    29.80283347965626
  ],
  [
    1537174500,
    6482.31218524,
    1,
    29.949536711800267
  ],
  [
    1537260900,
    6290.32892512,
    1,
    31.54734428000226
  ],
  [
    1537347300,
    6369.77022417,
    1,
    30.08797658712935
  ],
  [
    1537433700,
    6410.68707075,
    1,
    30.697517661208618
  ],
  [
    1537520100,
    6713.68313647,
    1,
    29.598317763611163
  ],
  [
    1537606500,
    6649.95275342,
    1,
    28.32156776580314
  ],
  [
    1537692900,
    6728.79222522,
    1,
    27.877655623942328
  ],
  [
    1537779300,
    6650.56177795,
    1,
    28.41256405575012
  ],
  [
    1537865700,
    6418.06862652,
    1,
    30.20495895229409
  ],
  [
    1537952100,
    6469.68046883,
    1,
    30.309611496354787
  ],
  [
    1538038500,
    6472.62581425,
    1,
    30.413204757003957
  ],
  [
    1538124900,
    6743.83990778,
    1,
    29.572115235625734
  ],
  [
    1538211300,
    6539.37711621,
    1,
    28.907072873313293
  ],
  [
    1538297700,
    6583.42474017,
    1,
    28.348728037313492
  ],
  [
    1538384100,
    6604.5543475,
    1,
    28.597896130381592
  ],
  [
    1538470500,
    6585.27033019,
    1,
    28.698082358992163
  ],
  [
    1538556900,
    6455.55453759,
    1,
    29.589846075465644
  ],
  [
    1538643300,
    6596.29115051,
    1,
    29.368469277574643
  ],
  [
    1538729700,
    6571.84810061,
    1,
    29.663065776963446
  ],
  [
    1538816100,
    6607.48821634,
    1,
    29.068201187904656
  ],
  [
    1538902500,
    6562.15112462,
    1,
    29.384699958679256
  ],
  [
    1538989500,
    6590.18141122,
    1,
    29.256965214549542
  ],
  [
    1539075300,
    6632.3126907,
    1,
    28.97853392285666
  ],
  [
    1539161700,
    6558.45194249,
    1,
    29.09321084682692
  ],
  [
    1539248100,
    6287.57325535,
    1,
    31.211502692079137
  ],
  [
    1539334500,
    6293.42620028,
    1,
    31.856814915211704
  ],
  [
    1539420900,
    6273.19252815,
    1,
    31.55319732741712
  ],
  [
    1539507300,
    6328.40093743,
    1,
    31.493083412900205
  ],
  [
    1539593700,
    6634.99129008,
    1,
    31.479973128597035
  ],
  [
    1539680100,
    6604.95628682,
    1,
    31.490794181049843
  ],
  [
    1539766500,
    6567.93740996,
    1,
    31.364835369309613
  ],
  [
    1539852900,
    6545.24561471,
    1,
    31.685755996401365
  ],
  [
    1539939300,
    6464.8115112,
    1,
    31.858660012610155
  ],
  [
    1540025700,
    6480.79713078,
    1,
    31.530199917065662
  ],
  [
    1540112100,
    6506.3648941,
    1,
    31.46502374880505
  ],
  [
    1540198500,
    6479.27740229,
    1,
    31.74418252550072
  ],
  [
    1540284900,
    6480.16875773,
    1,
    31.802155448536215
  ],
  [
    1540371300,
    6510.54505605,
    1,
    31.81732743321067
  ],
  [
    1540457700,
    6477.77290906,
    1,
    31.91073034218847
  ],
  [
    1540544100,
    6468.01239102,
    1,
    31.74027880575692
  ],
  [
    1540630500,
    6491.71796783,
    1,
    31.817576460677703
  ],
  [
    1540716900,
    6472.61408982,
    1,
    31.690594631871924
  ],
  [
    1540803300,
    6463.61844183,
    1,
    31.66286117994902
  ],
  [
    1540889700,
    6342.64257443,
    1,
    32.11727334108801
  ],
  [
    1540976100,
    6329.09036816,
    1,
    32.07834349013231
  ],
  [
    1541062500,
    6388.11765003,
    1,
    32.042597872753056
  ],
  [
    1541148900,
    6372.3305525,
    1,
    32.012924548920786
  ],
  [
    1541235300,
    6382.64442832,
    1,
    31.853917917201446
  ],
  [
    1541324700,
    6367.55351783,
    1,
    31.717569503109793
  ],
  [
    1541408100,
    6443.42954455,
    1,
    30.847287428685284
  ],
  [
    1541494500,
    6433.71476492,
    1,
    30.320470522309282
  ],
  [
    1541580900,
    6542.82962864,
    1,
    29.688166114374543
  ],
  [
    1541667300,
    6490.91048945,
    1,
    30.23881845013747
  ],
  [
    1541753700,
    6440.23529911,
    1,
    30.294657357906722
  ],
  [
    1541840100,
    6409.77174437,
    1,
    30.315646268053264
  ],
  [
    1541926500,
    6394.82610897,
    1,
    30.16708452000503
  ],
  [
    1542012900,
    6406.13572111,
    1,
    30.31612037449514
  ],
  [
    1542099300,
    6369.34897422,
    1,
    30.560716961491654
  ],
  [
    1542185700,
    6353.21403641,
    1,
    30.761465517282655
  ],
  [
    1542272100,
    5662.96576632,
    1,
    31.594919014423983
  ],
  [
    1542358500,
    5577.20702649,
    1,
    31.288789824961786
  ],
  [
    1542444900,
    5534.38203707,
    1,
    31.68334436742605
  ],
  [
    1542531300,
    5601.48918877,
    1,
    31.80485551798023
  ],
  [
    1542617700,
    5321.34070128,
    1,
    33.861819158736594
  ],
  [
    1542704100,
    4397.29378895,
    1,
    34.5182730338621
  ],
  [
    1542790500,
    4625.51003595,
    1,
    33.85244840665525
  ],
  [
    1542876900,
    4572.06678032,
    1,
    33.82919407692544
  ],
  [
    1542963300,
    4312.8600214,
    1,
    34.63591283038841
  ],
  [
    1543049700,
    4341.74703295,
    1,
    35.18949662896687
  ],
  [
    1543136100,
    3768.67362821,
    1,
    35.27360670584052
  ],
  [
    1543222500,
    3972.33963761,
    1,
    34.79922571175212
  ],
  [
    1543308900,
    3713.42228418,
    1,
    35.259818178030415
  ],
  [
    1543395300,
    4009.45642675,
    1,
    35.032101243989246
  ],
  [
    1543481700,
    4239.50627918,
    1,
    35.165574722305266
  ],
  [
    1543568100,
    4220.32558289,
    1,
    35.83613764842148
  ],
  [
    1543827300,
    3998.59830353,
    1,
    35.40282796574618
  ],
  [
    1543913700,
    3989.37317844,
    1,
    35.783982807541186
  ],
  [
    1544086500,
    3855.64795331,
    1,
    37.311024654111165
  ],
  [
    1544172900,
    3368.02233432,
    1,
    39.79757946594121
  ],
  [
    1544259300,
    3396.20109356,
    1,
    37.60823472707609
  ],
  [
    1544345700,
    3419.15532651,
    1,
    37.76609058036478
  ],
  [
    1544432100,
    3501.35114926,
    1,
    38.37319719884592
  ],
  [
    1544518500,
    3423.19078506,
    1,
    38.080626492450776
  ],
  [
    1544604900,
    3393.36274775,
    1,
    38.406867934951414
  ],
  [
    1544691300,
    3395.96545309,
    1,
    38.113845876507696
  ],
  [
    1544777700,
    3295.63108971,
    1,
    38.37594443601146
  ],
  [
    1544864100,
    3225.24800454,
    1,
    38.38426919652786
  ],
  [
    1544950500,
    3251.54474196,
    1,
    37.77144087526485
  ],
  [
    1545036900,
    3277.10936873,
    1,
    37.976252316298805
  ],
  [
    1545123300,
    3449.01023552,
    1,
    37.45013283422799
  ],
  [
    1545209700,
    3719.32219413,
    1,
    36.82719398549077
  ],
  [
    1545296100,
    4003.46408828,
    1,
    37.28143078492564
  ],
  [
    1545382500,
    3995.30677937,
    1,
    35.11382326195137
  ],
  [
    1545468900,
    3824.56289901,
    1,
    35.63586743087466
  ],
  [
    1545555300,
    3977.3222934,
    1,
    31.106250953770825
  ],
  [
    1545641700,
    4178.67875029,
    1,
    28.75498540083518
  ],
  [
    1545728100,
    3751.30156184,
    1,
    29.605549105108828
  ],
  [
    1545814500,
    3719.22271434,
    1,
    29.168459301318876
  ],
  [
    1545900900,
    3764.45409519,
    1,
    30.27863502936679
  ],
  [
    1545987300,
    3602.79164555,
    1,
    30.92027467423543
  ],
  [
    1546073700,
    3863.63760229,
    1,
    28.42219324352132
  ],
  [
    1546160100,
    3737.93366541,
    1,
    28.184977206470037
  ],
  [
    1546246500,
    3768.95748471,
    1,
    27.97432198467176
  ],
  [
    1546332900,
    3720.09669755,
    1,
    27.718210976611978
  ],
  [
    1546419300,
    3793.75963846,
    1,
    26.133983810881094
  ],
  [
    1546505700,
    3828.23970998,
    1,
    25.423058474930524
  ],
  [
    1546592100,
    3797.95728243,
    1,
    24.840254998170842
  ],
  [
    1546678500,
    3815.40911876,
    1,
    24.557067398529103
  ],
  [
    1546764900,
    3784.08877257,
    1,
    25.171491718493655
  ],
  [
    1546851300,
    4024.14888568,
    1,
    26.121258622535397
  ],
  [
    1546937700,
    3979.99077927,
    1,
    26.93678243841707
  ],
  [
    1547024100,
    4000.79692734,
    1,
    26.651983697811616
  ],
  [
    1547110500,
    3772.3711096,
    1,
    28.086435575071008
  ],
  [
    1547196900,
    3652.8838674,
    1,
    28.71420607185726
  ],
  [
    1547283300,
    3603.52408417,
    1,
    28.845259365666845
  ],
  [
    1547369700,
    3602.14574121,
    1,
    29.14010570545778
  ],
  [
    1547456100,
    3523.24030822,
    1,
    30.183044815457862
  ],
  [
    1547542500,
    3643.0574426,
    1,
    28.562936195637256
  ],
  [
    1547628900,
    3597.87545973,
    1,
    29.618915216882698
  ],
  [
    1547715300,
    3589.32378152,
    1,
    29.789500689293547
  ],
  [
    1547801700,
    3616.82747416,
    1,
    29.789198701233055
  ],
  [
    1547888100,
    3613.0859744,
    1,
    30.032984398648402
  ],
  [
    1547974500,
    3684.68487312,
    1,
    30.076158927050344
  ],
  [
    1548060900,
    3528.55146246,
    1,
    30.501651150019555
  ],
  [
    1548147300,
    3526.60865938,
    1,
    30.360500398868727
  ],
  [
    1548233700,
    3573.05766247,
    1,
    30.35028630002308
  ],
  [
    1548320100,
    3542.8666687,
    1,
    30.66142389718582
  ],
  [
    1548406500,
    3552.15100498,
    1,
    30.765436591139586
  ],
  [
    1548492900,
    3603.67827631,
    1,
    30.918671819998913
  ],
  [
    1548579300,
    3564.08138289,
    1,
    31.00874222844961
  ],
  [
    1548665700,
    3436.11826806,
    1,
    32.306003988987385
  ],
  [
    1548752100,
    3385.58528991,
    1,
    32.57779338579088
  ],
  [
    1548838500,
    3431.37403932,
    1,
    32.59285363938993
  ],
  [
    1548924900,
    3441.76471989,
    1,
    31.960370741775684
  ],
  [
    1549011300,
    3428.8167278,
    1,
    32.55366670311594
  ],
  [
    1549097700,
    3449.91813692,
    1,
    32.45591938311721
  ],
  [
    1549184100,
    3457.40422127,
    1,
    32.00408079866672
  ],
  [
    1549270500,
    3467.95217017,
    1,
    32.11377375128248
  ],
  [
    1549356900,
    3462.15147601,
    1,
    32.194933834543654
  ],
  [
    1549443300,
    3410.91947841,
    1,
    33.050705243430556
  ],
  [
    1549529700,
    3413.68534727,
    1,
    32.41665222992423
  ],
  [
    1549616100,
    3405.60079679,
    1,
    32.42939228576792
  ],
  [
    1549702500,
    3658.75709279,
    1,
    30.73818678447427
  ],
  [
    1549788900,
    3638.2589677,
    1,
    31.152205236157343
  ],
  [
    1549875300,
    3627.10857089,
    1,
    30.178800234912703
  ],
  [
    1549961700,
    3601.66283123,
    1,
    29.94564197976262
  ],
  [
    1550048100,
    3624.75404774,
    1,
    29.393412196682327
  ],
  [
    1550134500,
    3605.27492602,
    1,
    29.518154795650236
  ],
  [
    1550220900,
    3614.48014737,
    1,
    29.479755031078717
  ],
  [
    1550307300,
    3622.07773432,
    1,
    29.510229022856016
  ],
  [
    1550393700,
    3636.56013583,
    1,
    29.266564095605435
  ],
  [
    1550480100,
    3747.35258731,
    1,
    27.12738353755494
  ],
  [
    1550566500,
    3912.66240746,
    1,
    26.475485076197177
  ],
  [
    1550652900,
    3927.52567603,
    1,
    27.279976771786817
  ],
  [
    1550739300,
    3956.16300751,
    1,
    27.0585457263254
  ],
  [
    1550825700,
    3953.30906555,
    1,
    26.986956938650998
  ],
  [
    1550912100,
    3938.00359189,
    1,
    26.95368753747649
  ],
  [
    1550998500,
    4152.90658813,
    1,
    25.394231412862062
  ],
  [
    1551084900,
    3790.11776417,
    1,
    27.573574143856508
  ],
  [
    1551171300,
    3807.29376801,
    1,
    27.89378626149017
  ],
  [
    1551257700,
    3824.3377985,
    1,
    27.651701553926877
  ],
  [
    1551344100,
    3831.29060669,
    1,
    28.068911690000725
  ],
  [
    1551430500,
    3834.01936824,
    1,
    28.186777418104697
  ],
  [
    1551516900,
    3799.63170935,
    1,
    28.85704787914364
  ],
  [
    1551603300,
    3813.10567238,
    1,
    28.56916364001406
  ],
  [
    1551689700,
    3723.81436098,
    1,
    29.477067316085627
  ],
  [
    1551776100,
    3740.0128437,
    1,
    29.12969660869597
  ],
  [
    1551862500,
    3840.55810682,
    1,
    28.1793985982197
  ],
  [
    1551948900,
    3859.39691647,
    1,
    28.139005079479734
  ],
  [
    1552035300,
    3885.95874039,
    1,
    28.49137591119385
  ],
  [
    1552121700,
    3914.6340432,
    1,
    28.53746114033998
  ],
  [
    1552208100,
    3914.96207312,
    1,
    28.86490963699229
  ],
  [
    1552294500,
    3859.01045683,
    1,
    29.053994815560337
  ],
  [
    1552380900,
    3880.20518438,
    1,
    29.013651319250847
  ],
  [
    1552467300,
    3878.33601348,
    1,
    29.324672851298104
  ],
  [
    1552553700,
    3865.70157365,
    1,
    29.341657097385415
  ],
  [
    1552640100,
    3879.52431526,
    1,
    29.11465896126527
  ],
  [
    1552726200,
    3999.24763957,
    1,
    28.329419216978966
  ],
  [
    1552812600,
    3970.55482494,
    1,
    28.65183991661752
  ],
  [
    1552899000,
    3986.25718885,
    1,
    28.884749948451677
  ],
  [
    1552985400,
    3978.9713143,
    1,
    28.9287915225862
  ],
  [
    1553071800,
    4010.85441027,
    1,
    29.0942199038112
  ],
  [
    1553158200,
    4046.97514447,
    1,
    29.079776972957116
  ],
  [
    1553244600,
    3992.82793266,
    1,
    29.3776065438338
  ],
  [
    1553331000,
    4005.92042882,
    1,
    29.05664817019994
  ],
  [
    1553417400,
    3983.37458624,
    1,
    29.240708866442933
  ],
  [
    1553503800,
    4000.53458957,
    1,
    29.409137823068473
  ],
  [
    1553590200,
    3931.50489538,
    1,
    29.371111389122753
  ],
  [
    1553676600,
    3993.53010968,
    1,
    29.15363727830706
  ],
  [
    1553763000,
    4019.51926172,
    1,
    29.131138984208246
  ],
  [
    1553849400,
    4047.81736735,
    1,
    29.22133741943123
  ],
  [
    1553935800,
    4089.33856853,
    1,
    28.837604607144666
  ],
  [
    1554022200,
    4092.50407107,
    1,
    29.075067273276687
  ],
  [
    1554108600,
    4131.53898371,
    1,
    28.997244382318524
  ],
  [
    1554195000,
    4683.57702695,
    1,
    30.943740364362537
  ],
  [
    1554281400,
    4913.92929908,
    1,
    29.768759241652013
  ],
  [
    1554367800,
    5009.10064434,
    1,
    30.79514379310921
  ],
  [
    1554454200,
    4961.57791358,
    1,
    30.256978854204853
  ],
  [
    1554540600,
    4972.68777492,
    1,
    30.944010370362438
  ],
  [
    1554627000,
    5160.9129136,
    1,
    30.605962493186418
  ],
  [
    1554713400,
    5184.06991668,
    1,
    29.140647431101158
  ],
  [
    1554799800,
    5189.84646085,
    1,
    29.53012793719794
  ],
  [
    1554886200,
    5211.63878032,
    1,
    29.03491977453973
  ],
  [
    1554972600,
    5188.87,
    1,
    30.625450038363923
  ],
  [
    1555059000,
    5004.0804368,
    1,
    30.994100014293853
  ],
  [
    1555145400,
    5070.83849739,
    1,
    30.982754371406894
  ],
  [
    1555231800,
    5062.81563401,
    1,
    31.239565705808527
  ],
  [
    1555318200,
    5146.62363764,
    1,
    30.791529661085384
  ],
  [
    1555404600,
    5085.15760404,
    1,
    31.094137368707315
  ],
  [
    1555491000,
    5193.96727168,
    1,
    31.37517652706596
  ],
  [
    1555577400,
    5246.42,
    1,
    30.677230733247573
  ],
  [
    1555663800,
    5251.83,
    1,
    30.443626456437308
  ],
  [
    1555750200,
    5326.06,
    1,
    30.32200398519784
  ],
  [
    1555836600,
    5299.8,
    1,
    31.091165082717353
  ],
  [
    1555923000,
    5269.57,
    1,
    31.046780180286337
  ],
  [
    1556009400,
    5562.81,
    1,
    31.686090225563913
  ],
  [
    1556095800,
    5487.77,
    1,
    33.17075676982592
  ],
  [
    1556182200,
    5421.11,
    1,
    33.23387690044139
  ],
  [
    1556268600,
    5310.71,
    1,
    34.03428608049219
  ],
  [
    1556355000,
    5271.1,
    1,
    33.698376166730604
  ],
  [
    1556441400,
    5274.55,
    1,
    33.29892676767677
  ],
  [
    1556527800,
    5256.94,
    1,
    33.64011006591156
  ],
  [
    1556614200,
    5202.87,
    1,
    33.139299363057326
  ],
  [
    1556700600,
    5319.91,
    1,
    33.362034365985195
  ],
  [
    1556787000,
    5372.88,
    1,
    33.51347305389222
  ],
  [
    1556873400,
    5677.36,
    1,
    34.86679358840508
  ],
  [
    1556959800,
    5767.12,
    1,
    35.12895169641226
  ],
  [
    1557046200,
    5743.31,
    1,
    35.395722913841986
  ],
  [
    1557132600,
    5636.6,
    1,
    35.379111222696466
  ],
  [
    1557219000,
    5905.92,
    1,
    33.463199048104705
  ],
  [
    1557305400,
    5871.96,
    1,
    34.63670146876659
  ],
  [
    1557391800,
    6089.54,
    1,
    35.548978400467014
  ],
  [
    1557478200,
    6324.25,
    1,
    36.45100864553314
  ],
  [
    1557564600,
    6729.29,
    1,
    36.67587747983432
  ],
  [
    1557651000,
    7374.57,
    1,
    37.64456355283308
  ],
  [
    1557737400,
    7046.9,
    1,
    37.54542064041771
  ],
  [
    1557823800,
    8153.68,
    1,
    38.882594182165
  ],
  [
    1557910200,
    7908.16,
    1,
    34.46271843814006
  ],
  [
    1557996600,
    8030.77,
    1,
    30.550348080800397
  ],
  [
    1558083000,
    7320.24,
    1,
    30.49337665583604
  ],
  [
    1558169400,
    7353.54,
    1,
    31.0315229775921
  ],
  [
    1558255800,
    7918.21,
    1,
    31.178965191368718
  ],
  [
    1558342200,
    7882.07,
    1,
    31.68289251547552
  ],
  [
    1558428600,
    7926.23,
    1,
    31.444559051057244
  ],
  [
    1558515000,
    7911.3,
    1,
    30.893861293345832
  ],
  [
    1558601400,
    7598.43,
    1,
    32.364042933810374
  ],
  [
    1558687800,
    7954.29,
    1,
    31.831165712913684
  ],
  [
    1558774200,
    7992.05,
    1,
    31.877667424514378
  ],
  [
    1558860600,
    7988.61,
    1,
    32.1279308264629
  ],
  [
    1558947000,
    8701.27,
    1,
    32.59757239725771
  ],
  [
    1559033400,
    8703.25,
    1,
    32.3372594188898
  ],
  [
    1559119800,
    8607.37,
    1,
    32.14583955781297
  ],
  [
    1559206200,
    8690.21,
    1,
    30.83712430360881
  ],
  [
    1559292600,
    8326.12,
    1,
    32.49344364658133
  ],
  [
    1559379000,
    8539.34,
    1,
    31.698800994840198
  ],
  [
    1559465400,
    8744.58,
    1,
    32.061963775023834
  ],
  [
    1559551800,
    8525.93,
    1,
    32.427848775292865
  ],
  [
    1559638200,
    7935.97,
    1,
    32.10993323892373
  ],
  [
    1559724600,
    7791.53,
    1,
    31.81514904042466
  ],
  [
    1559811000,
    7816.05,
    1,
    31.81394496906545
  ],
  [
    1559897400,
    7983.95,
    1,
    32.069207904884315
  ],
  [
    1559983800,
    7946.4,
    1,
    31.96588760609839
  ],
  [
    1560070200,
    7941.6,
    1,
    32.492942187308216
  ],
  [
    1560156600,
    7738.14,
    1,
    32.55696735106025
  ],
  [
    1560243000,
    7947.26,
    1,
    32.252181323809914
  ],
  [
    1560329400,
    7970.54,
    1,
    32.13668252560277
  ],
  [
    1560415800,
    8120.68,
    1,
    31.288741619788855
  ],
  [
    1560502200,
    8287.83,
    1,
    32.33644166991807
  ],
  [
    1560588600,
    8680.5,
    1,
    32.85952227732142
  ],
  [
    1560675000,
    9089.17,
    1,
    33.45050051523627
  ],
  [
    1560761400,
    9177.92,
    1,
    33.86811321450976
  ],
  [
    1560847800,
    9133.27,
    1,
    34.01083637446935
  ],
  [
    1560934200,
    9169.84,
    1,
    34.09369422962523
  ],
  [
    1561020600,
    9243.8,
    1,
    34.565306809258495
  ],
  [
    1561107000,
    9729.06,
    1,
    34.02721040850587
  ],
  [
    1561193400,
    10784.38,
    1,
    34.986958214378404
  ],
  [
    1561279800,
    10715.17,
    1,
    34.42514296729423
  ],
  [
    1561366200,
    10868.21,
    1,
    35.29441756243302
  ],
  [
    1561452600,
    11347.59,
    1,
    36.12731614135625
  ],
  [
    1561539000,
    12651.92,
    1,
    38.03029938679813
  ],
  [
    1561625700,
    12115.27,
    1,
    38.027778649675135
  ],
  [
    1561711800,
    11594.69,
    1,
    38.07779967159278
  ],
  [
    1561798200,
    11787.19,
    1,
    39.12500414910214
  ],
  [
    1561884600,
    11791.81,
    1,
    38.0786320922272
  ],
  [
    1561971000,
    11044.5,
    1,
    37.15934324742614
  ],
  [
    1562057400,
    10263.02,
    1,
    36.12213149373504
  ],
  [
    1562143800,
    11220.45,
    1,
    38.14663085605494
  ],
  [
    1562230200,
    11701.99,
    1,
    39.47640252336133
  ],
  [
    1562316600,
    11004.79,
    1,
    38.45408484170802
  ],
  [
    1562403000,
    11342.01,
    1,
    38.84782161939992
  ],
  [
    1562489400,
    11224.92,
    1,
    38.88899667405765
  ],
  [
    1562575800,
    11439.73,
    1,
    37.2909019786811
  ],
  [
    1562662200,
    12571.46,
    1,
    40.012285559693176
  ],
  [
    1562748600,
    12913.8,
    1,
    41.84775916264299
  ],
  [
    1562835000,
    11442.83904982,
    1,
    42.28086885164318
  ],
  [
    1562921400,
    11604.74945646,
    1,
    42.32919103368562
  ],
  [
    1563007800,
    11558.99600294,
    1,
    42.736957396888016
  ],
  [
    1563094200,
    11165.27521884,
    1,
    41.90347519524218
  ],
  [
    1563180600,
    10244.78335219,
    1,
    45.675225933084214
  ],
  [
    1563267000,
    10761.83932518,
    1,
    47.5996804442351
  ],
  [
    1563353400,
    9542.92366573,
    1,
    46.71607619152106
  ],
  [
    1563439800,
    9790.11825052,
    1,
    45.15533872057559
  ],
  [
    1563526200,
    10377.0485658,
    1,
    47.379953088917524
  ],
  [
    1563612600,
    10555.77268182,
    1,
    46.78453267265502
  ],
  [
    1563699000,
    10691.48053078,
    1,
    47.08285782624993
  ],
  [
    1563785400,
    10570.85963903,
    1,
    47.041001634566086
  ],
  [
    1563871800,
    9985.5570562,
    1,
    47.20559733875042
  ],
  [
    1563958200,
    9745.62115894,
    1,
    46.974754142035245
  ],
  [
    1564044600,
    10092.66160125,
    1,
    45.383402619961416
  ],
  [
    1564131000,
    9781.01591344,
    1,
    45.20752239994587
  ],
  [
    1564217400,
    10053.43187486,
    1,
    45.632114636620585
  ],
  [
    1564303800,
    9503.32212949,
    1,
    45.4670031966007
  ],
  [
    1564390200,
    9604.4360346,
    1,
    45.26980062310601
  ],
  [
    1564476600,
    9493.50872666,
    1,
    45.631757828374816
  ],
  [
    1564563000,
    9765.37472076,
    1,
    45.971749740006295
  ],
  [
    1564649400,
    9977.09747884,
    1,
    46.841332019406416
  ],
  [
    1564735800,
    10519.82076153,
    1,
    47.801379400707894
  ],
  [
    1564822200,
    10776.82801501,
    1,
    48.386983372157616
  ],
  [
    1564908600,
    10685.208888,
    1,
    48.85923979014083
  ],
  [
    1564995000,
    11669.37455737,
    1,
    51.10974421221218
  ],
  [
    1565081400,
    12191.77530533,
    1,
    52.22391072792058
  ],
  [
    1565167800,
    11569.3266755,
    1,
    51.213349164613376
  ],
  [
    1565254200,
    11923.91046796,
    1,
    52.640196796427816
  ],
  [
    1565340600,
    11853.61972916,
    1,
    54.769144400500075
  ],
  [
    1565427000,
    11855.80368037,
    1,
    56.00694733757741
  ],
  [
    1565513400,
    11329.60186804,
    1,
    53.93818943384386
  ],
  [
    1565599800,
    11349.56500218,
    1,
    53.474343598408176
  ],
  [
    1565686200,
    11333.71127678,
    1,
    54.100174185871644
  ],
  [
    1565772600,
    10480.53038327,
    1,
    50.68005601507096
  ],
  [
    1565859000,
    9855.2096108,
    1,
    54.378894777840074
  ],
  [
    1565945400,
    10047.77012808,
    1,
    54.540029669537574
  ],
  [
    1566031800,
    10374.8234572,
    1,
    55.973561279908296
  ],
  [
    1566118200,
    10131.30150522,
    1,
    54.732455695775165
  ],
  [
    1566204600,
    10678.4693474,
    1,
    53.25974368694053
  ],
  [
    1566291000,
    10702.76944883,
    1,
    54.22994436172259
  ],
  [
    1566377400,
    10173.00482377,
    1,
    54.31182648228817
  ],
  [
    1566463800,
    9951.55252749,
    1,
    53.61447124763014
  ],
  [
    1566550200,
    10167.16833888,
    1,
    52.86400410201657
  ],
  [
    1566636600,
    10126.45723573,
    1,
    53.40308719820929
  ],
  [
    1566723000,
    10070.46158125,
    1,
    53.22692603768607
  ],
  [
    1566809400,
    10348.76935102,
    1,
    54.499361108377066
  ],
  [
    1566895800,
    10190.13471163,
    1,
    54.613869353549916
  ],
  [
    1566982200,
    10131.92669733,
    1,
    54.40920420931867
  ],
  [
    1567068600,
    9443.77409941,
    1,
    55.90396164883093
  ],
  [
    1567155000,
    9540.66020681,
    1,
    56.45158931342533
  ],
  [
    1567241400,
    9573.75924981,
    1,
    56.8850946198881
  ],
  [
    1567327800,
    9579.11318124,
    1,
    56.22882578972988
  ],
  [
    1567414200,
    9742.40418063,
    1,
    57.0967786674197
  ],
  [
    1567500600,
    10359.2,
    1,
    58.289444069322535
  ],
  [
    1567587000,
    10479.38,
    1,
    59.35308110557317
  ],
  [
    1567673400,
    10592.27304166,
    1,
    60.55346750053168
  ],
  [
    1567759800,
    10709.46484612,
    1,
    61.18018265965592
  ],
  [
    1567846200,
    10410.69663774,
    1,
    61.06389829420176
  ],
  [
    1567932600,
    10504.97528237,
    1,
    58.07017839433998
  ],
  [
    1568019000,
    10177.96,
    1,
    57.000224014336915
  ],
  [
    1568105400,
    10248.96,
    1,
    56.34392523364485
  ],
  [
    1568191800,
    10043.48,
    1,
    56.22189879086431
  ],
  [
    1568278200,
    10149.76,
    1,
    56.957126823793494
  ],
  [
    1568364600,
    10312.53,
    1,
    57.384285793778865
  ],
  [
    1568451000,
    10318.83869439,
    1,
    57.13400189201139
  ],
  [
    1568537400,
    10298.45128084,
    1,
    54.960055837161946
  ],
  [
    1568623800,
    10300.89093895,
    1,
    53.1275615263263
  ],
  [
    1568710200,
    10188.70529637,
    1,
    51.451261865689624
  ],
  [
    1568796600,
    10173.11599961,
    1,
    47.61777936444003
  ],
  [
    1568883000,
    9881.6137499,
    1,
    47.545801687884754
  ],
  [
    1568969400,
    10158.69050923,
    1,
    46.717216831083356
  ],
  [
    1569055800,
    10033.6650757,
    1,
    46.380791459983406
  ],
  [
    1569142200,
    10018.29736917,
    1,
    47.311833755499926
  ],
  [
    1569228600,
    9931.98920029,
    1,
    47.464045400117975
  ],
  [
    1569315000,
    9701.5692691,
    1,
    49.59741868480223
  ],
  [
    1569401400,
    8266.63360618,
    1,
    50.54292110501481
  ],
  [
    1569487800,
    8408.03714747,
    1,
    49.347310838076694
  ],
  [
    1569574200,
    8077.79169835,
    1,
    48.60619366926516
  ],
  [
    1569660600,
    8201.00801042,
    1,
    47.099108739163725
  ],
  [
    1569747000,
    8102.57223311,
    1,
    47.41043305639113
  ],
  [
    1569833400,
    7872.07732728,
    1,
    46.91160730620154
  ],
  [
    1569919800,
    8385.99290706,
    1,
    46.61346807089257
  ],
  [
    1570006200,
    8266.3108025,
    1,
    46.81923526193035
  ],
  [
    1570092600,
    8277.18380645,
    1,
    46.568045875079875
  ],
  [
    1570179000,
    8097.06704631,
    1,
    46.901914127680556
  ],
  [
    1570265400,
    8098.2703517,
    1,
    46.3730144545375
  ],
  [
    1570351800,
    7911.58877092,
    1,
    45.839338041094344
  ],
  [
    1570438200,
    7891.19248696,
    1,
    45.7941545715566
  ],
  [
    1570524600,
    8178.82742617,
    1,
    45.33592403422169
  ],
  [
    1570611000,
    8182.47042398,
    1,
    45.08437026281723
  ],
  [
    1570697400,
    8572.12673621,
    1,
    44.924778425124764
  ],
  [
    1570783800,
    8365.35169596,
    1,
    44.98847549908577
  ],
  [
    1570870200,
    8347.54148202,
    1,
    45.3724168479591
  ],
  [
    1570956600,
    8347.62270983,
    1,
    45.84688130133431
  ],
  [
    1571043000,
    8307.16165156,
    1,
    45.399224449336046
  ],
  [
    1571129400,
    8288.07786962,
    1,
    45.31108566965421
  ],
  [
    1571215800,
    8128.74049226,
    1,
    45.45539614298059
  ],
  [
    1571302200,
    8044.05922057,
    1,
    45.63159311631923
  ],
  [
    1571388600,
    7878.07985909,
    1,
    46.15399967816143
  ],
  [
    1571475000,
    7953.78648087,
    1,
    45.66246488016328
  ],
  [
    1571561400,
    7910.0958872,
    1,
    46.511196667904365
  ],
  [
    1571647800,
    8220.98343084,
    1,
    47.236634903462985
  ],
  [
    1571734200,
    8249.22908558,
    1,
    47.350505237847415
  ],
  [
    1571820600,
    7976.90133385,
    1,
    47.85766482733137
  ],
  [
    1571907000,
    7444.36238059,
    1,
    46.255453997725155
  ],
  [
    1571993400,
    7456.63257785,
    1,
    45.94636159415469
  ],
  [
    1572079800,
    9376.4566042,
    1,
    51.57657787185258
  ],
  [
    1572166200,
    9224.82941642,
    1,
    51.32353642255688
  ],
  [
    1572252600,
    9345.06026103,
    1,
    51.36531297956596
  ],
  [
    1572339000,
    9425.43836032,
    1,
    50.620856666869415
  ],
  [
    1572425400,
    9192.3911923,
    1,
    49.406286656705724
  ],
  [
    1572511800,
    9127.79304866,
    1,
    50.04587786919024
  ],
  [
    1572598200,
    9127.90535888,
    1,
    50.253317860859745
  ],
  [
    1572684600,
    9217.75956282,
    1,
    50.61153359022487
  ],
  [
    1572771000,
    9235.21657977,
    1,
    50.55623614087519
  ],
  [
    1572857400,
    9159.74880626,
    1,
    50.499032820934694
  ],
  [
    1572943800,
    9246.17244054,
    1,
    50.24950432042498
  ],
  [
    1573030200,
    9395.76188607,
    1,
    48.76823045497605
  ],
  [
    1573116600,
    9261.4420597,
    1,
    49.090540051182906
  ],
  [
    1573203000,
    9094.00421967,
    1,
    49.36203671213005
  ],
  [
    1573289400,
    8814.79983745,
    1,
    47.61334734164909
  ],
  [
    1573375800,
    8822.61849598,
    1,
    47.24834457814264
  ],
  [
    1573462200,
    8779.6433681,
    1,
    47.09689914883607
  ],
  [
    1573548600,
    8756.25700958,
    1,
    47.08423646210955
  ],
  [
    1573635000,
    8782.66272721,
    1,
    47.04244721851873
  ],
  [
    1573721400,
    8619.97406721,
    1,
    46.546802736931916
  ],
  [
    1573807800,
    8606.97793531,
    1,
    46.83804576378081
  ],
  [
    1573894200,
    8484.30868214,
    1,
    46.815366352736774
  ],
  [
    1573980600,
    8503.46872992,
    1,
    46.55625647803044
  ],
  [
    1574067000,
    8482.2116199,
    1,
    46.205187039027805
  ],
  [
    1574153400,
    8163.54773934,
    1,
    46.05025538826729
  ],
  [
    1574239800,
    8088.03368908,
    1,
    45.86772979196536
  ],
  [
    1574326200,
    7946.45831714,
    1,
    46.29961342716172
  ],
  [
    1574412600,
    7566.68772063,
    1,
    47.454752892621705
  ],
  [
    1574499000,
    7138.93048878,
    1,
    48.028067398352675
  ],
  [
    1574585400,
    7213.94086514,
    1,
    47.981489992692296
  ],
  [
    1574671800,
    6689.9598383,
    1,
    49.54469717255182
  ],
  [
    1574758200,
    7228.15096649,
    1,
    48.997467471078075
  ],
  [
    1574844600,
    7071.46501333,
    1,
    48.56292483301398
  ],
  [
    1574931000,
    7496.9172871,
    1,
    49.12778779876477
  ],
  [
    1575017400,
    7503.18225629,
    1,
    49.15597310102768
  ],
  [
    1575103800,
    7749.00071648,
    1,
    50.19199133465747
  ],
  [
    1575190200,
    7302.92841148,
    1,
    49.63725259817919
  ],
  [
    1575276600,
    7227.36508486,
    1,
    48.87676440193133
  ],
  [
    1575363000,
    7300.13991782,
    1,
    49.15413898562217
  ],
  [
    1575449400,
    7159.46322853,
    1,
    49.30804940578564
  ],
  [
    1575535800,
    7324.10621452,
    1,
    50.24386760847076
  ],
  [
    1575622200,
    7349.18209065,
    1,
    49.8786327115671
  ],
  [
    1575708600,
    7497.59225396,
    1,
    50.816869874388814
  ],
  [
    1575795000,
    7430.13422408,
    1,
    50.517024725540374
  ],
  [
    1575881400,
    7457.87576235,
    1,
    50.034570394105366
  ],
  [
    1575967800,
    7308.64564253,
    1,
    49.764224841649416
  ],
  [
    1576054200,
    7223.41696929,
    1,
    49.56437260539862
  ],
  [
    1576140600,
    7134.29945861,
    1,
    50.346297315385605
  ],
  [
    1576227000,
    7217.30805448,
    1,
    50.14082117229739
  ],
  [
    1576313400,
    7192.36507217,
    1,
    50.28445163737951
  ],
  [
    1576399800,
    7142.94415647,
    1,
    49.83390209973931
  ],
  [
    1576486200,
    7076.89242561,
    1,
    50.14795709026165
  ],
  [
    1576572600,
    6860.88406098,
    1,
    52.41804489017914
  ],
  [
    1576659000,
    6626.42475005,
    1,
    54.398789939542404
  ],
  [
    1576745400,
    7088.003896,
    1,
    55.64503852729716
  ],
  [
    1576831800,
    7121.29997221,
    1,
    56.121854678834005
  ],
  [
    1576918200,
    7142.39503816,
    1,
    56.09858281873049
  ],
  [
    1577004600,
    7167.65204627,
    1,
    55.54413221612983
  ],
  [
    1577091000,
    7520.98974405,
    1,
    56.815160791827545
  ],
  [
    1577177400,
    7302.90620552,
    1,
    57.14568389754224
  ],
  [
    1577263800,
    7245.76271346,
    1,
    57.69257202520482
  ],
  [
    1577350200,
    7221.70337572,
    1,
    57.55074538602874
  ],
  [
    1577436600,
    7202.61323673,
    1,
    57.53960833313069
  ],
  [
    1577523000,
    7292.3681148,
    1,
    57.30719307734493
  ],
  [
    1577609400,
    7345.59431481,
    1,
    57.07538848258311
  ],
  [
    1577695800,
    7368.51040149,
    1,
    54.64590614844625
  ],
  [
    1577782200,
    7232.18335396,
    1,
    54.90890755385249
  ],
  [
    1577868600,
    7176.26428243,
    1,
    55.24767444381841
  ],
  [
    1577955000,
    7143.56431239,
    1,
    54.95150892955743
  ],
  [
    1578041400,
    7274.1178713,
    1,
    55.00541960811995
  ],
  [
    1578127800,
    7353.94905294,
    1,
    54.90977381404121
  ],
  [
    1578214200,
    7428.62608279,
    1,
    54.87338595450327
  ],
  [
    1578300600,
    7530.26107794,
    1,
    53.97636154904701
  ],
  [
    1578387000,
    7873.0851136,
    1,
    55.059126453493796
  ],
  [
    1578473400,
    8319.89921865,
    1,
    57.856071666617204
  ],
  [
    1578559800,
    7920.18379025,
    1,
    56.91649958370341
  ],
  [
    1578646200,
    7692.94491626,
    1,
    56.66480602035423
  ],
  [
    1578732600,
    8072.92225533,
    1,
    56.40070442928173
  ],
  [
    1578819000,
    8125.87282736,
    1,
    56.07564922932597
  ],
  [
    1578905400,
    8111.19975466,
    1,
    56.57939385228602
  ],
  [
    1578991800,
    8509.97630736,
    1,
    57.02256181962226
  ],
  [
    1579078200,
    8642.3610828,
    1,
    53.33437618726417
  ],
  [
    1579164600,
    8687.52842593,
    1,
    53.856590601996665
  ],
  [
    1579251000,
    8939.08583954,
    1,
    52.09077471892088
  ],
  [
    1579337400,
    8855.50398794,
    1,
    52.56172096889036
  ],
  [
    1579423800,
    9084.08308749,
    1,
    51.54150810537518
  ],
  [
    1579510200,
    8617.68339975,
    1,
    52.256579151507665
  ],
  [
    1579596600,
    8638.19275758,
    1,
    51.799796744951685
  ],
  [
    1579683000,
    8668.91054113,
    1,
    51.428907582755464
  ],
  [
    1579769400,
    8511.65183045,
    1,
    51.96571098283556
  ],
  [
    1579855800,
    8320.76966181,
    1,
    52.43160769709272
  ],
  [
    1579942200,
    8367.42758268,
    1,
    51.98092754377856
  ],
  [
    1580028600,
    8434.22123413,
    1,
    51.934592071503296
  ],
  [
    1580115000,
    8589.21634995,
    1,
    51.828427054606315
  ],
  [
    1580201400,
    9012.50650316,
    1,
    52.50715208875662
  ],
  [
    1580287800,
    9360.97270667,
    1,
    52.58989997713771
  ],
  [
    1580374200,
    9352.38753722,
    1,
    53.294997975218195
  ],
  [
    1580460600,
    9386.67786301,
    1,
    51.350271534136
  ],
  [
    1580547000,
    9390.0026122,
    1,
    51.80782927014122
  ],
  [
    1580633400,
    9377.91158576,
    1,
    50.054845361127875
  ],
  [
    1580719800,
    9376.65777339,
    1,
    49.29473847931764
  ],
  [
    1580806200,
    9260.7757602,
    1,
    49.28476796549196
  ],
  [
    1580892600,
    9240.63683358,
    1,
    48.395549608038124
  ],
  [
    1580979000,
    9654.74929297,
    1,
    46.065441145230714
  ],
  [
    1581065400,
    9772.86882621,
    1,
    44.86693878184638
  ],
  [
    1581151800,
    9812.04549119,
    1,
    43.49928832206986
  ],
  [
    1581238200,
    10108.5739892,
    1,
    44.18595435756437
  ],
  [
    1581324600,
    9880.69443142,
    1,
    44.85442334588329
  ],
  [
    1581411000,
    9762.05509478,
    1,
    44.49765322264858
  ],
  [
    1581497400,
    10265.80802974,
    1,
    40.80122381733571
  ],
  [
    1581583800,
    10232.27195002,
    1,
    39.17313518656342
  ],
  [
    1581670200,
    10138.79396041,
    1,
    38.34354329649761
  ],
  [
    1581756600,
    10248.2013159,
    1,
    36.302495476017015
  ],
  [
    1581843000,
    10015.15036138,
    1,
    36.82387606035451
  ],
  [
    1581929400,
    9791.87259066,
    1,
    39.01489451598701
  ],
  [
    1582015800,
    9771.45971678,
    1,
    36.478841184447106
  ],
  [
    1582102200,
    10144.94482424,
    1,
    36.02650679330545
  ],
  [
    1582188600,
    9566.89465583,
    1,
    37.287938940746784
  ],
  [
    1582275000,
    9633.30476382,
    1,
    37.24006594619603
  ],
  [
    1582361400,
    9635.60235956,
    1,
    36.99497746689654
  ],
  [
    1582447800,
    9881.87211324,
    1,
    36.50834324111824
  ],
  [
    1582534200,
    9760.04951867,
    1,
    36.4706938210197
  ],
  [
    1582620600,
    9562.72132361,
    1,
    36.908142910064754
  ],
  [
    1582707000,
    9183.91525656,
    1,
    38.58302763885596
  ],
  [
    1582793400,
    8833.90650775,
    1,
    38.41198484966636
  ],
  [
    1582879800,
    8720.43408304,
    1,
    38.84060078009358
  ],
  [
    1582966200,
    8734.02504706,
    1,
    38.269352039220834
  ],
  [
    1583052600,
    8547.76852714,
    1,
    38.71910072158434
  ],
  [
    1583139000,
    8657.46154723,
    1,
    39.08180218301218
  ],
  [
    1583225400,
    8783.89280181,
    1,
    38.55893250923776
  ],
  [
    1583311800,
    8817.23932988,
    1,
    38.94255336808882
  ],
  [
    1583398200,
    8915.21419944,
    1,
    39.08112312209908
  ],
  [
    1583484600,
    9106.63734776,
    1,
    38.690429309454345
  ],
  [
    1583571000,
    9105.94119472,
    1,
    37.2819386634647
  ],
  [
    1583657400,
    8729.1110776,
    1,
    38.05620508289735
  ],
  [
    1583743800,
    7985.1279136,
    1,
    38.478528869027045
  ],
  [
    1583830200,
    7903.19573848,
    1,
    39.67851366492223
  ],
  [
    1583916600,
    7828.845591,
    1,
    39.41664825579863
  ],
  [
    1584003000,
    7389.42371485,
    1,
    43.678697526120104
  ],
  [
    1584089400,
    5221.15845854,
    1,
    42.54640920445974
  ],
  [
    1584175800,
    5350.62090936,
    1,
    41.65921603909957
  ],
  [
    1584262200,
    5236.78179342,
    1,
    42.788743140218145
  ],
  [
    1584348600,
    4887.51873376,
    1,
    44.522740652153956
  ],
  [
    1584435000,
    5295.28863034,
    1,
    45.17530449461686
  ],
  [
    1584521400,
    5171.6575546,
    1,
    45.84415783339567
  ],
  [
    1584607800,
    5401.38957096,
    1,
    45.49279948224859
  ],
  [
    1584694200,
    6633.26097352,
    1,
    45.09466998691929
  ],
  [
    1584780600,
    6152.81380556,
    1,
    46.68537912559812
  ],
  [
    1584867000,
    6247.89490438,
    1,
    47.11119767657792
  ],
  [
    1584953400,
    5792.58160929,
    1,
    47.32520146265827
  ],
  [
    1585039800,
    6704.7533498,
    1,
    48.09082082843808
  ],
  [
    1585126200,
    6722.39973927,
    1,
    48.32867857571408
  ],
  [
    1585212600,
    6596.37743254,
    1,
    48.919700853641274
  ],
  [
    1585299000,
    6694.20119171,
    1,
    48.86712512494159
  ],
  [
    1585385400,
    6252.55309407,
    1,
    48.364918621817075
  ],
  [
    1585471800,
    6125.07848186,
    1,
    47.4087728099906
  ],
  [
    1585558200,
    6272.20817198,
    1,
    48.004202678510126
  ],
  [
    1585644600,
    6485.08544773,
    1,
    48.84404434338182
  ],
  [
    1585731000,
    6313.51721553,
    1,
    47.78213573429132
  ],
  [
    1585817400,
    6652.78561,
    1,
    48.44817198623712
  ],
  [
    1585903800,
    6906.88734739,
    1,
    48.080064825316896
  ],
  [
    1585990200,
    6774.14294546,
    1,
    47.72370360419466
  ],
  [
    1586076600,
    6786.81741463,
    1,
    47.49902059577625
  ],
  [
    1586163000,
    7033.75015793,
    1,
    47.085797004910226
  ],
  [
    1586249400,
    7333.73139304,
    1,
    42.59916247317196
  ],
  [
    1586335800,
    7281.83830265,
    1,
    43.23385069732868
  ],
  [
    1586422200,
    7332.50409212,
    1,
    42.5673847445848
  ],
  [
    1586508600,
    6941.73011547,
    1,
    43.74551869718906
  ],
  [
    1586595000,
    6884.45609948,
    1,
    43.33440401338442
  ],
  [
    1586681400,
    6830.54100257,
    1,
    43.42754586695812
  ],
  [
    1586767800,
    6704.95084325,
    1,
    44.01959217133696
  ],
  [
    1586854200,
    6795.02148037,
    1,
    43.4020060558526
  ],
  [
    1586940600,
    6867.94646772,
    1,
    43.087435127671085
  ],
  [
    1587027000,
    7062.94634804,
    1,
    41.49859124150444
  ],
  [
    1587113400,
    7055.2307319,
    1,
    41.38377297970826
  ],
  [
    1587199800,
    7093.30483554,
    1,
    40.95559491522595
  ],
  [
    1587286200,
    7150.31292631,
    1,
    39.1431824008893
  ],
  [
    1587372600,
    7153.44775727,
    1,
    39.25384386726935
  ],
  [
    1587459000,
    6857.19220192,
    1,
    39.93047823961345
  ],
  [
    1587545400,
    6949.12954557,
    1,
    39.678648346958084
  ],
  [
    1587631800,
    7087.8830539,
    1,
    39.130461160940975
  ],
  [
    1587718200,
    7545.33098622,
    1,
    40.187676184211554
  ],
  [
    1587804600,
    7532.67564646,
    1,
    38.99423039932374
  ],
  [
    1587891000,
    7554.06663789,
    1,
    38.98758102840928
  ],
  [
    1587977400,
    7699.01702336,
    1,
    39.325753318207454
  ],
  [
    1588063800,
    7692.51974038,
    1,
    39.61992483652143
  ],
  [
    1588150200,
    7935.0651897,
    1,
    38.70683093635054
  ],
  [
    1588236600,
    9160.27420453,
    1,
    42.08591696917222
  ],
  [
    1588323000,
    8788.44365838,
    1,
    41.29721482791924
  ],
  [
    1588409400,
    8788.66246088,
    1,
    41.67226193649744
  ],
  [
    1588495800,
    9052.92867892,
    1,
    42.1503086719823
  ],
  [
    1588582200,
    8678.91851954,
    1,
    43.20672569295482
  ],
  [
    1588668600,
    9016.8313564,
    1,
    43.12743392374496
  ],
  [
    1588755000,
    9043.51764437,
    1,
    43.652576799596396
  ],
  [
    1588841400,
    9328.22086038,
    1,
    45.29910208806933
  ],
  [
    1588927800,
    9862.7307862,
    1,
    46.93439595730358
  ],
  [
    1589014200,
    9660.29494336,
    1,
    45.67280316690745
  ],
  [
    1589100600,
    8837.06950419,
    1,
    46.205881877346876
  ],
  [
    1589187000,
    8638.51411642,
    1,
    46.31738233672299
  ],
  [
    1589273400,
    8742.53903206,
    1,
    46.12486670339053
  ],
  [
    1589359800,
    8909.44450816,
    1,
    46.88074277730786
  ],
  [
    1589446200,
    9619.90421778,
    1,
    47.680505195454444
  ],
  [
    1589532600,
    9660.54072989,
    1,
    48.16727073725556
  ],
  [
    1589619000,
    9409.52320915,
    1,
    46.98805279183573
  ],
  [
    1589705400,
    9503.72625552,
    1,
    47.19752900346481
  ],
  [
    1589791800,
    9530.08509356,
    1,
    45.00405127106997
  ],
  [
    1589878200,
    9804.75777974,
    1,
    45.72006627752745
  ],
  [
    1589964600,
    9777.87956581,
    1,
    45.669951929256676
  ],
  [
    1590051000,
    9345.47877405,
    1,
    45.3943385296075
  ],
  [
    1590137400,
    9087.33538201,
    1,
    45.10483916943609
  ],
  [
    1590223800,
    9208.24844991,
    1,
    44.3407814635154
  ],
  [
    1590310200,
    9258.83886928,
    1,
    44.14062373404348
  ],
  [
    1590396600,
    8844.84123276,
    1,
    43.47542499117239
  ],
  [
    1590483000,
    8971.0027503,
    1,
    44.077183643262444
  ],
  [
    1590569400,
    8921.67316107,
    1,
    43.76478954353812
  ],
  [
    1590655800,
    9176.53728347,
    1,
    44.53664264460275
  ],
  [
    1590742200,
    9473.64528343,
    1,
    42.94570084520795
  ],
  [
    1590828600,
    9545.97660975,
    1,
    40.943621899778975
  ],
  [
    1590915000,
    9537.31458854,
    1,
    40.16945556324265
  ],
  [
    1591001400,
    9535.21685572,
    1,
    40.27350868330941
  ],
  [
    1591087800,
    10110.97316188,
    1,
    40.66188949968023
  ],
  [
    1591174200,
    9517.54977632,
    1,
    40.165737933236535
  ],
  [
    1591260600,
    9641.37124826,
    1,
    39.565204215742064
  ],
  [
    1591347000,
    9833.69114267,
    1,
    39.8653563032555
  ],
  [
    1591433400,
    9596.94031756,
    1,
    39.989607150913535
  ],
  [
    1591519800,
    9657.47578111,
    1,
    39.93096838397446
  ],
  [
    1591606200,
    9690.65365588,
    1,
    39.83646219805969
  ],
  [
    1591692600,
    9679.94582791,
    1,
    39.83231631565734
  ],
  [
    1591779000,
    9748.03617865,
    1,
    40.03192390616412
  ],
  [
    1591865400,
    9819.32475588,
    1,
    39.7807571171016
  ],
  [
    1591951800,
    9450.9590411,
    1,
    39.98044957568736
  ],
  [
    1592038200,
    9438.37821688,
    1,
    39.92489256445981
  ],
  [
    1592124600,
    9426.81220681,
    1,
    39.97526836128371
  ],
  [
    1592211000,
    9144.957204,
    1,
    40.7558216098375
  ],
  [
    1592297400,
    9481.9725052,
    1,
    40.774913649127754
  ],
  [
    1592383800,
    9506.67561544,
    1,
    40.53372168088161
  ],
  [
    1592470200,
    9435.74869525,
    1,
    40.44951124826616
  ],
  [
    1592556600,
    9316.28584138,
    1,
    40.678929173896236
  ],
  [
    1592643000,
    9287.67019978,
    1,
    40.599976987869525
  ],
  [
    1592729400,
    9361.9781248,
    1,
    40.6565667566456
  ],
  [
    1592815800,
    9419.87318784,
    1,
    40.02358809699306
  ],
  [
    1592902200,
    9617.81798521,
    1,
    39.61349392443937
  ],
  [
    1592988600,
    9537.03585708,
    1,
    39.210618741167096
  ],
  [
    1593075000,
    9301.18483053,
    1,
    39.65841586237147
  ],
  [
    1593161400,
    9140.90912498,
    1,
    39.794262963738554
  ],
  [
    1593247800,
    9171.15071408,
    1,
    39.78581940766632
  ],
  [
    1593334200,
    9067.79063801,
    1,
    40.86734781842811
  ],
  [
    1593420600,
    9084.50965485,
    1,
    40.72714975072947
  ],
  [
    1593507000,
    9135.67121142,
    1,
    40.37486023096779
  ],
  [
    1593593400,
    9180.62289818,
    1,
    40.232214090627195
  ],
  [
    1593679800,
    9196.77754083,
    1,
    40.255564722678415
  ],
  [
    1593766200,
    9099.73507804,
    1,
    40.09075515955135
  ],
  [
    1593852600,
    9075.37182102,
    1,
    40.123976290891356
  ],
  [
    1593939000,
    9071.15014471,
    1,
    39.96071516900693
  ],
  [
    1594025400,
    9185.80290547,
    1,
    39.450351762419494
  ],
  [
    1594111800,
    9265.04008105,
    1,
    39.00758852853667
  ],
  [
    1594198200,
    9277.85796769,
    1,
    38.56288343785797
  ],
  [
    1594284600,
    9401.15668663,
    1,
    38.143198041073234
  ],
  [
    1594371000,
    9188.00362821,
    1,
    38.34233598122091
  ],
  [
    1594457400,
    9266.84423129,
    1,
    38.543041146736336
  ],
  [
    1594543800,
    9256.55615916,
    1,
    38.52960401362319
  ],
  [
    1594630200,
    9276.474764,
    1,
    38.04793603435763
  ],
  [
    1594716600,
    9198.26663505,
    1,
    38.54501349607883
  ],
  [
    1594803000,
    9219.23473203,
    1,
    38.5053796000978
  ],
  [
    1594889400,
    9112.38394278,
    1,
    39.21793780515555
  ],
  [
    1594975800,
    9102.71228992,
    1,
    39.1018475753923
  ],
  [
    1595062200,
    9128.97610112,
    1,
    39.12041646776548
  ],
  [
    1595148600,
    9150.93456598,
    1,
    39.00284106095022
  ],
  [
    1595235000,
    9184.49407025,
    1,
    38.462093818746105
  ],
  [
    1595321400,
    9318.92079385,
    1,
    38.56651446976468
  ],
  [
    1595407800,
    9318.86962998,
    1,
    38.403059894029845
  ],
  [
    1595494200,
    9518.57015904,
    1,
    36.064361298683245
  ],
  [
    1595580600,
    9506.44995085,
    1,
    35.13647559587684
  ],
  [
    1595667000,
    9600.59365962,
    1,
    33.74030763742664
  ],
  [
    1595753400,
    9751.3035824,
    1,
    30.73975138280648
  ],
  [
    1595839800,
    10150.86305333,
    1,
    31.67956718743717
  ],
  [
    1595926200,
    10793.68895116,
    1,
    34.27403272427796
  ],
  [
    1596012600,
    11087.60468017,
    1,
    34.243836942444254
  ],
  [
    1596099000,
    10975.0295441,
    1,
    34.38385152837958
  ],
  [
    1596185400,
    11144.04238924,
    1,
    32.954699857675216
  ],
  [
    1596271800,
    11671.24907895,
    1,
    32.74090752590704
  ],
  [
    1596358200,
    11288.90517884,
    1,
    29.828241678718268
  ],
  [
    1596444600,
    11204.10984859,
    1,
    29.233388665570086
  ],
  [
    1596531000,
    11279.08392615,
    1,
    28.612923376641003
  ],
  [
    1596617400,
    11278.63162674,
    1,
    28.961044838513327
  ],
  [
    1596703800,
    11699.88272492,
    1,
    29.51404854398239
  ],
  [
    1596790200,
    11828.15756251,
    1,
    29.908075146308644
  ],
  [
    1596876600,
    11593.72721016,
    1,
    30.597080003087505
  ],
  [
    1596963000,
    11671.47720188,
    1,
    29.611739283366337
  ],
  [
    1597049400,
    11986.35974461,
    1,
    30.27516391656681
  ],
  [
    1597135800,
    11733.98856944,
    1,
    30.09234537997077
  ],
  [
    1597222200,
    11432.97931475,
    1,
    30.214017901922038
  ],
  [
    1597308600,
    11418.1190427,
    1,
    29.761589112343987
  ],
  [
    1597395000,
    11684.01396551,
    1,
    27.662270438599766
  ],
  [
    1597481400,
    11852.31778916,
    1,
    27.420072050391564
  ],
  [
    1597567800,
    11854.67136525,
    1,
    27.75300903269813
  ],
  [
    1597654200,
    11852.36076717,
    1,
    27.826567260908806
  ],
  [
    1597740600,
    12259.40159842,
    1,
    28.50078133605292
  ],
  [
    1597827000,
    11797.53748656,
    1,
    28.636001105688624
  ],
  [
    1597913400,
    11766.81161283,
    1,
    28.9333461435088
  ],
  [
    1597999800,
    11807.99830214,
    1,
    28.821754101910283
  ],
  [
    1598086200,
    11585.6295947,
    1,
    29.445342878128066
  ],
  [
    1598172600,
    11585.93268542,
    1,
    29.71116704678632
  ],
  [
    1598259000,
    11769.71673492,
    1,
    29.129986799703513
  ],
  [
    1598345400,
    11585.34751768,
    1,
    29.31646701315615
  ],
  [
    1598431800,
    11337.85109494,
    1,
    29.78973302391556
  ],
  [
    1598518200,
    11381.82112802,
    1,
    29.658935193555138
  ],
  [
    1598604600,
    11404.22915003,
    1,
    29.37537536283494
  ],
  [
    1598691000,
    11541.48596404,
    1,
    28.726319914888894
  ],
  [
    1598777400,
    11563.97328744,
    1,
    28.61557732332104
  ],
  [
    1598863800,
    11600.48486998,
    1,
    27.590544046339446
  ],
  [
    1598950200,
    11908.3592659,
    1,
    25.854286191757943
  ],
  [
    1599036600,
    11687.92578748,
    1,
    25.04956437653531
  ],
  [
    1599123000,
    11372.52292104,
    1,
    26.129643628052886
  ],
  [
    1599209400,
    10421.82014442,
    1,
    26.314355284010603
  ],
  [
    1599295800,
    10385.06647891,
    1,
    27.533741806233134
  ],
  [
    1599382200,
    10243.10844949,
    1,
    29.3838402768548
  ],
  [
    1599468600,
    10157.70914075,
    1,
    29.24721188822521
  ],
  [
    1599555000,
    10184.15084996,
    1,
    29.62254649649162
  ],
  [
    1599641400,
    10166.54421301,
    1,
    29.817481533092316
  ],
  [
    1599727800,
    10289.26210325,
    1,
    28.103023547302147
  ],
  [
    1599814200,
    10272.40242293,
    1,
    28.20970867995546
  ],
  [
    1599900600,
    10334.74888625,
    1,
    28.113752648425887
  ],
  [
    1599987000,
    10537.89145151,
    1,
    27.343974720043892
  ],
  [
    1600073400,
    10387.70531696,
    1,
    28.35629945902513
  ],
  [
    1600159800,
    10642.49108626,
    1,
    28.739213955118238
  ],
  [
    1600246200,
    10888.55431253,
    1,
    29.915572505934257
  ],
  [
    1600332600,
    10881.55728577,
    1,
    28.690024618445545
  ],
  [
    1600419000,
    10953.90566765,
    1,
    28.35647447893248
  ],
  [
    1600505400,
    10924.35641414,
    1,
    28.80270232331045
  ],
  [
    1600591800,
    10962.65598705,
    1,
    28.810584392961545
  ],
  [
    1600678200,
    10850.66367139,
    1,
    29.547930556950963
  ],
  [
    1600764600,
    10455.1661521,
    1,
    30.703256826886864
  ],
  [
    1600851000,
    10443.33311203,
    1,
    31.063776160605034
  ],
  [
    1600937400,
    10321.05299412,
    1,
    31.640750762969272
  ],
  [
    1601023800,
    10623.17156462,
    1,
    31.32131075374838
  ],
  [
    1601110200,
    10677.71547045,
    1,
    30.49436474166296
  ],
  [
    1601196600,
    10648.35016974,
    1,
    30.220578952719478
  ],
  [
    1601283000,
    10877.67855139,
    1,
    30.305131634201366
  ],
  [
    1601369400,
    10735.08858633,
    1,
    30.225806863228534
  ],
  [
    1601455800,
    10723.60326956,
    1,
    30.127257103175342
  ],
  [
    1601542200,
    10894.08565949,
    1,
    29.628934164343757
  ],
  [
    1601628600,
    10462.8673151,
    1,
    30.660204053470125
  ],
  [
    1601715000,
    10519.94630134,
    1,
    30.36500680744093
  ],
  [
    1601801400,
    10594.82694912,
    1,
    30.47030261971273
  ],
  [
    1601887800,
    10676.75966832,
    1,
    30.45942603120692
  ],
  [
    1601974200,
    10720.36250914,
    1,
    30.51213480572745
  ],
  [
    1602060600,
    10608.25825049,
    1,
    31.213627749195652
  ],
  [
    1602147000,
    10588.64065723,
    1,
    31.494889750336743
  ],
  [
    1602233400,
    10884.9237337,
    1,
    31.059415749679353
  ],
  [
    1602319800,
    11392.25717436,
    1,
    30.299282109915218
  ],
  [
    1602406200,
    11359.23825877,
    1,
    30.46372393311724
  ],
  [
    1602492600,
    11375.40689327,
    1,
    30.386928695697186
  ],
  [
    1602579000,
    11484.91052417,
    1,
    29.90821543461542
  ],
  [
    1602665400,
    11444.96494358,
    1,
    29.89010085175963
  ],
  [
    1602751800,
    11357.00483643,
    1,
    30.173689062977072
  ],
  [
    1602838200,
    11315.51581976,
    1,
    30.66083102882856
  ],
  [
    1602924600,
    11339.19316173,
    1,
    30.85068479304591
  ],
  [
    1603011000,
    11428.6385007,
    1,
    30.60260397490273
  ],
  [
    1603097400,
    11459.2972947,
    1,
    30.537572138517262
  ],
  [
    1603183800,
    11750.04074226,
    1,
    31.33694928426127
  ],
  [
    1603270200,
    12162.2196041,
    1,
    32.164545018518254
  ],
  [
    1603356600,
    12849.75566222,
    1,
    32.20104859638145
  ],
  [
    1603443000,
    12929.63093245,
    1,
    31.206592049098642
  ],
  [
    1603529400,
    12925.84201613,
    1,
    31.577793307755
  ],
  [
    1603615800,
    13021.00834598,
    1,
    31.81010940013216
  ],
  [
    1603702200,
    13026.33874054,
    1,
    32.09641696815687
  ],
  [
    1603788600,
    13156.35586146,
    1,
    33.395711601662086
  ],
  [
    1603875000,
    13663.15923931,
    1,
    34.47895140660195
  ],
  [
    1603961400,
    13155.30650472,
    1,
    33.95102373758191
  ],
  [
    1604047800,
    13262.25713652,
    1,
    34.96738298997958
  ],
  [
    1604134200,
    13749.80034713,
    1,
    35.49396545878382
  ],
  [
    1604220600,
    13727.90842464,
    1,
    35.45341295906309
  ],
  [
    1604307000,
    13484.80360102,
    1,
    34.83814182528156
  ],
  [
    1604393400,
    13511.47903298,
    1,
    35.73086482024731
  ],
  [
    1604479800,
    13640.04208425,
    1,
    35.84997779848607
  ],
  [
    1604566200,
    14401.57282507,
    1,
    36.07074698944596
  ],
  [
    1604652600,
    15561.38964284,
    1,
    36.11020020521657
  ],
  [
    1604739000,
    15541.05145641,
    1,
    33.616902932110655
  ],
  [
    1604825400,
    14961.43517083,
    1,
    34.203479706582975
  ],
  [
    1604911800,
    15385.74320006,
    1,
    34.36273362465823
  ],
  [
    1604998200,
    15428.11863608,
    1,
    34.04109090333688
  ],
  [
    1605084600,
    15372.73778039,
    1,
    33.44036206202917
  ],
  [
    1605171000,
    15837.27732406,
    1,
    34.10131616339865
  ],
  [
    1605257400,
    16218.99610536,
    1,
    35.051868924285685
  ],
  [
    1605343800,
    15935.40023493,
    1,
    34.48459849106278
  ],
  [
    1605430200,
    16093.09438091,
    1,
    34.93584469916155
  ],
  [
    1605516600,
    16219.34489739,
    1,
    35.70858603623575
  ],
  [
    1605603000,
    16703.60257785,
    1,
    35.8969893183888
  ],
  [
    1605689400,
    18200.48314191,
    1,
    37.95466394244552
  ],
  [
    1605775800,
    17445.70019996,
    1,
    37.29794588352414
  ],
  [
    1605862200,
    18266.36957477,
    1,
    36.62639582652209
  ],
  [
    1605948600,
    18730.18355381,
    1,
    36.40685784431813
  ],
  [
    1606035000,
    18309.38922913,
    1,
    34.132004545204616
  ],
  [
    1606121400,
    18596.71814204,
    1,
    31.85626431050814
  ],
  [
    1606207800,
    18528.93036094,
    1,
    29.998995140530614
  ],
  [
    1606294200,
    19136.26203789,
    1,
    32.09090528643508
  ],
  [
    1606380600,
    16713.40082024,
    1,
    33.93078528012771
  ],
  [
    1606467000,
    16832.91180875,
    1,
    33.114865596774
  ],
  [
    1606553400,
    16920.27049284,
    1,
    33.178209320639716
  ],
  [
    1606639800,
    18095.90418928,
    1,
    32.87315081543516
  ],
  [
    1606726200,
    18495.06423751,
    1,
    32.02457759421649
  ],
  [
    1606812600,
    19517.17437677,
    1,
    31.94947381845465
  ],
  [
    1606899000,
    19248.3793452,
    1,
    32.232718887376194
  ],
  [
    1606985400,
    19389.93688158,
    1,
    32.06526743486453
  ],
  [
    1607071800,
    19417.6388928,
    1,
    31.74343772839589
  ],
  [
    1607158200,
    19119.48613759,
    1,
    32.28355820263886
  ],
  [
    1607244600,
    19079.65024271,
    1,
    32.107657866038465
  ],
  [
    1607331000,
    19247.02773559,
    1,
    32.2887110055556
  ],
  [
    1607417400,
    19075.45964815,
    1,
    32.66705212037888
  ],
  [
    1607503800,
    17868.64608775,
    1,
    33.198959270128135
  ],
  [
    1607590200,
    18436.95573982,
    1,
    32.568394220588196
  ],
  [
    1607676600,
    17843.644305,
    1,
    32.850695125093274
  ],
  [
    1607763000,
    18354.15129726,
    1,
    32.96957613927964
  ],
  [
    1607849400,
    19223.11610432,
    1,
    33.17428921788599
  ],
  [
    1607935800,
    19209.62767916,
    1,
    32.69831935546881
  ],
  [
    1608022200,
    19155.23549385,
    1,
    32.82251715653775
  ],
  [
    1608108600,
    19459.75704245,
    1,
    33.167034241665995
  ],
  [
    1608195000,
    22764.15789336,
    1,
    35.24402121859644
  ],
  [
    1608281400,
    23108.51791804,
    1,
    35.50023835003006
  ],
  [
    1608367800,
    22906.05404352,
    1,
    35.19051425272229
  ],
  [
    1608454200,
    23751.94946265,
    1,
    36.13153167156398
  ],
  [
    1608540600,
    23768.64017575,
    1,
    37.1745520419134
  ],
  [
    1608627000,
    22640.2123995,
    1,
    37.98057286555221
  ],
  [
    1608713400,
    23506.46943733,
    1,
    38.32686612829735
  ],
  [
    1608799800,
    23279.12518562,
    1,
    40.097134409086614
  ],
  [
    1608886200,
    23717.28443933,
    1,
    38.43666114378035
  ],
  [
    1608972600,
    24901.874499,
    1,
    39.64323229327014
  ],
  [
    1609059000,
    27877.91992564,
    1,
    42.76770459636666
  ],
  [
    1609145400,
    26865.27798942,
    1,
    36.581789532023585
  ],
  [
    1609231800,
    26677.81983222,
    1,
    37.256099192965316
  ],
  [
    1609318200,
    27740.55887268,
    1,
    37.96681879621125
  ],
  [
    1609404600,
    28940.42575957,
    1,
    38.85215390128988
  ],
  [
    1609491000,
    29020.56561168,
    1,
    39.79889598128335
  ],
  [
    1609577400,
    29812.97253324,
    1,
    40.64588946221289
  ],
  [
    1609663800,
    34430.9495777,
    1,
    42.4564185714091
  ],
  [
    1609750200,
    31843.2759152,
    1,
    31.3994232623709
  ],
  [
    1609836600,
    31081.08773586,
    1,
    30.356129012081418
  ],
  [
    1609923000,
    34478.69666463,
    1,
    31.26817326879313
  ],
  [
    1610009400,
    37307.74709215,
    1,
    31.209969742089854
  ],
  [
    1610095800,
    38525.43247849,
    1,
    32.34984862223234
  ],
  [
    1610182200,
    40157.48962425,
    1,
    33.220163077751465
  ],
  [
    1610268600,
    40780.28226212,
    1,
    30.720005495731474
  ],
  [
    1610355000,
    35746.27197647,
    1,
    31.906508030499488
  ],
  [
    1610441400,
    35763.65164302,
    1,
    32.10770860858956
  ],
  [
    1610527800,
    34715.05203812,
    1,
    32.47885278903114
  ],
  [
    1610614200,
    38545.182586,
    1,
    33.077129528642764
  ],
  [
    1610700600,
    38607.39879707,
    1,
    31.40972694355942
  ],
  [
    1610787000,
    37252.91426089,
    1,
    30.784389681140386
  ],
  [
    1610873400,
    34428.69583017,
    1,
    29.05682922501186
  ],
  [
    1610959800,
    36296.60097219,
    1,
    29.694401674132347
  ],
  [
    1611046200,
    37319.24568059,
    1,
    27.00142240507063
  ],
  [
    1611132600,
    35588.71111259,
    1,
    26.21208927696314
  ],
  [
    1611219000,
    33792.2438552,
    1,
    26.429988571970735
  ],
  [
    1611305400,
    30775.33700206,
    1,
    26.883407484566174
  ],
  [
    1611391800,
    32742.63026845,
    1,
    25.947794402743714
  ],
  [
    1611478200,
    32630.3249913,
    1,
    24.706823158467348
  ],
  [
    1611564600,
    33395.73666435,
    1,
    23.58238162434742
  ],
  [
    1611651000,
    31780.33595605,
    1,
    23.943957123294748
  ],
  [
    1611737400,
    31353.91574096,
    1,
    24.63170257356448
  ],
  [
    1611823800,
    31192.06005061,
    1,
    24.257423850465624
  ],
  [
    1611910200,
    34114.1694775,
    1,
    25.5444447624154
  ],
  [
    1611996600,
    33945.61385908,
    1,
    25.32185923877505
  ],
  [
    1612083000,
    33532.6522332,
    1,
    24.918428657165737
  ],
  [
    1612169400,
    34093.01971158,
    1,
    25.812585868739177
  ],
  [
    1612255800,
    34307.08985332,
    1,
    24.13512763990755
  ],
  [
    1612342200,
    36068.71395284,
    1,
    23.50888745368031
  ],
  [
    1612428600,
    38123.44466946,
    1,
    22.78738276180888
  ],
  [
    1612515000,
    37671.99541556,
    1,
    22.667137314169683
  ],
  [
    1612601400,
    39431.56791442,
    1,
    23.20875920928897
  ],
  [
    1612687800,
    39370.16881414,
    1,
    24.039916374774034
  ],
  [
    1612774200,
    39137.5304603,
    1,
    23.969245030218588
  ],
  [
    1612860600,
    47088.0887726,
    1,
    26.151784733175827
  ],
  [
    1612947000,
    46546.53362588,
    1,
    25.823986619825952
  ],
  [
    1613033400,
    45022.03934135,
    1,
    25.73128007789983
  ],
  [
    1613119800,
    47295.54298704,
    1,
    26.75072663055779
  ],
  [
    1613206200,
    47410.74692415,
    1,
    25.985061886430547
  ],
  [
    1613292600,
    48638.78098469,
    1,
    26.556082662347443
  ],
  [
    1613379000,
    47478.1558347,
    1,
    26.979595332179713
  ],
  [
    1613465400,
    49064.35649964,
    1,
    27.2193057575528
  ],
  [
    1613551800,
    51012.82869403,
    1,
    28.315213416861166
  ],
  [
    1613638200,
    51883.46672775,
    1,
    27.372503959568633
  ],
  [
    1613724600,
    51999.33721361,
    1,
    27.11842503782105
  ],
  [
    1613811000,
    55289.83103482,
    1,
    27.643159265263478
  ],
  [
    1613897400,
    56618.80075632,
    1,
    29.178261401478785
  ],
  [
    1613983800,
    55868.34749205,
    1,
    29.84546419318167
  ],
  [
    1614070200,
    47998.6991325,
    1,
    31.893359363571385
  ],
  [
    1614156600,
    50441.68881214,
    1,
    30.322278170712938
  ],
  [
    1614243000,
    50023.57528941,
    1,
    30.996504604484166
  ],
  [
    1614329400,
    45743.78771992,
    1,
    31.44579837662852
  ],
  [
    1614415800,
    46632.032363,
    1,
    31.63865589399385
  ],
  [
    1614502200,
    44840.7068248,
    1,
    33.023292828748914
  ],
  [
    1614588600,
    47177.5148231,
    1,
    32.21982874742215
  ],
  [
    1614675000,
    49189.49663666,
    1,
    31.469224725947
  ],
  [
    1614761400,
    50635.40293402,
    1,
    31.965482684683654
  ],
  [
    1614847800,
    50317.62107158,
    1,
    31.92777304160256
  ],
  [
    1614934200,
    46833.88643652,
    1,
    32.07020031116106
  ],
  [
    1615020600,
    48720.58678229,
    1,
    30.833456307851364
  ],
  [
    1615107000,
    50494.78886564,
    1,
    30.156883251644242
  ],
  [
    1615193400,
    49679.94632761,
    1,
    29.624163552058672
  ],
  [
    1615279800,
    53950.6883485,
    1,
    29.513839865139396
  ],
  [
    1615366200,
    54677.29948217,
    1,
    30.070477462011226
  ],
  [
    1615452600,
    54913.61618952,
    1,
    31.392585401820888
  ],
  [
    1615539000,
    57024.08151915,
    1,
    31.629424176812574
  ],
  [
    1615625400,
    57380.17094601,
    1,
    32.1602940884211
  ],
  [
    1615711800,
    60713.0471361,
    1,
    32.01316705389708
  ],
  [
    1615798200,
    58019.80003469,
    1,
    32.261325442697505
  ],
  [
    1615884600,
    55878.52072349,
    1,
    30.90461440974176
  ],
  [
    1615971000,
    55860.79077225,
    1,
    31.274224730329124
  ],
  [
    1616057400,
    58079.30709296,
    1,
    32.10872244434011
  ],
  [
    1616143800,
    58195.60741202,
    1,
    32.26240991357069
  ],
  [
    1616230200,
    58735.44371675,
    1,
    32.13782032853791
  ],
  [
    1616316600,
    57231.88722838,
    1,
    32.08711960001595
  ],
  [
    1616403000,
    57290.41147534,
    1,
    32.13995778644708
  ],
  [
    1616489400,
    54399.34225157,
    1,
    32.34571047770873
  ],
  [
    1616575800,
    56187.27705734,
    1,
    32.83549925918736
  ],
  [
    1616662200,
    52619.11503919,
    1,
    32.92635572802111
  ],
  [
    1616748600,
    53373.89882539,
    1,
    32.75871285608943
  ],
  [
    1616835000,
    54874.59681033,
    1,
    32.43620436259835
  ],
  [
    1616921400,
    56222.89833791,
    1,
    32.746448048942
  ],
  [
    1617007800,
    56920.38889132,
    1,
    32.977748590706526
  ],
  [
    1617094200,
    57997.03602778,
    1,
    31.91972310910031
  ],
  [
    1617180600,
    57817.93672551,
    1,
    32.10016453487659
  ],
  [
    1617267000,
    59153.42855592,
    1,
    30.276543987500276
  ],
  [
    1617353400,
    59477.91240305,
    1,
    29.82416878151978
  ],
  [
    1617439800,
    59070.2678785,
    1,
    28.12692198587924
  ],
  [
    1617526200,
    57518.96547313,
    1,
    28.194981972901587
  ],
  [
    1617612600,
    57150.98959047,
    1,
    28.199669535701545
  ],
  [
    1617699000,
    58513.77637411,
    1,
    28.002184230722182
  ],
  [
    1617785400,
    57736.5782232,
    1,
    27.965939761971008
  ],
  [
    1617871800,
    56895.17789089,
    1,
    28.233619225371832
  ],
  [
    1617958200,
    57875.06039496,
    1,
    27.939375152786194
  ],
  [
    1618044600,
    60514.21085819,
    1,
    27.96645685398771
  ],
  [
    1618131000,
    60020.74181731,
    1,
    28.005512949317392
  ],
  [
    1618217400,
    60648.00121838,
    1,
    27.77480702524426
  ],
  [
    1618303800,
    62403.78053005,
    1,
    28.253670508437757
  ],
  [
    1618390200,
    63731.32381172,
    1,
    27.239465065049718
  ],
  [
    1618476600,
    62763.81690163,
    1,
    25.628550625412164
  ],
  [
    1618563000,
    61382.13538367,
    1,
    25.226973376902176
  ],
  [
    1618649400,
    62312.29387408,
    1,
    25.25412813691376
  ],
  [
    1618735800,
    55639.53546253,
    1,
    25.788481338985353
  ],
  [
    1618822200,
    56929.95694897,
    1,
    25.33486718518775
  ],
  [
    1618908600,
    55146.48687701,
    1,
    25.68990583071149
  ],
  [
    1618995000,
    55400.14504664,
    1,
    24.170616458110548
  ],
  [
    1619081400,
    53582.30750183,
    1,
    22.01131101507609
  ],
  [
    1619167800,
    48311.60155115,
    1,
    22.14443516247137
  ],
  [
    1619254200,
    50128.85083608,
    1,
    21.843933034981337
  ],
  [
    1619340600,
    50131.9098806,
    1,
    22.477437217306484
  ],
  [
    1619427001,
    52706.07609998,
    1,
    21.608511627708218
  ],
  [
    1619513400,
    54587.20183196,
    1,
    21.49124652588993
  ],
  [
    1619599800,
    54316.6542192,
    1,
    20.772610926825248
  ],
  [
    1619686200,
    53964.76016516,
    1,
    19.83886409425897
  ],
  [
    1619772600,
    54584.04064104,
    1,
    19.608712519232142
  ],
  [
    1619859000,
    57513.85703539,
    1,
    20.2173680523348
  ],
  [
    1619945400,
    56509.61799515,
    1,
    19.645466479667665
  ],
  [
    1620031800,
    58729.07615658,
    1,
    18.431445057852567
  ],
  [
    1620118200,
    55784.63105318,
    1,
    16.903898340799387
  ],
  [
    1620204600,
    55475.8778908,
    1,
    16.551627239181308
  ],
  [
    1620291000,
    57020.28540522,
    1,
    16.552510806583015
  ],
  [
    1620377700,
    55990.520610466796,
    1,
    16.309282505557977
  ],
  [
    1620464100,
    58941.95750494105,
    1,
    16.548624851620467
  ],
  [
    1620550500,
    58203.280592576964,
    1,
    14.827503394570599
  ],
  [
    1620636900,
    58373.14382316671,
    1,
    14.111625015241371
  ],
  [
    1620723300,
    55303.57157958594,
    1,
    14.122708345852606
  ],
  [
    1620809700,
    56877.2407195339,
    1,
    13.233303581960449
  ],
  [
    1620896100,
    50163.90409075277,
    1,
    13.033720683523772
  ],
  [
    1620982500,
    50335.593331325166,
    1,
    12.818953361278835
  ],
  [
    1621068900,
    48977.143325974765,
    1,
    12.492424127610532
  ],
  [
    1621155300,
    48850.494198482826,
    1,
    12.863677258435212
  ],
  [
    1621241700,
    45403.903989933235,
    1,
    12.797217189022128
  ],
  [
    1621328100,
    44961.61325954469,
    1,
    12.894075601082353
  ],
  [
    1621414500,
    40580.355880996976,
    1,
    13.571455277071003
  ],
  [
    1621500900,
    39629.5498137342,
    1,
    14.783013112299132
  ],
  [
    1621587300,
    40036.71325659699,
    1,
    14.803717636245525
  ],
  [
    1621673700,
    37035.23772738026,
    1,
    15.688236023516815
  ],
  [
    1621760100,
    34397.64253902892,
    1,
    16.92247986529257
  ],
  [
    1621846500,
    36553.51703809108,
    1,
    16.014275128803433
  ],
  [
    1621932900,
    38117.82613708061,
    1,
    14.78944756871333
  ],
  [
    1622019300,
    40173.220322843576,
    1,
    14.049380412514516
  ],
  [
    1622105700,
    38967.10284406978,
    1,
    14.069333968332684
  ],
  [
    1622192100,
    36618.350495965205,
    1,
    14.38420731251878
  ],
  [
    1622278500,
    35871.61039548665,
    1,
    14.58771910767126
  ],
  [
    1622364900,
    35839.71075602029,
    1,
    14.736275628858216
  ],
  [
    1622451300,
    36078.238393801854,
    1,
    14.521389479350518
  ],
  [
    1622537700,
    36183.042580808855,
    1,
    14.034896109448587
  ],
  [
    1622624100,
    37259.9483900959,
    1,
    13.82801979936228
  ],
  [
    1622710500,
    38761.04747926383,
    1,
    13.691030397048777
  ],
  [
    1622796900,
    36754.95433573625,
    1,
    13.990719499616263
  ],
  [
    1622883300,
    37800.29117815624,
    1,
    13.456053745132364
  ],
  [
    1622969700,
    36154.49083573575,
    1,
    13.312558093583489
  ],
  [
    1623056100,
    36116.19616565861,
    1,
    13.085082599503174
  ],
  [
    1623142500,
    33100.788956226985,
    1,
    13.058590644811218
  ],
  [
    1623228900,
    33907.257552763615,
    1,
    13.672665192844262
  ],
  [
    1623315300,
    36453.4246739889,
    1,
    14.483542797617835
  ],
  [
    1623401700,
    37032.43530348422,
    1,
    15.108601108814463
  ],
  [
    1623488100,
    35210.055599951505,
    1,
    15.251231497610709
  ],
  [
    1623574500,
    35575.04446859035,
    1,
    15.13489807825465
  ],
  [
    1623660900,
    39011.6927476768,
    1,
    15.757607631954118
  ],
  [
    1623747300,
    39812.73590370142,
    1,
    15.403954259024129
  ],
  [
    1623833700,
    40036.37812285283,
    1,
    15.845876149114249
  ],
  [
    1623920100,
    39181.75999414381,
    1,
    16.103937975362502
  ],
  [
    1624006500,
    37891.185776109036,
    1,
    16.13670496498921
  ],
  [
    1624092900,
    35839.64459905871,
    1,
    15.93674550882903
  ],
  [
    1624179300,
    35323.58275383816,
    1,
    16.193728234951347
  ],
  [
    1624265700,
    32986.19359377203,
    1,
    16.351892873219036
  ],
  [
    1624352100,
    31679.456061074587,
    1,
    16.828160901761184
  ],
  [
    1624438500,
    33982.39052300172,
    1,
    17.129114268065788
  ],
  [
    1624524900,
    33223.44715783952,
    1,
    17.100111799790987
  ],
  [
    1624611300,
    34163.971123565745,
    1,
    17.713486270807074
  ],
  [
    1624697700,
    30393.163079536313,
    1,
    17.567661571945028
  ],
  [
    1624784100,
    33175.6835169255,
    1,
    17.85694556804708
  ],
  [
    1624870500,
    34899.06114746997,
    1,
    16.905944653875668
  ],
  [
    1624956900,
    35075.60201520465,
    1,
    16.301874638818887
  ],
  [
    1625043300,
    34832.90377663511,
    1,
    16.29672256966219
  ],
  [
    1625129700,
    33361.17360259933,
    1,
    15.793767752797697
  ],
  [
    1625216100,
    33361.563899855704,
    1,
    16.181164490899167
  ],
  [
    1625302500,
    34530.44612840183,
    1,
    15.720886978364208
  ],
  [
    1625388900,
    35518.90334576238,
    1,
    15.255548245348807
  ],
  [
    1625475300,
    34248.904157815654,
    1,
    15.083686553007281
  ],
  [
    1625561700,
    34865.397478466126,
    1,
    14.905025670762916
  ],
  [
    1625648100,
    34791.559784688936,
    1,
    14.598629111582431
  ],
  [
    1625734500,
    32506.2000305947,
    1,
    14.909380756157793
  ],
  [
    1625820900,
    32866.02132839167,
    1,
    15.665847689239596
  ],
  [
    1625907300,
    33838.91232697833,
    1,
    15.884058094245491
  ],
  [
    1625993700,
    33692.03474119905,
    1,
    15.946693667392696
  ],
  [
    1626080100,
    34278.01967316188,
    1,
    16.00003979252479
  ],
  [
    1626166500,
    33211.7625544547,
    1,
    16.380059262919776
  ],
  [
    1626252900,
    31786.064997308982,
    1,
    16.841975555055463
  ],
  [
    1626339300,
    32432.254019332482,
    1,
    16.527049241357833
  ],
  [
    1626425700,
    31457.57606478143,
    1,
    16.789215001553142
  ],
  [
    1626512100,
    31839.792745897772,
    1,
    16.75853189923274
  ],
  [
    1626598500,
    31723.659183681986,
    1,
    16.15582384225456
  ],
  [
    1626684900,
    31522.397490226373,
    1,
    16.71195973606105
  ],
  [
    1626771300,
    29676.92511533475,
    1,
    17.056981574449612
  ],
  [
    1626857700,
    30809.39267348675,
    1,
    16.495847452872013
  ],
  [
    1626944100,
    32121.987249600243,
    1,
    15.987977922516658
  ],
  [
    1627030500,
    32445.9046107717,
    1,
    15.779756762239995
  ],
  [
    1627116900,
    33599.7576208588,
    1,
    15.84507681155705
  ],
  [
    1627203300,
    34570.88436293752,
    1,
    15.948008508529472
  ],
  [
    1627289700,
    38169.28210131244,
    1,
    16.352238618342394
  ],
  [
    1627376100,
    37304.09485415934,
    1,
    16.837085979925565
  ],
  [
    1627462500,
    39501.41909968503,
    1,
    17.27799550659825
  ],
  [
    1627548900,
    39938.83983947628,
    1,
    17.39617884975005
  ],
  [
    1627635300,
    38871.22782365705,
    1,
    16.526804597686034
  ],
  [
    1627721700,
    41744.3744728004,
    1,
    16.940758020349982
  ],
  [
    1627808100,
    41733.64712118185,
    1,
    16.084563389034074
  ],
  [
    1627894500,
    39590.23179567894,
    1,
    15.34831010153833
  ],
  [
    1627980900,
    38550.448870565284,
    1,
    15.500025596882761
  ],
  [
    1628067300,
    37626.59788283028,
    1,
    15.253799763719167
  ],
  [
    1628153700,
    38613.81101339703,
    1,
    14.530788187963127
  ],
  [
    1628240100,
    41004.25101878591,
    1,
    14.76459976795258
  ],
  [
    1628326500,
    43416.625271641904,
    1,
    14.598164595937932
  ],
  [
    1628412900,
    44687.1316931137,
    1,
    14.232146471540757
  ],
  [
    1628499300,
    43776.708058557844,
    1,
    14.786666986336119
  ],
  [
    1628585700,
    45984.1387375967,
    1,
    14.605387045112334
  ],
  [
    1628672100,
    46167.35979045382,
    1,
    14.378391436543467
  ],
  [
    1628758500,
    45217.41298988787,
    1,
    14.436710778102686
  ],
  [
    1628844900,
    46175.85531920941,
    1,
    14.282207026889974
  ],
  [
    1628931300,
    47643.31044540655,
    1,
    14.427261162658553
  ],
  [
    1629017700,
    45839.64132906769,
    1,
    14.487985823424387
  ],
  [
    1629104100,
    47180.39970774789,
    1,
    14.408001250373564
  ],
  [
    1629190500,
    46157.5767157391,
    1,
    14.495327943568943
  ],
  [
    1629276900,
    45166.69168415164,
    1,
    14.867356796867739
  ],
  [
    1629363300,
    44637.23519117115,
    1,
    14.877836968940308
  ],
  [
    1629449700,
    46824.48877805789,
    1,
    14.660869197266257
  ],
  [
    1629536100,
    48870.16491366689,
    1,
    14.867479617154945
  ],
  [
    1629622500,
    49065.325079871574,
    1,
    15.07750913918767
  ],
  [
    1629708900,
    50211.71476706085,
    1,
    15.080179193498866
  ],
  [
    1629795300,
    49655.32719167414,
    1,
    14.895339144094724
  ],
  [
    1629881700,
    47393.212687046354,
    1,
    15.197000368111617
  ],
  [
    1629968100,
    47065.631848768906,
    1,
    15.147315292834238
  ],
  [
    1630054500,
    47260.96764051834,
    1,
    15.1835631111055
  ],
  [
    1630140900,
    48943.16190924002,
    1,
    15.11463396389105
  ],
  [
    1630227300,
    48416.7044734493,
    1,
    15.126005434522368
  ],
  [
    1630313700,
    48129.222849571386,
    1,
    15.057056261399953
  ],
  [
    1630400100,
    47728.576528164864,
    1,
    14.272472575117943
  ],
  [
    1630486500,
    47345.01879662717,
    1,
    13.389033715084866
  ],
  [
    1630572900,
    50068.617204901515,
    1,
    13.37253720708866
  ],
  [
    1630659300,
    49813.60995411189,
    1,
    12.837889958275786
  ],
  [
    1630745700,
    50142.72525354683,
    1,
    12.726846436573842
  ],
  [
    1630832100,
    50089.39715200787,
    1,
    12.812802211517667
  ],
  [
    1630918500,
    51678.40260082566,
    1,
    13.033859046555735
  ],
  [
    1631004900,
    51336.05646332326,
    1,
    13.609513316163827
  ],
  [
    1631091300,
    45785.14787188829,
    1,
    13.662422319982696
  ],
  [
    1631177700,
    46263.60133270949,
    1,
    13.10073504214132
  ],
  [
    1631264100,
    46033.527950127565,
    1,
    13.608062679060469
  ],
  [
    1631350500,
    45121.18192102034,
    1,
    13.853591618851059
  ],
  [
    1631436900,
    45981.0304941716,
    1,
    13.400620323212268
  ],
  [
    1631523300,
    44334.99232334317,
    1,
    13.883731739889836
  ],
  [
    1631609700,
    45993.26413679357,
    1,
    13.718528673740922
  ],
  [
    1631696100,
    47078.26595947617,
    1,
    13.85485718411624
  ],
  [
    1631782500,
    48156.92861779293,
    1,
    13.26908591065175
  ],
  [
    1631868900,
    47947.76392309422,
    1,
    13.587822761869944
  ],
  [
    1631955300,
    48606.930560304,
    1,
    13.837645282936212
  ],
  [
    1632041700,
    48285.117069699016,
    1,
    14.009866073585895
  ],
  [
    1632128100,
    45076.55447028513,
    1,
    14.303773104710944
  ],
  [
    1632214500,
    43317.39490113231,
    1,
    14.081416137772381
  ],
  [
    1632300900,
    42409.77897818871,
    1,
    14.454650471156587
  ],
  [
    1632387300,
    43856.935458229964,
    1,
    14.058122779073674
  ],
  [
    1632473700,
    44780.21379479724,
    1,
    14.470315125307053
  ],
  [
    1632560100,
    42230.458192469676,
    1,
    14.69741483297783
  ],
  [
    1632646500,
    41463.90787943307,
    1,
    14.951733414147332
  ],
  [
    1632732900,
    43810.140300980216,
    1,
    14.089192304173263
  ],
  [
    1632819300,
    41719.67383679628,
    1,
    14.395494115536774
  ],
  [
    1632905700,
    42385.8688104549,
    1,
    14.516628758397466
  ],
  [
    1632992100,
    42953.34775261222,
    1,
    14.476754722893931
  ],
  [
    1633078500,
    44897.238424613235,
    1,
    14.416949420539185
  ],
  [
    1633164900,
    47598.26121905324,
    1,
    14.52685265194803
  ],
  [
    1633251300,
    47878.571971024496,
    1,
    14.088127352155768
  ],
  [
    1633337700,
    47615.87785502894,
    1,
    14.229776790952595
  ],
  [
    1633424100,
    49785.36993681917,
    1,
    14.600023805768984
  ],
  [
    1633510500,
    50755.84318110919,
    1,
    14.983961011425189
  ],
  [
    1633596900,
    54512.66198218521,
    1,
    15.197106510753173
  ],
  [
    1633683300,
    55838.27587206541,
    1,
    15.253626898319377
  ],
  [
    1633769700,
    54694.43236128632,
    1,
    15.278450926024309
  ],
  [
    1633856100,
    55613.78984396417,
    1,
    15.498599408850746
  ],
  [
    1633942500,
    56559.395110011355,
    1,
    15.678162377610063
  ],
  [
    1634028900,
    57515.80468773785,
    1,
    16.437680422480625
  ],
  [
    1634115300,
    54771.54987051406,
    1,
    15.94799195901872
  ],
  [
    1634201700,
    57560.1518979797,
    1,
    15.804582416539366
  ],
  [
    1634288100,
    58891.83527440182,
    1,
    15.659715316352164
  ],
  [
    1634374500,
    61558.83806975677,
    1,
    15.640574671249322
  ],
  [
    1634460900,
    60685.31477462513,
    1,
    15.596098194927032
  ],
  [
    1634547300,
    61813.67964333063,
    1,
    16.142455347030015
  ],
  [
    1634633700,
    62257.36709717139,
    1,
    16.382590511348422
  ],
  [
    1634720100,
    64091.9713707956,
    1,
    16.612114801488264
  ],
  [
    1634806500,
    65863.6510042011,
    1,
    15.694879494949168
  ],
  [
    1634892900,
    63520.851440667444,
    1,
    15.325428052436633
  ],
  [
    1634979300,
    61070.662072616804,
    1,
    15.184461400760267
  ],
  [
    1635065700,
    60601.46273162507,
    1,
    14.850904190164927
  ],
  [
    1635152100,
    62738.457841646006,
    1,
    15.187724723244695
  ],
  [
    1635238500,
    62921.70181812241,
    1,
    14.90976251717181
  ],
  [
    1635324900,
    59119.576956763245,
    1,
    14.660983636668574
  ],
  [
    1635411300,
    60616.05150922873,
    1,
    14.663193773862076
  ],
  [
    1635497700,
    60853.51573868103,
    1,
    14.049428110194073
  ],
  [
    1635584100,
    61316.48379192802,
    1,
    14.224205560995378
  ],
  [
    1635670500,
    60876.172266946065,
    1,
    14.223431515401224
  ],
  [
    1635756900,
    61849.54368734004,
    1,
    14.354827235318458
  ],
  [
    1635843300,
    61709.51832111892,
    1,
    14.1490701477961
  ],
  [
    1635929700,
    63007.28253972771,
    1,
    13.730359637744318
  ],
  [
    1636016100,
    61657.16011058354,
    1,
    13.665667948787307
  ],
  [
    1636102500,
    62139.16914501368,
    1,
    13.700273606701323
  ],
  [
    1636188900,
    61021.80504229728,
    1,
    13.64112174795792
  ],
  [
    1636275300,
    61741.11039497567,
    1,
    13.575740025860597
  ],
  [
    1636361700,
    66037.63246427942,
    1,
    13.970082722903147
  ],
  [
    1636448100,
    68007.09717260709,
    1,
    14.1515100348532
  ],
  [
    1636534500,
    66627.14256031584,
    1,
    14.14035749557352
  ],
  [
    1636620900,
    65145.94734240433,
    1,
    13.855716025772528
  ],
  [
    1636707300,
    64896.674011853276,
    1,
    13.699692324328149
  ],
  [
    1636793700,
    63927.43371579011,
    1,
    13.793420358497713
  ],
  [
    1636880100,
    64282.384078696494,
    1,
    14.013375443111817
  ],
  [
    1636966500,
    65764.57547426321,
    1,
    13.936234814644186
  ],
  [
    1637052900,
    61002.01524253308,
    1,
    14.124142907041675
  ],
  [
    1637139300,
    59697.693095126655,
    1,
    14.207636115849844
  ],
  [
    1637225700,
    59513.03253993466,
    1,
    14.060664279120557
  ],
  [
    1637312100,
    57071.85109811835,
    1,
    13.757801722626123
  ],
  [
    1637398500,
    58595.13365513761,
    1,
    13.623413360059805
  ],
  [
    1637484900,
    58837.21039547229,
    1,
    13.548329080464706
  ],
  [
    1637571300,
    57376.05094012024,
    1,
    13.68154534518942
  ],
  [
    1637657700,
    56292.078969916474,
    1,
    13.6300905035235
  ],
  [
    1637744100,
    56707.40511068367,
    1,
    13.219723877518728
  ],
  [
    1637830500,
    57683.37859102102,
    1,
    13.437931456591247
  ],
  [
    1637916900,
    54525.77676853102,
    1,
    13.509072384989201
  ],
  [
    1638003300,
    55079.582883696516,
    1,
    13.226407196957378
  ],
  [
    1638089700,
    54330.51598213597,
    1,
    13.400387070588458
  ],
  [
    1638176100,
    57292.04574588505,
    1,
    13.26320544534006
  ],
  [
    1638262500,
    57052.34191186467,
    1,
    12.83671947210955
  ],
  [
    1638348900,
    57070.485320464366,
    1,
    12.099407554685307
  ],
  [
    1638435300,
    56980.50008238503,
    1,
    12.413250389329109
  ],
  [
    1638521700,
    56550.0776098792,
    1,
    12.403520307490814
  ],
  [
    1638608100,
    47659.736907091254,
    1,
    12.06249112473953
  ],
  [
    1638694500,
    49554.86045206154,
    1,
    11.756497206987019
  ],
  [
    1638780900,
    48208.208413492095,
    1,
    11.94301389725945
  ],
  [
    1638867300,
    51237.394036770216,
    1,
    11.600812736425691
  ],
  [
    1638953700,
    50377.79717934568,
    1,
    11.55101256202392
  ],
  [
    1639040100,
    49516.83434290215,
    1,
    11.404780687325298
  ],
  [
    1639126500,
    48045.46268833908,
    1,
    11.79863888047881
  ],
  [
    1639212900,
    48349.57198226085,
    1,
    12.063916962462796
  ],
  [
    1639299300,
    49055.69730180707,
    1,
    12.219030284223853
  ],
  [
    1639385700,
    49120.365204667025,
    1,
    12.201044110835559
  ],
  [
    1639472100,
    47378.73452369515,
    1,
    12.435410479275921
  ],
  [
    1639558500,
    48540.75708284009,
    1,
    12.514669435548745
  ],
  [
    1639644900,
    48803.64547137707,
    1,
    12.099120115488258
  ],
  [
    1639731300,
    47134.772871616486,
    1,
    12.120345889078026
  ],
  [
    1639817700,
    46283.98080534405,
    1,
    11.906288911183276
  ],
  [
    1639904100,
    47488.52384174265,
    1,
    11.957707393997321
  ],
  [
    1639990500,
    46250.47430488441,
    1,
    12.132708451148282
  ],
  [
    1640076900,
    48615.69127779641,
    1,
    12.094540982170706
  ],
  [
    1640163300,
    49403.90275905861,
    1,
    12.188404060325848
  ],
  [
    1640249700,
    48304.2663,
    1,
    12.2878
  ],
  [
    1640336100,
    51076.8195,
    1,
    12.4689
  ],
  [
    1640422500,
    50974.0891,
    1,
    12.4951
  ],
  [
    1640508900,
    49871.2418,
    1,
    12.3697
  ],
  [
    1640595300,
    50613.9568,
    1,
    12.514
  ],
  [
    1640681700,
    49326.3825,
    1,
    12.5997
  ],
  [
    1640768100,
    47752.3529,
    1,
    12.5617
  ],
  [
    1640854500,
    46868.7746,
    1,
    12.6692
  ],
  [
    1640940900,
    48317.5081,
    1,
    12.7089
  ],
  [
    1641027300,
    47130.9713,
    1,
    12.6536
  ],
  [
    1641113700,
    47227.3347,
    1,
    12.6112
  ],
  [
    1641200100,
    46955.1083,
    1,
    12.3316
  ],
  [
    1641286500,
    46458.4667,
    1,
    12.3337
  ],
  [
    1641372900,
    46637.0694,
    1,
    12.1941
  ],
  [
    1641459300,
    42986.8379,
    1,
    12.6384
  ],
  [
    1641545700,
    42169.5168,
    1,
    13.0344
  ],
  [
    1641632100,
    42009.4659,
    1,
    13.0432
  ],
  [
    1641718500,
    41743.0765,
    1,
    13.4083
  ],
  [
    1641804900,
    41786.9586,
    1,
    13.292
  ],
  [
    1641891300,
    42000.1782,
    1,
    13.5126
  ],
  [
    1641977700,
    42660.7495,
    1,
    13.179
  ],
  [
    1642064100,
    43837.0001,
    1,
    13.0782
  ],
  [
    1642150500,
    42479.1587,
    1,
    13.0529
  ],
  [
    1642236900,
    43021.3441,
    1,
    12.9782
  ],
  [
    1642323300,
    43113.8454,
    1,
    12.9803
  ],
  [
    1642409700,
    42848.164,
    1,
    13.0799
  ],
  [
    1642496100,
    41756.1713,
    1,
    13.2187
  ],
  [
    1642582500,
    41307.6448,
    1,
    13.4936
  ],
  [
    1642668900,
    41917.6389,
    1,
    13.4059
  ],
  [
    1642755300,
    39147.41,
    1,
    13.5538
  ],
  [
    1642841700,
    35627.7171,
    1,
    14.3955
  ],
  [
    1642928100,
    35624.6388,
    1,
    14.2732
  ],
  [
    1643014500,
    35024.5772,
    1,
    14.6502
  ],
  [
    1643100900,
    36227.085,
    1,
    15.1259
  ],
  [
    1643187300,
    37575.0867,
    1,
    15.1205
  ],
  [
    1643273700,
    36357.1106,
    1,
    14.9713
  ],
  [
    1643360100,
    36631.331,
    1,
    15.2746
  ],
  [
    1643446500,
    37817.3454,
    1,
    14.8056
  ],
  [
    1643532900,
    38056.9464,
    1,
    14.5827
  ],
  [
    1643619300,
    37175.7621,
    1,
    14.6888
  ],
  [
    1643705700,
    38564.8101,
    1,
    13.9829
  ],
  [
    1643792100,
    38403.7575,
    1,
    13.9263
  ],
  [
    1643878500,
    37053.5612,
    1,
    13.8669
  ],
  [
    1643964900,
    37905.0244,
    1,
    13.393
  ],
  [
    1644051300,
    41456.6926,
    1,
    13.7774
  ],
  [
    1644137700,
    41606.7426,
    1,
    13.8317
  ],
  [
    1644224100,
    42655.5211,
    1,
    13.8564
  ],
  [
    1644310500,
    44153.1556,
    1,
    14.1723
  ],
  [
    1644396900,
    43446.1023,
    1,
    14.0296
  ],
  [
    1644483300,
    44236.4066,
    1,
    13.833
  ],
  [
    1644569700,
    43314.6941,
    1,
    14.0166
  ],
  [
    1644656100,
    42335.857,
    1,
    14.4078
  ],
  [
    1644742500,
    42462.2259,
    1,
    14.5096
  ],
  [
    1644828900,
    42104.4856,
    1,
    14.7071
  ],
  [
    1644915300,
    43930.0547,
    1,
    14.286
  ],
  [
    1645001700,
    44183.1456,
    1,
    14.0495
  ],
  [
    1645088100,
    43460.9579,
    1,
    14.1861
  ],
  [
    1645174500,
    40807.8719,
    1,
    13.9185
  ],
  [
    1645260900,
    40056.1216,
    1,
    14.3616
  ],
  [
    1645347300,
    38260.9501,
    1,
    14.6382
  ],
  [
    1645433700,
    39121.9588,
    1,
    14.3474
  ],
  [
    1645520100,
    37040.1739,
    1,
    14.5774
  ],
  [
    1645606500,
    38672.4374,
    1,
    14.3005
  ],
  [
    1645692900,
    35603.4969,
    1,
    14.9435
  ],
  [
    1645779300,
    38429.4801,
    1,
    14.7086
  ],
  [
    1645865700,
    38744.9755,
    1,
    14.0861
  ],
  [
    1645952100,
    38844.5627,
    1,
    14.152
  ],
  [
    1646038500,
    38312.7149,
    1,
    14.5841
  ],
  [
    1646124900,
    43459.1757,
    1,
    14.8778
  ],
  [
    1646211300,
    43944.3557,
    1,
    14.7314
  ],
  [
    1646297700,
    43418.1243,
    1,
    14.9602
  ],
  [
    1646384100,
    41477.0548,
    1,
    15.1507
  ],
  [
    1646470500,
    39110.1002,
    1,
    14.7502
  ],
  [
    1646556900,
    39155.5513,
    1,
    14.8455
  ],
  [
    1646643300,
    38099.9514,
    1,
    15.0637
  ],
  [
    1646729700,
    38787.5325,
    1,
    15.1085
  ],
  [
    1646816100,
    42361.0278,
    1,
    15.3076
  ],
  [
    1646902500,
    39215.1404,
    1,
    15.1056
  ],
  [
    1646988900,
    38986.0738,
    1,
    14.9943
  ],
  [
    1647075300,
    39139.9837,
    1,
    15.142
  ],
  [
    1647161700,
    39034.5746,
    1,
    15.142
  ],
  [
    1647248100,
    39076.3198,
    1,
    15.0558
  ],
  [
    1647334500,
    38402.3642,
    1,
    15.2419
  ],
  [
    1647420900,
    40339.9316,
    1,
    15.0742
  ],
  [
    1647507300,
    40859.0554,
    1,
    14.7444
  ],
  [
    1647593700,
    40610.9037,
    1,
    14.4794
  ],
  [
    1647680100,
    41709.7954,
    1,
    14.216
  ],
  [
    1647766500,
    41871.6135,
    1,
    14.3079
  ],
  [
    1647852900,
    41195.2329,
    1,
    14.2927
  ],
  [
    1647939300,
    42514.1798,
    1,
    14.1146
  ],
  [
    1648025700,
    42293.0201,
    1,
    14.2906
  ],
  [
    1648112100,
    43071.7014,
    1,
    14.1134
  ],
  [
    1648198500,
    44003.9293,
    1,
    14.0707
  ],
  [
    1648284900,
    44482.2165,
    1,
    14.2216
  ],
  [
    1648371300,
    44651.4374,
    1,
    14.1887
  ],
  [
    1648457700,
    47012.1042,
    1,
    14.1509
  ],
  [
    1648544100,
    47590.6841,
    1,
    13.9332
  ],
  [
    1648630500,
    47442.892,
    1,
    13.9399
  ],
  [
    1648716900,
    47308.3326,
    1,
    13.8556
  ],
  [
    1648803300,
    45118.6796,
    1,
    13.753
  ],
  [
    1648889700,
    46541.3472,
    1,
    13.4037
  ],
  [
    1648976100,
    46235.8101,
    1,
    13.3055
  ],
  [
    1649062500,
    46042.4553,
    1,
    13.1917
  ],
  [
    1649148900,
    46616.1844,
    1,
    13.253
  ],
  [
    1649235300,
    45364.076,
    1,
    13.5118
  ],
  [
    1649321700,
    43472.5206,
    1,
    13.496
  ],
  [
    1649408100,
    43884.773,
    1,
    13.2803
  ],
  [
    1649494500,
    42469.9617,
    1,
    13.2321
  ],
  [
    1649580900,
    42622.0044,
    1,
    13.148
  ],
  [
    1649667300,
    42186.7699,
    1,
    13.3599
  ],
  [
    1649753700,
    40219.6987,
    1,
    13.2838
  ],
  [
    1649840100,
    40147.2108,
    1,
    13.1434
  ],
  [
    1649926500,
    41185.7515,
    1,
    13.2551
  ],
  [
    1650012900,
    40132.2953,
    1,
    13.2804
  ],
  [
    1650099300,
    40370.8446,
    1,
    13.2961
  ],
  [
    1650185700,
    40377.7759,
    1,
    13.2863
  ],
  [
    1650272100,
    38883.6397,
    1,
    13.4338
  ],
  [
    1650358500,
    40714.9732,
    1,
    13.3938
  ],
  [
    1650444900,
    41460.3429,
    1,
    13.3764
  ],
  [
    1650531300,
    41973.4095,
    1,
    13.5254
  ],
  [
    1650617700,
    40427.0042,
    1,
    13.4706
  ],
  [
    1650704100,
    39659.724,
    1,
    13.3756
  ],
  [
    1650790500,
    39752.5937,
    1,
    13.4861
  ],
  [
    1650876900,
    38437.7325,
    1,
    13.6826
  ],
  [
    1650963300,
    40499.774,
    1,
    13.5141
  ],
  [
    1651049700,
    38848.769,
    1,
    13.5132
  ],
  [
    1651136100,
    39651.9957,
    1,
    13.5116
  ],
  [
    1651222500,
    39449.0383,
    1,
    13.555
  ],
  [
    1651395300,
    38047.2856,
    1,
    13.6794
  ],
  [
    1651481700,
    38924.3293,
    1,
    13.7135
  ],
  [
    1651568100,
    38558.9739,
    1,
    13.5452
  ],
  [
    1651654500,
    38884.5943,
    1,
    13.7022
  ],
  [
    1651740900,
    39522.1139,
    1,
    13.4964
  ],
  [
    1651827300,
    36262.8308,
    1,
    13.318
  ],
  [
    1651913700,
    36029.764,
    1,
    13.4051
  ],
  [
    1652000100,
    34692.106,
    1,
    13.5262
  ],
  [
    1652086500,
    33600.281,
    1,
    13.6889
  ],
  [
    1652172900,
    31709.0126,
    1,
    13.3093
  ],
  [
    1652259300,
    31092.096,
    1,
    13.1516
  ],
  [
    1652345700,
    27782.2279,
    1,
    14.6908
  ],
  [
    1652432100,
    30268.159,
    1,
    14.6679
  ],
  [
    1652518500,
    29531.2343,
    1,
    14.4595
  ],
  [
    1652604900,
    29595.2888,
    1,
    14.634
  ],
  [
    1652691300,
    29801.226,
    1,
    14.6988
  ],
  [
    1652777700,
    30632.7113,
    1,
    14.6735
  ],
  [
    1652864100,
    29813.0924,
    1,
    14.6891
  ],
  [
    1652950500,
    29042.7431,
    1,
    14.9737
  ],
  [
    1653036900,
    30209.9469,
    1,
    14.9089
  ],
  [
    1653123300,
    29358.8479,
    1,
    14.851
  ],
  [
    1653209700,
    29521.6836,
    1,
    14.8889
  ],
  [
    1653296100,
    30337.9523,
    1,
    14.7602
  ],
  [
    1653382500,
    29209.2303,
    1,
    14.8484
  ],
  [
    1653468900,
    29865.0652,
    1,
    15.093
  ],
  [
    1653555300,
    29005.8681,
    1,
    15.835
  ],
  [
    1653641700,
    28984.2034,
    1,
    16.3712
  ],
  [
    1653728100,
    28840.0932,
    1,
    16.3827
  ],
  [
    1653814500,
    29064.8119,
    1,
    16.284
  ],
  [
    1653900900,
    30715.3325,
    1,
    16.1039
  ],
  [
    1653987300,
    31679.6939,
    1,
    16.0249
  ],
  [
    1654073700,
    31613.9127,
    1,
    16.3196
  ],
  [
    1654160100,
    29955.2274,
    1,
    16.4286
  ],
  [
    1654246500,
    30422.2868,
    1,
    16.817
  ],
  [
    1654332900,
    29743.7249,
    1,
    16.7703
  ],
  [
    1654419300,
    29718.5218,
    1,
    16.592
  ],
  [
    1654505700,
    31423.0352,
    1,
    16.516
  ],
  [
    1654592100,
    29632.259,
    1,
    16.7396
  ],
  [
    1654678500,
    30501.1789,
    1,
    16.8887
  ],
  [
    1654764900,
    30498.8718,
    1,
    16.7906
  ],
  [
    1654851300,
    30033.2054,
    1,
    16.8031
  ],
  [
    1654937700,
    29149.028,
    1,
    17.9689
  ],
  [
    1655024100,
    27553.5394,
    1,
    18.7406
  ],
  [
    1655110500,
    24374.7597,
    1,
    19.884
  ],
  [
    1655196900,
    22686.3168,
    1,
    18.5283
  ],
  [
    1655283300,
    20296.1446,
    1,
    19.4868
  ],
  [
    1655369700,
    21754.3913,
    1,
    18.5941
  ],
  [
    1655456100,
    21069.9006,
    1,
    19.2256
  ],
  [
    1655542500,
    19029.9765,
    1,
    19.1767
  ],
  [
    1655628900,
    18573.0423,
    1,
    19.2436
  ],
  [
    1655715300,
    20510.2015,
    1,
    18.3039
  ],
  [
    1655801700,
    21320.5279,
    1,
    18.2855
  ],
  [
    1655888100,
    20179.1673,
    1,
    18.7187
  ],
  [
    1655974500,
    20493.6014,
    1,
    18.7393
  ],
  [
    1656060900,
    21051.9577,
    1,
    18.1628
  ],
  [
    1656147300,
    21432.7192,
    1,
    17.4367
  ],
  [
    1656233700,
    21466.2856,
    1,
    17.2369
  ],
  [
    1656320100,
    21430.9992,
    1,
    17.4276
  ],
  [
    1656406500,
    21064.5084,
    1,
    17.1892
  ],
  [
    1656492900,
    20004.9236,
    1,
    17.7697
  ],
  [
    1656579300,
    19275.1203,
    1,
    18.3334
  ],
  [
    1656665700,
    19610.3052,
    1,
    18.2395
  ],
  [
    1656752100,
    19129.9712,
    1,
    18.3824
  ],
  [
    1656838500,
    19051.8088,
    1,
    18.0438
  ],
  [
    1656924900,
    19156.5011,
    1,
    18.2062
  ],
  [
    1657011300,
    19887.9724,
    1,
    17.4446
  ],
  [
    1657097700,
    20188.9155,
    1,
    17.6818
  ],
  [
    1657184100,
    20513.1612,
    1,
    17.304
  ],
  [
    1657270500,
    21647.3725,
    1,
    17.5822
  ],
  [
    1657356900,
    21680.621,
    1,
    17.7074
  ],
  [
    1657443300,
    21291.7302,
    1,
    17.9691
  ],
  [
    1657529700,
    20519.5925,
    1,
    17.9446
  ],
  [
    1657616100,
    19764.8855,
    1,
    18.5132
  ],
  [
    1657702500,
    19875.9324,
    1,
    18.4391
  ],
  [
    1657788900,
    19847.5556,
    1,
    18.246
  ],
  [
    1657875300,
    20906.4293,
    1,
    17.2461
  ],
  [
    1657961700,
    20617.5009,
    1,
    17.1822
  ],
  [
    1658048100,
    21403.2826,
    1,
    15.7088
  ],
  [
    1658134500,
    22245.7509,
    1,
    15.0913
  ],
  [
    1658220900,
    21867.8095,
    1,
    14.287
  ],
  [
    1658307300,
    23346.7731,
    1,
    15.2714
  ],
  [
    1658393700,
    22866.616,
    1,
    15.3479
  ],
  [
    1658480100,
    23191.319,
    1,
    14.4856
  ],
  [
    1658566500,
    22689.1336,
    1,
    14.5559
  ],
  [
    1658652900,
    22676.1946,
    1,
    14.1256
  ],
  [
    1658739300,
    22066.3665,
    1,
    14.3361
  ],
  [
    1658825700,
    21066.6313,
    1,
    14.8927
  ],
  [
    1658912100,
    21377.2633,
    1,
    14.5872
  ],
  [
    1658998500,
    22983.1291,
    1,
    14.1397
  ],
  [
    1659084900,
    23930.0644,
    1,
    13.9153
  ],
  [
    1659171300,
    23905.2417,
    1,
    13.9494
  ],
  [
    1659257700,
    23617.5111,
    1,
    13.9399
  ],
  [
    1659344100,
    23300.7382,
    1,
    13.8412
  ],
  [
    1659430500,
    22822.8937,
    1,
    14.4289
  ],
  [
    1659516900,
    23230.8474,
    1,
    14.1132
  ],
  [
    1659603300,
    22822.8618,
    1,
    14.0887
  ],
  [
    1659689700,
    23106.8128,
    1,
    13.9795
  ],
  [
    1659776100,
    23178.1768,
    1,
    13.546
  ],
  [
    1659862500,
    22968.3725,
    1,
    13.6509
  ],
  [
    1659948900,
    24092.3941,
    1,
    13.6273
  ],
  [
    1660035300,
    23794.858,
    1,
    13.4263
  ],
  [
    1660121700,
    23123.351,
    1,
    13.5874
  ],
  [
    1660208100,
    24471.0774,
    1,
    12.9954
  ],
  [
    1660294500,
    23945.5486,
    1,
    12.6598
  ],
  [
    1660380900,
    24458.3154,
    1,
    12.2809
  ],
  [
    1660467300,
    24633.2151,
    1,
    12.34
  ],
  [
    1660553700,
    24038.2137,
    1,
    12.6139
  ],
  [
    1660640100,
    23995.9916,
    1,
    12.7689
  ],
  [
    1660726500,
    23803.9155,
    1,
    12.6223
  ],
  [
    1660812900,
    23426.0172,
    1,
    12.7097
  ],
  [
    1660899300,
    21810.1719,
    1,
    12.5439
  ],
  [
    1660985700,
    21202.2491,
    1,
    12.9423
  ],
  [
    1661072100,
    21325.0725,
    1,
    13.3587
  ],
  [
    1661158500,
    21096.8405,
    1,
    13.5762
  ],
  [
    1661244900,
    21473.5218,
    1,
    13.3198
  ],
  [
    1661331300,
    21395.5895,
    1,
    13.0572
  ],
  [
    1661417700,
    21695.6457,
    1,
    12.6943
  ],
  [
    1661504100,
    21424.2822,
    1,
    12.8891
  ],
  [
    1661590500,
    20216.4304,
    1,
    13.4416
  ],
  [
    1661676900,
    20013.0519,
    1,
    13.423
  ],
  [
    1661763300,
    19816.0093,
    1,
    13.6955
  ],
  [
    1661849700,
    20399.0444,
    1,
    12.8661
  ],
  [
    1661936100,
    20257.5635,
    1,
    12.8035
  ],
  [
    1662022500,
    19903.2308,
    1,
    12.9037
  ],
  [
    1662108900,
    20100.4485,
    1,
    12.6465
  ],
  [
    1662195300,
    19794.4969,
    1,
    12.8041
  ],
  [
    1662281700,
    19734.8372,
    1,
    12.7046
  ],
  [
    1662368100,
    19771.9581,
    1,
    12.637
  ],
  [
    1662454500,
    19968.4574,
    1,
    12.04
  ],
  [
    1662540900,
    18807.7273,
    1,
    12.3675
  ],
  [
    1662627300,
    19226.6712,
    1,
    11.8346
  ],
  [
    1662713700,
    20776.2908,
    1,
    12.1592
  ],
  [
    1662800100,
    21347.239,
    1,
    12.4383
  ],
  [
    1662886500,
    21691.8832,
    1,
    12.2787
  ],
  [
    1662972900,
    22220.2551,
    1,
    12.6672
  ],
  [
    1663059300,
    22234.4495,
    1,
    12.9689
  ],
  [
    1663145700,
    20395.5573,
    1,
    12.7283
  ],
  [
    1663232100,
    20133.4836,
    1,
    12.5242
  ],
  [
    1663318500,
    19681.147,
    1,
    13.5742
  ],
  [
    1663404900,
    19869.9792,
    1,
    13.8635
  ],
  [
    1663491300,
    20059.7634,
    1,
    13.7789
  ],
  [
    1663577700,
    18433.051,
    1,
    14.2272
  ],
  [
    1663664100,
    19305.1836,
    1,
    14.2147
  ],
  [
    1663750500,
    18886.3661,
    1,
    14.2033
  ],
  [
    1663836900,
    19187.1172,
    1,
    14.8787
  ],
  [
    1663923300,
    19053.1404,
    1,
    14.4789
  ],
  [
    1664009700,
    19039.7721,
    1,
    14.332
  ],
  [
    1664096100,
    19079.7019,
    1,
    14.3533
  ],
  [
    1664182500,
    19260.8865,
    1,
    14.6071
  ],
  [
    1664268900,
    20158.7284,
    1,
    14.6197
  ],
  [
    1664355300,
    18747.2394,
    1,
    14.6077
  ],
  [
    1664441700,
    19360.7244,
    1,
    14.6207
  ],
  [
    1664528100,
    19593.8414,
    1,
    14.5689
  ],
  [
    1664614500,
    19338.9603,
    1,
    14.5175
  ],
  [
    1664700900,
    19282.6159,
    1,
    14.7141
  ],
  [
    1664787300,
    19208.3919,
    1,
    14.8537
  ],
  [
    1664873700,
    19894.5124,
    1,
    14.7595
  ],
  [
    1664960100,
    20141.4309,
    1,
    14.9721
  ],
  [
    1665046500,
    20139.1328,
    1,
    14.8226
  ],
  [
    1665132900,
    19960.4341,
    1,
    14.7228
  ],
  [
    1665219300,
    19464.4286,
    1,
    14.6801
  ],
  [
    1665305700,
    19394.1177,
    1,
    14.7469
  ],
  [
    1665392100,
    19236.6062,
    1,
    14.7285
  ],
  [
    1665478500,
    19097.4053,
    1,
    14.8666
  ],
  [
    1665564900,
    19142.1895,
    1,
    14.7375
  ],
  [
    1665651300,
    19015.3307,
    1,
    14.881
  ],
  [
    1665737700,
    19595.9891,
    1,
    14.8294
  ],
  [
    1665824100,
    19058.4699,
    1,
    14.8688
  ],
  [
    1665910500,
    19169.356,
    1,
    14.8762
  ],
  [
    1665996900,
    19263.0149,
    1,
    14.7135
  ],
  [
    1666083300,
    19559.8813,
    1,
    14.7525
  ],
  [
    1666169700,
    19197.3609,
    1,
    14.7722
  ],
  [
    1666256100,
    19128.6354,
    1,
    14.7915
  ],
  [
    1666342500,
    18978.2567,
    1,
    14.856
  ],
  [
    1666428900,
    19154.7929,
    1,
    14.7233
  ],
  [
    1666515300,
    19184.1503,
    1,
    14.6388
  ],
  [
    1666601700,
    19339.4891,
    1,
    14.4552
  ],
  [
    1666688100,
    19302.8972,
    1,
    14.351
  ],
  [
    1666774500,
    20670.1195,
    1,
    13.4219
  ],
  [
    1666860900,
    20707.0526,
    1,
    13.3314
  ],
  [
    1666947300,
    20201.7947,
    1,
    13.4362
  ],
  [
    1667033700,
    20921.9246,
    1,
    12.8528
  ],
  [
    1667120100,
    20776.1123,
    1,
    12.846
  ],
  [
    1667206500,
    20483.1937,
    1,
    12.9482
  ],
  [
    1667292900,
    20579.8982,
    1,
    12.967
  ],
  [
    1667379300,
    20462.6947,
    1,
    13.0338
  ],
  [
    1667465700,
    20282.9629,
    1,
    13.156
  ],
  [
    1667552100,
    20613.0175,
    1,
    13.0357
  ],
  [
    1667638500,
    21297.6705,
    1,
    12.9606
  ],
  [
    1667724900,
    21264.9226,
    1,
    13.0113
  ],
  [
    1667811300,
    20714.3475,
    1,
    13.2067
  ],
  [
    1667897700,
    19721.402,
    1,
    13.2791
  ],
  [
    1667984100,
    18111.5498,
    1,
    14.3554
  ],
  [
    1668070500,
    16941.2855,
    1,
    13.9181
  ],
  [
    1668156900,
    17432.4292,
    1,
    13.5853
  ],
  [
    1668243300,
    16830.5357,
    1,
    13.3403
  ],
  [
    1668329700,
    16667.223,
    1,
    13.4351
  ],
  [
    1668416100,
    16780.6564,
    1,
    13.2921
  ],
  [
    1668502500,
    16917.1723,
    1,
    13.2877
  ],
  [
    1668588900,
    16776.7062,
    1,
    13.5518
  ],
  [
    1668675300,
    16567.7897,
    1,
    13.7704
  ],
  [
    1668761700,
    16708.4998,
    1,
    13.8061
  ],
  [
    1668848100,
    16656.6405,
    1,
    13.749
  ],
  [
    1668934500,
    16610.674,
    1,
    13.7454
  ],
  [
    1669020900,
    16009.6174,
    1,
    14.3255
  ],
  [
    1669107300,
    15744.9087,
    1,
    14.4541
  ],
  [
    1669193700,
    16544.6825,
    1,
    14.206
  ],
  [
    1669280100,
    16587.6119,
    1,
    13.8276
  ],
  [
    1669366500,
    16447.632,
    1,
    13.9253
  ],
  [
    1669452900,
    16581.6584,
    1,
    13.6231
  ],
  [
    1669539300,
    16535.0389,
    1,
    13.6408
  ],
  [
    1669625700,
    16240.2382,
    1,
    13.8191
  ],
  [
    1669712100,
    16501.9195,
    1,
    13.5627
  ],
  [
    1669798500,
    16888.8727,
    1,
    13.3193
  ],
  [
    1669884900,
    17169.9763,
    1,
    13.3251
  ],
  [
    1669971300,
    16967.1229,
    1,
    13.2906
  ],
  [
    1670057700,
    16954.5374,
    1,
    13.3191
  ],
  [
    1670144100,
    17015.7114,
    1,
    13.5115
  ],
  [
    1670230500,
    17359.3794,
    1,
    13.3435
  ],
  [
    1670316900,
    17026.5753,
    1,
    13.4933
  ],
  [
    1670403300,
    16785.9271,
    1,
    13.6645
  ],
  [
    1670489700,
    16829.0292,
    1,
    13.6443
  ],
  [
    1670576100,
    17204.5149,
    1,
    13.4493
  ],
  [
    1670662500,
    17145.4674,
    1,
    13.565
  ],
  [
    1670748900,
    17169.1428,
    1,
    13.4749
  ],
  [
    1670835300,
    16933.4397,
    1,
    13.5734
  ],
  [
    1670921700,
    17170.7748,
    1,
    13.5685
  ],
  [
    1671008100,
    17832.1848,
    1,
    13.4765
  ],
  [
    1671094500,
    17646.2828,
    1,
    13.7278
  ],
  [
    1671180900,
    17296.8811,
    1,
    13.8067
  ],
  [
    1671267300,
    16725.2283,
    1,
    14.1668
  ],
  [
    1671353700,
    16754.11,
    1,
    14.1136
  ],
  [
    1671440100,
    16755.2318,
    1,
    14.1567
  ],
  [
    1671526500,
    16833.8881,
    1,
    13.895
  ],
  [
    1671612900,
    16875.9159,
    1,
    13.8578
  ],
  [
    1671699300,
    16835.5721,
    1,
    13.8522
  ],
  [
    1671785700,
    16842.5205,
    1,
    13.818
  ],
  [
    1671872100,
    16840.3954,
    1,
    13.8138
  ],
  [
    1671958500,
    16830.6817,
    1,
    13.7925
  ],
  [
    1672044900,
    16838.53,
    1,
    13.8071
  ],
  [
    1672131300,
    16857.7685,
    1,
    13.8398
  ],
  [
    1672217700,
    16645.2545,
    1,
    13.9243
  ],
  [
    1672304100,
    16527.7219,
    1,
    13.8632
  ],
  [
    1672390500,
    16506.7007,
    1,
    13.8494
  ],
  [
    1672476900,
    16554.2436,
    1,
    13.8385
  ],
  [
    1672563300,
    16511.9928,
    1,
    13.8242
  ],
  [
    1672649700,
    16733.1027,
    1,
    13.7676
  ],
  [
    1672736100,
    16708.7525,
    1,
    13.7532
  ],
  [
    1672822500,
    16861.1483,
    1,
    13.4792
  ],
  [
    1672908900,
    16806.5459,
    1,
    13.4365
  ],
  [
    1672995300,
    16801.446,
    1,
    13.4441
  ],
  [
    1673081700,
    16930.9052,
    1,
    13.3808
  ],
  [
    1673168100,
    16946.8783,
    1,
    13.4158
  ],
  [
    1673254500,
    17205.5731,
    1,
    13.119
  ],
  [
    1673340900,
    17256.0457,
    1,
    12.992
  ],
  [
    1673427300,
    17434.7942,
    1,
    13.0762
  ],
  [
    1673513700,
    18129.3699,
    1,
    12.9736
  ],
  [
    1673600100,
    18838.6376,
    1,
    13.3749
  ],
  [
    1673686500,
    20982.4166,
    1,
    13.4246
  ],
  [
    1673772900,
    20637.4883,
    1,
    13.552
  ],
  [
    1673859300,
    20764.5862,
    1,
    13.4965
  ],
  [
    1673945700,
    21190.3946,
    1,
    13.5134
  ],
  [
    1674032100,
    21245.7468,
    1,
    13.4732
  ],
  [
    1674118500,
    20785.8107,
    1,
    13.6025
  ],
  [
    1674204900,
    20930.4651,
    1,
    13.5023
  ],
  [
    1674291300,
    22728.7444,
    1,
    13.7666
  ],
  [
    1674377700,
    22899.2043,
    1,
    14.0528
  ],
  [
    1674464100,
    22725.8889,
    1,
    13.9275
  ],
  [
    1674550500,
    23058.2285,
    1,
    14.1003
  ],
  [
    1674636900,
    22683.6363,
    1,
    14.617
  ],
  [
    1674723300,
    23045.1075,
    1,
    14.32
  ],
  [
    1674809700,
    22967.9987,
    1,
    14.5308
  ],
  [
    1674896100,
    23003.3465,
    1,
    14.4464
  ],
  [
    1674982500,
    23165.3146,
    1,
    14.5685
  ],
  [
    1675068900,
    23540.8844,
    1,
    14.5445
  ],
  [
    1675155300,
    22868.2874,
    1,
    14.5721
  ],
  [
    1675241700,
    22976.3888,
    1,
    14.6304
  ],
  [
    1675328100,
    23796.5057,
    1,
    14.2574
  ],
  [
    1675414500,
    23395.5634,
    1,
    14.2676
  ],
  [
    1675500900,
    23304.0468,
    1,
    14.104
  ],
  [
    1675587300,
    23392.5396,
    1,
    14.0083
  ],
  [
    1675673700,
    22862.8185,
    1,
    14.0068
  ],
  [
    1675760100,
    22874.537,
    1,
    14.0323
  ],
  [
    1675846500,
    23197.7342,
    1,
    13.8724
  ],
  [
    1675932900,
    22723.6431,
    1,
    13.9028
  ],
  [
    1676019300,
    21884.8608,
    1,
    14.1125
  ],
  [
    1676105700,
    21695.7677,
    1,
    14.2421
  ],
  [
    1676192100,
    21813.9965,
    1,
    14.2348
  ],
  [
    1676278500,
    21750.1466,
    1,
    14.4134
  ],
  [
    1676364900,
    21712.6229,
    1,
    14.4734
  ],
  [
    1676451300,
    22102.9222,
    1,
    14.2888
  ],
  [
    1676537700,
    24629.6441,
    1,
    14.6118
  ],
  [
    1676624100,
    23667.9541,
    1,
    14.2976
  ],
  [
    1676710500,
    24590.2554,
    1,
    14.5162
  ],
  [
    1676796900,
    24576.2113,
    1,
    14.5141
  ],
  [
    1676883300,
    24476.3515,
    1,
    14.4634
  ],
  [
    1676969700,
    24710.8414,
    1,
    14.7191
  ],
  [
    1677056100,
    23937.4775,
    1,
    14.6837
  ],
  [
    1677142500,
    24422.4782,
    1,
    14.6403
  ],
  [
    1677228900,
    23883.3645,
    1,
    14.4463
  ],
  [
    1677315300,
    23106.6437,
    1,
    14.4043
  ],
  [
    1677401700,
    23155.4326,
    1,
    14.4605
  ],
  [
    1677488100,
    23400.3391,
    1,
    14.298
  ],
  [
    1677574500,
    23284.0108,
    1,
    14.352
  ],
  [
    1677660900,
    23949.1926,
    1,
    14.4602
  ],
  [
    1677747300,
    23453.8048,
    1,
    14.2344
  ],
  [
    1677833700,
    22423.2815,
    1,
    14.3327
  ],
  [
    1677920100,
    22347.8466,
    1,
    14.2463
  ],
  [
    1678006500,
    22341.3435,
    1,
    14.2669
  ],
  [
    1678092900,
    22404.7348,
    1,
    14.3261
  ],
  [
    1678179300,
    22424.1275,
    1,
    14.2757
  ],
  [
    1678265700,
    22007.8229,
    1,
    14.159
  ],
  [
    1678352100,
    21684.1732,
    1,
    14.1469
  ],
  [
    1678438500,
    19954.9138,
    1,
    14.1687
  ],
  [
    1678524900,
    20139.5393,
    1,
    13.9476
  ],
  [
    1678611300,
    20342.5296,
    1,
    13.8647
  ],
  [
    1678697700,
    22231.1973,
    1,
    13.9981
  ],
  [
    1678784100,
    24234.0619,
    1,
    14.543
  ],
  [
    1678870500,
    24785.0669,
    1,
    14.5754
  ],
  [
    1678956900,
    24603.3612,
    1,
    14.8644
  ],
  [
    1679043300,
    25998.6849,
    1,
    15.1803
  ],
  [
    1679129700,
    27288.1225,
    1,
    15.0439
  ],
  [
    1679216100,
    27044.7635,
    1,
    15.1983
  ],
  [
    1679302500,
    28272.5408,
    1,
    15.7999
  ],
  [
    1679388900,
    27656.0343,
    1,
    15.901
  ],
  [
    1679475300,
    28097.4598,
    1,
    15.7244
  ],
  [
    1679561700,
    27649.9606,
    1,
    15.774
  ],
  [
    1679648100,
    28061.1309,
    1,
    15.606
  ],
  [
    1679734500,
    27462.8901,
    1,
    15.7337
  ],
  [
    1679820900,
    27693.3887,
    1,
    15.554
  ],
  [
    1679907300,
    27856.895,
    1,
    15.8493
  ],
  [
    1679993700,
    27029.0847,
    1,
    15.6334
  ],
  [
    1680080100,
    28481.1198,
    1,
    15.6466
  ],
  [
    1680166500,
    28640.4493,
    1,
    15.8998
  ],
  [
    1680252900,
    27792.9144,
    1,
    15.5092
  ],
  [
    1680339300,
    28456.6803,
    1,
    15.5821
  ],
  [
    1680425700,
    28382.8229,
    1,
    15.6084
  ],
  [
    1680512100,
    28309.0519,
    1,
    15.6249
  ],
  [
    1680598500,
    28095.908,
    1,
    15.4281
  ],
  [
    1680684900,
    28557.4503,
    1,
    14.9479
  ],
  [
    1680771300,
    27952.273,
    1,
    14.8637
  ],
  [
    1680857700,
    27845.3797,
    1,
    15.0497
  ],
  [
    1680944100,
    28061.2586,
    1,
    14.9831
  ],
  [
    1681030500,
    27884.9487,
    1,
    15.1666
  ],
  [
    1681116900,
    28307.3145,
    1,
    15.2307
  ],
  [
    1681203300,
    30079.0972,
    1,
    15.6874
  ],
  [
    1681289700,
    29953.1121,
    1,
    16.0267
  ],
  [
    1681376100,
    30222.5009,
    1,
    15.3768
  ],
  [
    1681462500,
    30717.0244,
    1,
    14.5693
  ],
  [
    1681548900,
    30395.615,
    1,
    14.4834
  ],
  [
    1681635300,
    30359.5479,
    1,
    14.4406
  ],
  [
    1681721700,
    29894.1005,
    1,
    14.2712
  ],
  [
    1681808100,
    29720.1502,
    1,
    14.1715
  ],
  [
    1681894500,
    29308.2291,
    1,
    14.7139
  ],
  [
    1681980900,
    28851.422,
    1,
    14.7843
  ],
  [
    1682067300,
    28072.1994,
    1,
    14.6195
  ],
  [
    1682153700,
    27265.1892,
    1,
    14.7118
  ],
  [
    1682240100,
    27719.1825,
    1,
    14.7576
  ],
  [
    1682326500,
    27276.5447,
    1,
    14.8563
  ],
  [
    1682412900,
    27342.7633,
    1,
    15.0441
  ],
  [
    1682499300,
    28679.0913,
    1,
    15.2987
  ],
  [
    1682585700,
    28994.098,
    1,
    15.3703
  ],
  [
    1682672100,
    29243.8601,
    1,
    15.3174
  ],
  [
    1682758500,
    29320.8172,
    1,
    15.4152
  ],
  [
    1682844900,
    29278.1536,
    1,
    15.3678
  ],
  [
    1682931300,
    28564.7047,
    1,
    15.4665
  ],
  [
    1683017700,
    28027.294,
    1,
    15.2997
  ],
  [
    1683104100,
    28727.3641,
    1,
    15.3589
  ],
  [
    1683190500,
    29086.6289,
    1,
    15.2833
  ],
  [
    1683276900,
    29059.3178,
    1,
    15.3214
  ],
  [
    1683363300,
    29348.1239,
    1,
    15.0869
  ],
  [
    1683449700,
    28888.3372,
    1,
    15.1276
  ],
  [
    1683536100,
    27770.5453,
    1,
    15.0636
  ],
  [
    1683622500,
    27569.9555,
    1,
    14.9757
  ],
  [
    1683708900,
    27582.1893,
    1,
    15.0018
  ],
  [
    1683795300,
    27413.3788,
    1,
    15.0666
  ],
  [
    1683881700,
    26301.9806,
    1,
    14.9372
  ],
  [
    1683968100,
    26807.9641,
    1,
    14.8432
  ],
  [
    1684054500,
    26859.9443,
    1,
    14.8765
  ],
  [
    1684140900,
    27430.269,
    1,
    14.9764
  ],
  [
    1684227300,
    27181.0901,
    1,
    14.9439
  ],
  [
    1684313700,
    26816.5283,
    1,
    14.8357
  ],
  [
    1684400100,
    27422.5317,
    1,
    15.0006
  ],
  [
    1684486500,
    26895.4101,
    1,
    14.8747
  ],
  [
    1684572900,
    26910.1608,
    1,
    14.8315
  ],
  [
    1684659300,
    27045.2244,
    1,
    14.8955
  ],
  [
    1684745700,
    26849.8379,
    1,
    14.8125
  ],
  [
    1684832100,
    27322.8483,
    1,
    14.717
  ],
  [
    1684918500,
    26728.5083,
    1,
    14.707
  ],
  [
    1685004900,
    26219.918,
    1,
    14.693
  ],
  [
    1685091300,
    26502.2819,
    1,
    14.5866
  ],
  [
    1685177700,
    26693.7163,
    1,
    14.6015
  ],
  [
    1685264100,
    27188.4163,
    1,
    14.7429
  ],
  [
    1685350500,
    27893.1996,
    1,
    14.6834
  ],
  [
    1685436900,
    27795.5413,
    1,
    14.6129
  ],
  [
    1685523300,
    27124.4389,
    1,
    14.5142
  ],
  [
    1685609700,
    26889.8067,
    1,
    14.4646
  ],
  [
    1685696100,
    27146.5849,
    1,
    14.3469
  ],
  [
    1685782500,
    27157.3997,
    1,
    14.2673
  ],
  [
    1685868900,
    27150.7729,
    1,
    14.2922
  ],
  [
    1685955300,
    26803.199,
    1,
    14.3314
  ],
  [
    1686041700,
    25721.5541,
    1,
    14.1595
  ],
  [
    1686128100,
    26800.5237,
    1,
    14.3002
  ],
  [
    1686214500,
    26460.6561,
    1,
    14.3713
  ],
  [
    1686300900,
    26629.1816,
    1,
    14.4533
  ],
  [
    1686387300,
    25692.8354,
    1,
    14.7557
  ],
  [
    1686473700,
    25728.4775,
    1,
    14.7079
  ],
  [
    1686560100,
    25910.2717,
    1,
    14.8709
  ],
  [
    1686646500,
    26204.6361,
    1,
    14.9324
  ],
  [
    1686732900,
    25926.956,
    1,
    14.8651
  ],
  [
    1686819300,
    24976.1009,
    1,
    15.2318
  ],
  [
    1686905700,
    25553.0653,
    1,
    15.2924
  ],
  [
    1686992100,
    26596.4924,
    1,
    15.2516
  ],
  [
    1687078500,
    26505.3136,
    1,
    15.329
  ],
  [
    1687164900,
    26406.0628,
    1,
    15.336
  ],
  [
    1687251300,
    26746.0046,
    1,
    15.5034
  ],
  [
    1687337700,
    28822.857,
    1,
    15.9287
  ],
  [
    1687424100,
    30105.8568,
    1,
    15.7912
  ],
  [
    1687510500,
    30025.1197,
    1,
    15.9272
  ],
  [
    1687596900,
    30570.0376,
    1,
    16.1993
  ],
  [
    1687683300,
    30759.2618,
    1,
    16.0171
  ],
  [
    1687769700,
    30394.4374,
    1,
    16.0703
  ],
  [
    1687856100,
    30377.3122,
    1,
    16.2053
  ],
  [
    1687942500,
    30261.1124,
    1,
    16.3036
  ],
  [
    1688028900,
    30382.5988,
    1,
    16.4476
  ],
  [
    1688115300,
    30897.8396,
    1,
    16.3312
  ],
  [
    1688201700,
    30446.2055,
    1,
    15.8881
  ],
  [
    1688288100,
    30505.6063,
    1,
    15.9385
  ],
  [
    1688374500,
    30631.7546,
    1,
    15.6327
  ],
  [
    1688460900,
    30930.2825,
    1,
    15.8474
  ],
  [
    1688547300,
    30778.5909,
    1,
    15.9393
  ],
  [
    1688633700,
    31190.9806,
    1,
    16.0404
  ],
  [
    1688720100,
    30076.6181,
    1,
    16.1935
  ],
  [
    1688806500,
    30273.47,
    1,
    16.2232
  ],
  [
    1688892900,
    30304.8205,
    1,
    16.2141
  ],
  [
    1688979300,
    30075.2526,
    1,
    16.2204
  ],
  [
    1689065700,
    30519.1603,
    1,
    16.2321
  ],
  [
    1689152100,
    30722.2651,
    1,
    16.2744
  ],
  [
    1689238500,
    30414.716,
    1,
    16.235
  ],
  [
    1689324900,
    31233.0974,
    1,
    15.6208
  ],
  [
    1689411300,
    30326.7893,
    1,
    15.6189
  ],
  [
    1689497700,
    30297.6823,
    1,
    15.7123
  ],
  [
    1689584100,
    30283.4417,
    1,
    15.7128
  ],
  [
    1689670500,
    30030.6161,
    1,
    15.7891
  ],
  [
    1689756900,
    29902.0478,
    1,
    15.6877
  ],
  [
    1689843300,
    30255.0572,
    1,
    15.8039
  ],
  [
    1689929700,
    29798.3667,
    1,
    15.7467
  ],
  [
    1690016100,
    29938.6423,
    1,
    15.8232
  ],
  [
    1690102500,
    29952.363,
    1,
    15.9533
  ],
  [
    1690188900,
    29764.6836,
    1,
    15.9111
  ],
  [
    1690275300,
    29168.6972,
    1,
    15.7289
  ],
  [
    1690361700,
    29232.1874,
    1,
    15.7611
  ],
  [
    1690448100,
    29462.633,
    1,
    15.7048
  ],
  [
    1690534500,
    29153.9378,
    1,
    15.6679
  ],
  [
    1690620900,
    29294.5582,
    1,
    15.6516
  ],
  [
    1690707300,
    29298.0982,
    1,
    15.6253
  ],
  [
    1690793700,
    29390.2078,
    1,
    15.7407
  ],
  [
    1690880100,
    28948.6388,
    1,
    15.7852
  ],
  [
    1690966500,
    29500.7515,
    1,
    15.8937
  ],
  [
    1691052900,
    29064.9418,
    1,
    15.8843
  ],
  [
    1691139300,
    29185.4331,
    1,
    15.9061
  ],
  [
    1691225700,
    29080.5608,
    1,
    15.8709
  ],
  [
    1691312100,
    29125.7443,
    1,
    15.8604
  ],
  [
    1691398500,
    29070.585,
    1,
    15.855
  ],
  [
    1691484900,
    29188.0343,
    1,
    15.9582
  ],
  [
    1691571300,
    29838.9536,
    1,
    16.0296
  ],
  [
    1691657700,
    29517.4741,
    1,
    15.9537
  ],
  [
    1691744100,
    29410.2347,
    1,
    15.9187
  ],
  [
    1691830500,
    29392.5807,
    1,
    15.9004
  ],
  [
    1691916900,
    29403.034,
    1,
    15.8882
  ],
  [
    1692003300,
    29432.4972,
    1,
    15.9178
  ],
  [
    1692089700,
    29425.7383,
    1,
    15.9804
  ],
  [
    1692176100,
    29179.8845,
    1,
    15.9963
  ],
  [
    1692262500,
    28561.0023,
    1,
    15.9184
  ],
  [
    1692348900,
    26536.5069,
    1,
    15.6829
  ],
  [
    1692435300,
    25920.0818,
    1,
    15.6053
  ],
  [
    1692521700,
    26115.826,
    1,
    15.6397
  ],
  [
    1692608100,
    26037.7363,
    1,
    15.5872
  ],
  [
    1692694500,
    26056.531,
    1,
    15.6596
  ],
  [
    1692780900,
    26031.998,
    1,
    15.8328
  ],
  [
    1692867300,
    26448.5663,
    1,
    15.8249
  ],
  [
    1692953700,
    26076.2378,
    1,
    15.7687
  ],
  [
    1693040100,
    26033.3764,
    1,
    15.7854
  ],
  [
    1693126500,
    26065.902,
    1,
    15.7859
  ],
  [
    1693212900,
    25966.3386,
    1,
    15.8174
  ],
  [
    1693299300,
    25970.7973,
    1,
    15.7778
  ],
  [
    1693385700,
    27471.0143,
    1,
    15.9911
  ],
  [
    1693472100,
    27232.6771,
    1,
    15.96
  ],
  [
    1693558500,
    25976.5425,
    1,
    15.7979
  ],
  [
    1693644900,
    25801.8123,
    1,
    15.8034
  ],
  [
    1693731300,
    25887.9396,
    1,
    15.858
  ],
  [
    1693817700,
    25984.4704,
    1,
    15.8576
  ],
  [
    1693904100,
    25702.3747,
    1,
    15.8373
  ],
  [
    1693990500,
    25756.174,
    1,
    15.7879
  ],
  [
    1694076900,
    25746.8336,
    1,
    15.7651
  ],
  [
    1694163300,
    26201.4493,
    1,
    15.9274
  ],
  [
    1694249700,
    25882.1405,
    1,
    15.835
  ],
  [
    1694336100,
    25859.764,
    1,
    15.9091
  ],
  [
    1694422500,
    25812.3571,
    1,
    16.0506
  ],
  [
    1694508900,
    25777.02,
    1,
    16.3274
  ],
  [
    1694595300,
    25946.2002,
    1,
    16.294
  ],
  [
    1694681700,
    26292.3873,
    1,
    16.2392
  ],
  [
    1694768100,
    26625.5239,
    1,
    16.3431
  ],
  [
    1694854500,
    26513.3284,
    1,
    16.2085
  ],
  [
    1694940900,
    26544.7007,
    1,
    16.264
  ],
  [
    1695027300,
    26707.8881,
    1,
    16.3676
  ],
  [
    1695113700,
    26989.7015,
    1,
    16.4591
  ],
  [
    1695200100,
    27183.9716,
    1,
    16.6059
  ],
  [
    1695286500,
    26978.2942,
    1,
    16.6423
  ],
  [
    1695372900,
    26676.1436,
    1,
    16.7053
  ],
  [
    1695459300,
    26568.0681,
    1,
    16.6723
  ],
  [
    1695545700,
    26583.0506,
    1,
    16.6686
  ],
  [
    1695632100,
    26136.1017,
    1,
    16.5627
  ],
  [
    1695718500,
    26279.9038,
    1,
    16.5296
  ],
  [
    1695804900,
    26258.2333,
    1,
    16.4873
  ],
  [
    1695891300,
    26413.777,
    1,
    16.3203
  ],
  [
    1695977700,
    27073.0993,
    1,
    16.1406
  ],
  [
    1696064100,
    26935.905,
    1,
    16.0916
  ],
  [
    1696150500,
    27154.1554,
    1,
    16.1517
  ],
  [
    1696236900,
    28336.7743,
    1,
    16.3276
  ],
  [
    1696323300,
    27608.3253,
    1,
    16.5815
  ],
  [
    1696409700,
    27533.1213,
    1,
    16.7164
  ],
  [
    1696496100,
    27657.6167,
    1,
    16.8826
  ],
  [
    1696582500,
    27625.2195,
    1,
    16.9185
  ],
  [
    1696668900,
    28012.3919,
    1,
    17.0472
  ],
  [
    1696755300,
    27903.2111,
    1,
    17.1347
  ],
  [
    1696841700,
    27744.8579,
    1,
    17.1891
  ],
  [
    1696928100,
    27680.1485,
    1,
    17.4091
  ],
  [
    1697014500,
    27099.9397,
    1,
    17.3347
  ],
  [
    1697100900,
    26798.3855,
    1,
    17.1955
  ],
  [
    1697187300,
    26795.0063,
    1,
    17.318
  ],
  [
    1697273700,
    26853.7455,
    1,
    17.3653
  ],
  [
    1697360100,
    26907.7428,
    1,
    17.2755
  ],
  [
    1697446500,
    27836.7722,
    1,
    17.5977
  ],
  [
    1697532900,
    28458.984,
    1,
    17.9241
  ],
  [
    1697619300,
    28530.8313,
    1,
    18.1036
  ],
  [
    1697705700,
    28343.5428,
    1,
    18.2852
  ],
  [
    1697792100,
    29746.4723,
    1,
    18.4838
  ],
  [
    1697878500,
    29668.0155,
    1,
    18.4837
  ],
  [
    1697964900,
    29835.719,
    1,
    18.3145
  ],
  [
    1698051300,
    30636.5763,
    1,
    18.2153
  ],
  [
    1698137700,
    34128.6883,
    1,
    18.7841
  ],
  [
    1698224100,
    33879.7979,
    1,
    19.1359
  ],
  [
    1698310500,
    34589.274,
    1,
    18.7191
  ],
  [
    1698396900,
    34074.3373,
    1,
    19.099
  ],
  [
    1698483300,
    34096.3684,
    1,
    19.0533
  ],
  [
    1698569700,
    34236.9318,
    1,
    19.1498
  ],
  [
    1698656100,
    34366.0471,
    1,
    18.9139
  ],
  [
    1698742500,
    34390.0662,
    1,
    19.103
  ],
  [
    1698828900,
    34444.8466,
    1,
    19.079
  ],
  [
    1698915300,
    35297.3258,
    1,
    19.2712
  ],
  [
    1699001700,
    34441.2237,
    1,
    19.167
  ],
  [
    1699088100,
    34858.7321,
    1,
    18.956
  ],
  [
    1699174500,
    35039.9696,
    1,
    18.6284
  ],
  [
    1699260900,
    34898.4523,
    1,
    18.5609
  ],
  [
    1699347300,
    34909.7674,
    1,
    18.4938
  ],
  [
    1699433700,
    35297.2778,
    1,
    18.7917
  ],
  [
    1699520100,
    36668.5265,
    1,
    19.1212
  ],
  [
    1699606500,
    36499.0692,
    1,
    17.3899
  ],
  [
    1699692900,
    36970.1874,
    1,
    18.0114
  ],
  [
    1699779300,
    37102.4046,
    1,
    18.0353
  ],
  [
    1699865700,
    37037.5492,
    1,
    17.9952
  ],
  [
    1699952100,
    36725.0658,
    1,
    17.8156
  ],
  [
    1700038500,
    35814.5568,
    1,
    18.0006
  ],
  [
    1700124900,
    37363.8522,
    1,
    18.2664
  ],
  [
    1700211300,
    36386.5915,
    1,
    18.3949
  ],
  [
    1700297700,
    36463.7458,
    1,
    18.7923
  ],
  [
    1700384100,
    36634.9044,
    1,
    18.6945
  ],
  [
    1700470500,
    37101.0382,
    1,
    18.4064
  ],
  [
    1700556900,
    37397.9381,
    1,
    18.5965
  ],
  [
    1700643300,
    36543.6898,
    1,
    18.2342
  ],
  [
    1700729700,
    37456.1833,
    1,
    18.0531
  ],
  [
    1700816100,
    37435.4925,
    1,
    18.0628
  ],
  [
    1700902500,
    37770.2108,
    1,
    18.1647
  ],
  [
    1700988900,
    37776.8508,
    1,
    18.1442
  ],
  [
    1701075300,
    37339.5226,
    1,
    18.2512
  ],
  [
    1701161700,
    36945.7517,
    1,
    18.4101
  ],
  [
    1701248100,
    38312.7047,
    1,
    18.5364
  ],
  [
    1701334500,
    37705.6509,
    1,
    18.5897
  ],
  [
    1701420900,
    38322.738,
    1,
    18.2838
  ],
  [
    1701507300,
    38714.536,
    1,
    18.4321
  ],
  [
    1701593700,
    39426.0467,
    1,
    18.2327
  ],
  [
    1701680100,
    41668.2065,
    1,
    18.4786
  ],
  [
    1701766500,
    41524.3492,
    1,
    18.8964
  ],
  [
    1701852900,
    43816.2667,
    1,
    19.2225
  ],
  [
    1701939300,
    43640.7469,
    1,
    19.3801
  ],
  [
    1702025700,
    43230.9551,
    1,
    18.38
  ],
  [
    1702112100,
    43869.8364,
    1,
    18.5196
  ],
  [
    1702198500,
    43751.1465,
    1,
    18.6731
  ],
  [
    1702284900,
    42219.8462,
    1,
    18.828
  ],
  [
    1702371300,
    42032.6557,
    1,
    18.794
  ],
  [
    1702457700,
    41222.5734,
    1,
    18.898
  ],
  [
    1702544100,
    42790.1842,
    1,
    18.8146
  ],
  [
    1702630500,
    42809.1363,
    1,
    18.8197
  ],
  [
    1702716900,
    42162.1271,
    1,
    18.7833
  ],
  [
    1702803300,
    41862.7433,
    1,
    18.904
  ],
  [
    1702862153,
    41375.00893741754,
    1,
    18.826088143886622
  ]
]

export default btcchartData;
