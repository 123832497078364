// ----------------------------------------------------------------------
import { fShortenNumber } from "../utils/formatNumber";

const coins = [
  {
    index: 1,
    id: "bitcoin",
    symbol: 'BTC',
    title: "Bitcoin",
    icon: "/assets/images/coins/bitcoin.png",
    color: "error",
    link: '/dashboard/cvcreator',
    price: 1000,
    priceChange1h: -1.32,
    priceChange1d: 1.23,
    priceChange1w: 0.34,
  },

  {
    index: 2,
    id: "ethereum",
    symbol: 'ETH',
    title: "Ethereum",
    icon: "/assets/images/coins/etherium.png",
    color: "purple",
    link: '/dashboard/costestimation',
    price: 0,
    priceChange1h: 0.22,
    priceChange1d: 1.21,
    priceChange1w: -1.45,
  },

  {
    index: 3,
    id: "tether",
    symbol: 'USDT',
    title: "Tether",
    icon: "/assets/images/coins/tether.png",
    color: "green",
    link: '/dashboard/costestimation',
    price: 0,
    priceChange1h: 0.33,
    priceChange1d: -1.2,
    priceChange1w: 2.2,
  },

  {
    index: 4,
    id: "binance-coin",
    symbol: 'BNB',
    title: "Binance",
    icon: "/assets/images/coins/binance.png",
    color: "warning",
    link: '/dashboard/countrycity',
    price: 0,
    priceChange1h: 0,
    priceChange1d: 0,
    priceChange1w: 0,
  },

  {
    index: 5,
    id: "ripple",
    symbol: 'XRP',
    title: "Ripple",
    icon: "/assets/images/coins/ripple.png",
    color: "info",
    link: '/dashboard/universities',
    price: 0,
    priceChange1h: 0,
    priceChange1d: 0,
    priceChange1w: 0,
  },

  {
    index: 6,
    id: "solana",
    symbol: 'SOL',
    title: "Solana",
    icon: "/assets/images/coins/solana.png",
    color: "error",
    link: '/dashboard/jobsandfields',
    price: 0,
    priceChange1h: 0,
    priceChange1d: 0,
    priceChange1w: 0,
  },

  {
    index: 7,
    id: "usd-coin",
    symbol: 'USDC',
    title: "USD Coin",
    icon: "/assets/images/coins/usdcoin.png",
    color: "orange",
    link: '/dashboard/jobsandfields',
    price: 0,
    priceChange1h: 0,
    priceChange1d: 0,
    priceChange1w: 0,
  },

  {
    index: 8,
    id: "cardano",
    symbol: 'ADA',
    title: "Cardano",
    icon: "/assets/images/coins/cardano.png",
    color: "green",
    link: '/dashboard/jobsandfields',
    price: 0,
    priceChange1h: 0,
    priceChange1d: 0,
    priceChange1w: 0,
  },
];


const maincoins = [
  {
    id: 'bitcoin',
    icon: 'https://static.coinstats.app/coins/1650455588819.png',
    name: 'Bitcoin',
    symbol: 'BTC',
    rank: 1,
    price: 42101.53909746874,
    priceBtc: 1,
    volume: 18583375659.174347,
    marketCap: 824044457126.9279,
    availableSupply: 19572787,
    totalSupply: 21000000,
    priceChange1h: 0.09,
    priceChange1d: -0.94,
    priceChange1w: -3.92,
    redditUrl: 'https://www.reddit.com/r/Bitcoin/',
    websiteUrl: 'http://www.bitcoin.org',
    twitterUrl: 'https://twitter.com/bitcoin',
    explorers: [Array]
  },
  {
    id: 'ethereum',
    icon: 'https://static.coinstats.app/coins/1650455629727.png',
    name: 'Ethereum',
    symbol: 'ETH',
    rank: 2,
    price: 2227.0604384580724,
    priceBtc: 0.05289736399665183,
    volume: 7341566997.271359,
    marketCap: 267692001038.64966,
    availableSupply: 120199702,
    totalSupply: 120199702,
    priceChange1h: -0.13,
    priceChange1d: -1.04,
    priceChange1w: -5.05,
    redditUrl: 'https://www.reddit.com/r/ethereum',
    websiteUrl: 'https://www.ethereum.org/',
    twitterUrl: 'https://twitter.com/ethereum',
    contractAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'tether',
    icon: 'https://static.coinstats.app/coins/1650455771843.png',
    name: 'Tether',
    symbol: 'USDT',
    rank: 3,
    price: 0.9965538492492757,
    priceBtc: 0.00002367024746867725,
    volume: 27195248114.215725,
    marketCap: 90599209587.01547,
    availableSupply: 90912507794,
    totalSupply: 90912507794,
    priceChange1h: -0.01,
    priceChange1d: 0.1,
    priceChange1w: -0.07,
    redditUrl: 'https://www.reddit.com',
    websiteUrl: 'https://tether.to/',
    twitterUrl: 'https://twitter.com/Tether_to',
    contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'binance-coin',
    icon: 'https://static.coinstats.app/coins/1666608145347.png',
    name: 'BNB',
    symbol: 'BNB',
    rank: 4,
    price: 243.0862474102594,
    priceBtc: 0.005773809048821078,
    volume: 680538082.8871074,
    marketCap: 37400314144.48998,
    availableSupply: 153856150,
    totalSupply: 153856150,
    priceChange1h: -0.19,
    priceChange1d: -1.11,
    priceChange1w: 1.67,
    redditUrl: 'https://www.reddit.com/r/binance',
    websiteUrl: 'https://www.binance.com',
    twitterUrl: 'https://twitter.com/binance',
    contractAddress: 'BNB',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'ripple',
    icon: 'https://static.coinstats.app/coins/XRPdnqGJ.png',
    name: 'XRP',
    symbol: 'XRP',
    rank: 5,
    price: 0.6199802623535023,
    priceBtc: 0.000014725833678388666,
    volume: 503816399.75818896,
    marketCap: 33482404267.91525,
    availableSupply: 54005597115,
    totalSupply: 99988151135,
    priceChange1h: 0.02,
    priceChange1d: -0.44,
    priceChange1w: -5.5,
    redditUrl: 'https://www.reddit.com/r/ripple',
    websiteUrl: 'https://ripple.com/currency/',
    twitterUrl: 'https://twitter.com/Ripple',
    contractAddress: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'solana',
    icon: 'https://static.coinstats.app/coins/1701234596791.png',
    name: 'Solana',
    symbol: 'SOL',
    rank: 6,
    price: 74.39503916803835,
    priceBtc: 0.0017670384684941643,
    volume: 1586964257.9596481,
    marketCap: 31821687556.837246,
    availableSupply: 427739375,
    totalSupply: 565044764,
    priceChange1h: 0.65,
    priceChange1d: -2.22,
    priceChange1w: 2.7,
    redditUrl: 'https://www.reddit.com/r/solana',
    websiteUrl: 'https://solana.com/',
    twitterUrl: 'https://twitter.com/solana',
    contractAddress: '0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'usd-coin',
    icon: 'https://static.coinstats.app/coins/1650455825065.png',
    name: 'USDC',
    symbol: 'USDC',
    rank: 7,
    price: 1,
    priceBtc: 0.00002375579621280516,
    volume: 5202380296,
    marketCap: 24603190505,
    availableSupply: 24634366845,
    totalSupply: 24634366845,
    priceChange1h: -0.25,
    priceChange1d: 0.14,
    priceChange1w: 0.02,
    redditUrl: 'https://www.reddit.com',
    websiteUrl: 'https://www.circle.com/en/usdc',
    twitterUrl: 'https://twitter.com/circle',
    contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    decimals: 18,
    explorers: [Array]
  },
  {
    id: 'cardano',
    icon: 'https://static.coinstats.app/coins/CardanojXddT.png',
    name: 'Cardano',
    symbol: 'ADA',
    rank: 8,
    price: 0.6010371662239333,
    priceBtc: 0.000014275895349870221,
    volume: 439807842.5910666,
    marketCap: 21044699944.4009,
    availableSupply: 35013974388,
    totalSupply: 45000000000,
    priceChange1h: 0.44,
    priceChange1d: -3.13,
    priceChange1w: 1.44,
    redditUrl: 'https://www.reddit.com/r/cardano',
    websiteUrl: 'https://www.cardano.org/en/home/',
    twitterUrl: 'https://twitter.com/Cardano_CF',
    contractAddress: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    decimals: 18,
    explorers: [Array]
  },
]

export default coins;
